var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-option-checkout"},[_c('u-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.lastSize),expression:"lastSize"}],attrs:{"bb":false,"value":`${_vm.lastSize}`,"options":[
        { label: `${_vm.lastSize}`, value: `${_vm.lastSize}` },
        {
          label: _vm.$t('text.showMoreSizes'),
          value: 'show more sizes',
          href: _vm.localePath({name: 'new-go',query: _vm.goProductQuery }),
          target: '_blank'
        }
      ]}}),_vm._v(" "),_c('u-button',{attrs:{"color":"black","bt":_vm.lastSize ? false : true,"click":() => _vm.goToCheckout()}},[_vm._v(_vm._s(_vm.$t('label.checkoutNow')))]),_vm._v(" "),_c('u-dialog',{attrs:{"show":_vm.alertDialog,"onClose":_vm.closeDialog,"size":"md","title":_vm.$t('label.warning') + '!'}},[_c('u-dialog-content',[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('text.shippingWarning'))),_c('br')])]),_vm._v(" "),_c('u-dialog-footer',[_c('u-button',{staticStyle:{"margin-right":"10px"},attrs:{"color":"secondary","block":false,"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t('label.cancel')))]),_vm._v(" "),_c('u-button',{attrs:{"color":"primary","block":false,"click":() => _vm.goToCheckout(true)}},[_vm._v(_vm._s(_vm.$t('label.checkoutNow')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }