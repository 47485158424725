var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.imageWrapper,class:{
  'w-full lg:basis-1/2 aspect-square border-[#131416] bg-cover bg-center bg-no-repeat ': true,
  'transition-[background-image] duration-500': true,
  'border-r': _vm.position === 'left',
  'border-l': _vm.position === 'right',
},style:({
  backgroundImage: `url(${_vm.image})`,
  backgroundPositionY: this.scrollY < this.imageWrapperTop ? '0px' : `${(this.scrollY - this.imageWrapperTop) / 2}px`
})})
}
var staticRenderFns = []

export { render, staticRenderFns }