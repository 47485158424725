<script>
export default {
  name: "UiLabelInput",
  props: {
    type: {
      type: String,
      default: "text"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    error: {
      type: Object,
      default: () => {
        return {
          status: false,
          message: ""
        }
      }
    }
  },
  data() {
    return {
      id: Math.random().toString(36).substring(7),
      showPassword: false
    }
  },
  computed: {
    inputType() {
      if(this.type === "password" && this.showPassword) {
        return "text";
      }

      return this.type;
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    }
  }
}
</script>

<template>
  <div class="flex flex-col mb-2 relative">
    <label :for="id" class="text-sm text-gray-high">{{ label }}{{ required ? ' *' : '' }}</label>
    <div class="w-full relative">
      <input :type="inputType" :id="id" :class="{
      'border border-black p-2 rounded-[2px] mt-1 w-full': true,
      'border-red-500': error.status
    }" :value="value" @input="updateValue" :disabled="disabled">
      <div v-if="type === 'password'" class="absolute right-3 top-3 cursor-pointer"
           @click="showPassword = !showPassword">
        <img src="~/assets/images/ui/icons/eye.png" v-show="showPassword"
             alt="eye" class="w-6 h-6" />
        <img src="~/assets/images/ui/icons/eye-x.png" v-show="!showPassword"
             alt="eye-x" class="w-6 h-6" />
      </div>
    </div>
    <div v-if="error.status" v-html="error.message" class="text-red-500 text-sm mt-1"></div>
  </div>
</template>

<style scoped>

</style>