var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5 flex flex-col w-full border-b border-black items-center"},[_c('div',{staticClass:"loading-overlay",style:({display: _vm.loading ? 'flex' : 'none'}),attrs:{"id":"checkout-loading"}},[_c('div',{staticClass:"loading-spinner"})]),_vm._v(" "),_c('div',{staticClass:"p-6 flex flex-col w-full lg:min-w-[800px]"},[_c('h2',{staticClass:"text-2xl font-bold text-font-line-color mb-4"},[_vm._v("Leave Feedback")]),_vm._v(" "),(_vm.successMessage)?_c('div',{staticClass:"flex flex-col p-4 border border-black mb-4"},[_c('span',{staticClass:"text-green-500 text-sm font-semibold"},[_vm._v(_vm._s(_vm.successMessage))])]):_vm._e(),_vm._v(" "),(_vm.errorMessage)?_c('div',{staticClass:"flex flex-col p-2 px-4 border border-black mb-4 rounded"},[_c('span',{staticClass:"text-red-500 text-sm font-semibold"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendFeedback.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"text-sm text-gray-high mb-1",attrs:{"for":"requestType"}},[_vm._v("Request Type")]),_vm._v(" "),_c('UiSelect',{attrs:{"items":[
              {
                label: 'Error for Sneakers123',
                value: 'Error for Sneakers123'
              },
              {
                label: 'General feedback',
                value: 'General feedback'
              },
              {
                label: 'Account issues',
                value: 'Account issues'
              },
              {
                label: 'Shipping issues',
                value: 'Shipping issues'
              },
              {
                label: 'Other',
                value: 'Other'
              }
          ],"click":(selected) => {
            _vm.form.requestType = selected.label;
          },"title":'Select request type',"color":"white","size":"md"}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col mb-4"},[_c('UiLabelInput',{attrs:{"label":"E-mail","required":true,"type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col mb-4"},[_c('UiLabelInput',{attrs:{"label":"Subject","required":true},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"text-sm text-gray-high mb-1",attrs:{"for":"description"}},[_vm._v("Description")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"border border-black p-2 rounded-[2px] mt-1",attrs:{"id":"description","rows":"3"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "description", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"flex flex-col mb-4 justify-center items-center"},[_c('UiButton',{attrs:{"type":"submit","color":"black","size":"lg","block":""}},[_vm._v("Submit")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }