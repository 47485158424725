<script>
import UiTabs from "../../ui/UiTabs.vue";
import SignInForm from "./components/sign/sign-in-form.vue";
import SignUpForm from "./components/sign/sign-up-form.vue";

export default {
  name: "sign-drawer",
  components: {SignUpForm, UiTabs, SignInForm},
  created() {
    this.$root.$on('global-drawer-close', this.closeDrawer);
  },
  methods: {
    closeDrawer() {
    },
  }
}
</script>

<template>
  <UiTabs :tabs="['Sign In', 'Sign Up']" :tabChange="() => {
    $emit('change-sign-tab');
  }">
    <template #tab1>
      <sign-in-form></sign-in-form>
    </template>
    <template #tab2>
      <sign-up-form></sign-up-form>
    </template>
  </UiTabs>
</template>

<style scoped>

</style>