<script>
import {mapActions, mapMutations} from "vuex";
import formMixin from "../../../../plugins/formMixin";
import UiButton from "@/components/v2/ui/UiButton.vue";
import {getCountry, getCountryList} from "~/lib/utils";

export default {
  name: "mobile-menu-drawer",
  created() {
    this.$root.$on('global-drawer-close', this.closeDrawer);
  },
  components: {UiButton},
  mixins: [formMixin],

  beforeRouteUpdate(to, from, next) {
    this.closeDrawer();
    next();
  },
  data() {
    return {
    }
  },
  computed: {
    menus() {
      return [
        {
          title: 'Sneaker',
          link: this.sneakerRootPage(),
          subMenus: [],
        },
        {
          title: 'Sale',
          link: this.sneakerLocalePath('sale'),
          subMenus: [],
        },
        {
          title: this.$t("filters.trending"),
          link: this.sneakerLocalePath('top-trends'),
          subMenus: [],
        },
        {
          title: this.$t("Limited Editions"),
          link: this.sneakerLocalePath('limited-editions'),
          subMenus: [],
        },
        {
          title: 'Reseller',
          link: this.sneakerLocalePath('reseller'),
          subMenus: [],
        },
        {
          title: this.$t("Shops"),
          link: this.localePath({ name: 'shops' }),
          subMenus: [],
        }
      ]
    },
    currentCurrency() {
      const currency = this.$store.state.currency ? this.$store.state.currency.toUpperCase() : "EUR";
      let findItem = this.currencies.find((c) => c.code === currency);
      if(!findItem) {
        findItem = this.currencies[0];
      }
      return findItem
    },
    currencies() {
      return [
        {code: "USD", name: this.$t('label.currencyCode.usd'), symbol: "$"},
        {code: "EUR", name: this.$t('label.currencyCode.eur'), symbol: "€"},
        {code: "GBP", name: this.$t('label.currencyCode.gbp'), symbol: "£"},
        {code: "JPY", name: this.$t('label.currencyCode.jpy'), symbol: "¥"},
      ]
    },
    country(){
      return getCountry(this.$store.state.location.code)
    },
    countries() {
      return getCountryList(this.$store.state.countryCodes)
    },
    currentLocaleName () {
      return this.availableLocales.find((l)=>{ return l.code === this.currentLocale}).name
    },
    currentLocale () {
      return this.$i18n.locale
    },
    availableLocales () {
      return this.$i18n.locales
    },
  },
  methods: {
    ...mapMutations(['setGlobalDrawerSection']),
    ...mapActions(['closeGlobalDrawer', 'openGlobalDrawer']),


    goToMenu(link) {
      this.$router.push(link);
      this.closeDrawer();
    },
    closeDrawer() {
      this.closeGlobalDrawer();
    },

  },
}
</script>

<template>
  <div class="flex flex-col w-full  overflow-y-auto max-h-header-without pb-32">
    <ul class="flex flex-col  w-full">
      <li v-for="(menu, index) in menus" :key="index" class="border-b border-black flex flex-col">
        <div @click="goToMenu(menu.link)" class="py-4 px-4 text-base font-bold text-gray-800 relative">{{ menu.title }}
          <span class="absolute right-2 top-0 bottom-0 h-[56px] flex items-center">
            <img src="~/assets/images/ui/icons/select-arrow-up.png" class="w-8 h-8 transition-transform transform rotate-180" v-if="menu.subMenus.length > 0" />
            <img src="~/assets/images/ui/icons/arrow-right.png" class="w-8 h-8" v-if="!menu.subMenus || menu.subMenus.length === 0" />
          </span>
        </div>
        <ul v-if="menu.subMenus.length > 0" class="flex flex-col">
          <li v-for="(subMenu, subIndex) in menu.subMenus" :key="subIndex" class="border-t border-black flex flex-col">
            <div @click="goToMenu(subMenu.link)" class="py-4 px-6 text-base text-gray-800">{{ subMenu.title }}</div>
          </li>
        </ul>
      </li>
    </ul>

    <div class="flex flex-col p-4 justify-center items-center w-full">

      <client-only>
        <ui-button v-if="!$store.state.auth.loggedIn" :click="() => openGlobalDrawer('sign')" block>
          {{ $t('Sign In') }}
        </ui-button>
        <ui-button v-if="$store.state.auth.loggedIn" :click="() => openGlobalDrawer('mypage')" block>
          {{ $t('My account') }}
        </ui-button>
        <ui-button v-if="$store.state.auth.loggedIn" :click="() => {
        this.$store.dispatch('logout');
          this.$router.push(this.localePath('index'))
          this.closeDrawer();
      }"
                   class="mt-4"
                   block>
          {{ $t('Logout') }}
        </ui-button>

        <div @click="() => {
        openGlobalDrawer('setting')
      }" class="flex flex-col w-full gap-3 mt-6">
          <div class="text-base text-black font-normal">
            {{$t('label.language')}} & {{$t('label.region')}} & {{$t('label.currency')}}
          </div>
          <div class="flex flex-col w-full gap-1 relative h-12">
            <div class="text-base text-black font-normal">{{currentLocaleName}}</div>
            <div class="text-sm text-gray-high font-normal">{{$t('label.language')}}</div>
            <div class="absolute right-2 top-0 bottom-0 h-12 flex items-center">
               <img src="~/assets/images/ui/icons/arrow-right.png" class="w-8 h-8"/>
            </div>
          </div>
          <div class="flex flex-col w-full gap-1 relative h-12">
            <div class="text-base text-black font-normal">{{$t(country.name)}}</div>
            <div class="text-sm text-gray-high font-normal">{{$t('label.region')}}</div>
            <div class="absolute right-2 top-0 bottom-0 h-12 flex items-center">
               <img src="~/assets/images/ui/icons/arrow-right.png" class="w-8 h-8"/>
            </div>
          </div>
          <div class="flex flex-col w-full gap-1 relative h-12">
            <div class="text-base text-black font-normal">{{currentCurrency.symbol}} {{ currentCurrency.name }}</div>
            <div class="text-sm text-gray-high font-normal">{{$t('label.currency')}}</div>
            <div class="absolute right-2 top-0 bottom-0 h-12 flex items-center">
               <img src="~/assets/images/ui/icons/arrow-right.png" class="w-8 h-8"/>
            </div>
          </div>
        </div>

      </client-only>
    </div>
  </div>
</template>

<style scoped>

</style>