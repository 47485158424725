<script>
export default {
  name: "ui-tabs",
  data() {
    return {
      activeTab: 0,
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    tabChange: {
      type: Function,
      required: false,
    }
  },
}
</script>

<!-- src/components/Tabs.vue -->
<template>
  <div class="w-full">
    <div class="flex border-b border-gray-low flex-row justify-around">
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          @click="() => {
            activeTab = index;
            if(tabChange) tabChange();
          }"
          :class="['cursor-pointer']"
      >
        <div
             :class="['px-4 py-4 uppercase', activeTab === index ? 'border-b-2 border-black' : '']">
          {{ tab }}
        </div>
      </div>
    </div>
    <div class="p-0">
      <div v-if="activeTab === 0">
        <slot name="tab1"></slot>
      </div>
      <div v-if="activeTab === 1">
        <slot name="tab2"></slot>
      </div>
      <div v-if="activeTab === 2">
        <slot name="tab3"></slot>
      </div>
    </div>
  </div>
</template>