<script>
import {mapState} from "vuex";
import ReleaseAlert from "@/components/new/release/release-alert.vue";
import ReleaseFavorite from "@/components/new/release/release-favorite.vue";

export default {
  name: "release-item",
  components: {ReleaseFavorite, ReleaseAlert},
  props: {
    release: { type: Object, default: {} },
  },
  data() {
    return {
      hoverRelatedIndex: null,
      showRelated: false,
      imageWidth: 0
    }
  },
  computed: {
    ...mapState(['sizeFormat']),
    query()
    {
      let query = {};
      if (this.$route.query.sizes)
        query.sizes = this.$route.query.sizes;
      return query;
    },
    hasManyAvailableSizes()
    {
      return (
          (this.selectedRelated.sizes_eu && this.selectedRelated.sizes_eu.length >= 6)
              || (this.selectedRelated.sizes_us && this.selectedRelated.sizes_us.length >= 6)
              || (this.selectedRelated.sizes_uk && this.selectedRelated.sizes_uk.length >= 6)
          ) || (
          (this.selectedRelated.sizes_eu && this.selectedRelated.sizes_eu.length === 0)
            || (this.selectedRelated.sizes_us && this.selectedRelated.sizes_us.length === 0)
            || (this.selectedRelated.sizes_uk && this.selectedRelated.sizes_uk.length === 0)
          )
    },
    getAvailableSizes()
    {
      let roundedSizes = [];
      let sizes = this.selectedRelated['sizes_' + this.sizeFormat];
      for (let size in sizes) {
        let roundedSize = Math.floor(sizes[size]);
        if (!roundedSizes.includes(roundedSize))
          roundedSizes.push(roundedSize);
      }
      return roundedSizes;
    },

    infoHeight(){
      const baseHeight = 138;
      return baseHeight;
      // return this.showRelated
      //     ? this.release.related && this.release.related.length > 0
      //         ? baseHeight + 100
      //         : baseHeight
      //     : baseHeight;
    },
    releaseLink(){
      return this.localePath({name: 'product', params : { product : this.release.slug}})
    },
    selectedRelated(){
      return this.hoverRelatedIndex !== null && this.release.related && this.release.related.length >= this.hoverRelatedIndex
          ? this.release.related[this.hoverRelatedIndex]
          : this.release;
    }
  },
  mounted() {

    if(this.$device.isDesktop) {
      let observer = new IntersectionObserver(this.handleIntersection);
      this.$nextTick(() => {
        observer.observe(this.$refs.releaseItemWrap);
      })
    }

    if(typeof window !== 'undefined'){
      this.imageWidth = this.$refs.releaseItemWrap.offsetWidth;
    }
  },
  methods: {

    handleIntersection(entries, observer) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          this.loadRelated();
          observer.unobserve(entry.target);
        }
      });
    },
    loadRelated(){
      this.$axios.get('releases/related/' + this.release.slug,{ progress: false })
          .then(({data})=>{
            this.release.related = data;
          })
    },
    goToRelease(){

    }
  },
}

</script>

<template>
  <div ref="releaseItemWrap" class="release-item-wrap"
           @mouseover="showRelated = true"
           @mouseleave="showRelated = false" >
    <nuxt-link no-prefetch :to="releaseLink" class="release-item-content">
      <div class="release-item-buttons">
        <div class="release-item-discount">
          <div class="release-item-discount-rate" v-if="release.sale &&  release.percentage > 0">{{release.percentage}}%</div>
<!--          <div class="release-item-trending" v-else>{{$t('Trending')}}</div>-->
        </div>
        <div class="release-item-buttons-item">
          <release-alert :sneaker="release" v-show="!release.sold_out"></release-alert>
          <release-favorite :sneaker="release"/>
        </div>
      </div>
      <div class="release-item-thumbnail" :class="{'show-related': showRelated, 'no-related': !release.related}" >
        <client-only>
          <img class="release-item-thumbnail-image" :data-src="thumb(selectedRelated)" v-lazy-load :alt="selectedRelated.brand + ' ' + selectedRelated.name + ' ' + selectedRelated.sku"
               :style="{width: imageWidth + 'px', height: imageWidth + 'px'}" />
          <img class="release-item-thumbnail-image" src="~/assets/img/sneakers123-thumb.jpg" alt="sneaker thumbnail"  slot="placeholder"
               :style="{width: imageWidth + 'px', height: imageWidth + 'px'}" />
        </client-only>
      </div>
      <div class="release-item-info-dummy" :style="{height: infoHeight + 'px'}"></div>
      <div class="release-item-info" :class="{'show-related': showRelated, 'no-related': !release.related}" @mouseleave="hoverRelatedIndex = null" ref="releaseItemInfo">
        <div class="release-item-info-names">
          <div class="release-item-info-brand">{{release.brand}}</div>
          <div class="release-item-info-name">{{selectedRelated.name}}</div>
          <div class="release-item-info-sku">{{selectedRelated.sku}}</div>
        </div>
        <div class="release-item-info-properties">
          <div class="release-item-info-property">
            <div class="release-item-info-property-value">
              <div class="release-item-info-price-info" :class="{ 'sale-price': release.sale && release.percentage > 0 }">{{releasePrice(selectedRelated)}}</div>
            </div>
          </div>
          <div class="release-item-info-property">
            <div class="release-item-info-property-value">
              <div class="release-item-info-shop-badge" :class="{'sold-out-badge': selectedRelated.sold_out, 'coming-soon-badge': selectedRelated.coming_soon}"></div>
              <div class="release-item-info-shop-count">
                {{selectedRelated.sold_out ? $t('sold-out') : selectedRelated.shop_count + ' ' + $t('shops')}}
              </div>
            </div>
          </div>
        </div>
        <div class="release-item-info-details" :class="{'show-related': showRelated, 'no-related': !release.related}">
          <div class="release-item-related-size-container">
            <span class="release-item-related-size" v-if="hasManyAvailableSizes">{{ $t('several-sizes-available') }}</span>
            <span class="release-item-related-size" v-else>
              Available Size : {{ getAvailableSizes.join(', ') }}
            </span>
          </div>
          <div class="release-item-related-container">
            <div class="release-item-related-item" v-for="(relatedRelease, i) in release.related" @mouseover="hoverRelatedIndex = i"  @mouseleave="hoverRelatedIndex = null">
              <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : relatedRelease.slug}, query: query })">
                <img v-lazy-load :data-src="thumb(relatedRelease)" />
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<style lang="scss" scoped>
.release-item-wrap{
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  border-radius: 16px;
  background: #fff;
  transition: all 0.3s;

  &:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
    transform: translateY(-4px);
  }

  .release-item-content{
    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;

    &:hover{
      text-decoration: none;
    }

    &:focus{
      text-decoration: none;
    }

    &:visited{
      text-decoration: none;
    }



    .release-item-thumbnail{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img{
        border-radius: 16px;
        object-fit: contain;
        object-position: center;
        transition: all 0.3s;
      }

      &.show-related{
        img{
          object-position: 0 -20px;
        }
      }

      &.no-related{
        img{
          object-position: center;
        }
      }
    }

    .release-item-info-dummy{
      width: 100%;
    }
    .release-item-info{
      position: absolute;
      background: #fff;
      border-radius: 16px;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.3s;
      transform: translateY(0);
      display: flex;
      flex-direction: column;

      &.show-related{
        transform: translateY(0);
      }

      .release-item-info-details{
        background-color: rgba(137, 153, 177, 0.2);
        transition: all 0.3s;
        border-radius: 0 0 16px 16px;
        height: 0;

        .release-item-related-size-container{
          height: 0;
          line-height: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          font-weight: normal;
          text-align: center;
          color: #8999b1;
          display: block;
          transition: all 0.3s;
        }

        .release-item-related-container{

          height: 0;
          display: flex;
          width: 100%;
          overflow-x: auto;
          gap: 6px;
          flex-wrap: nowrap;
          flex-direction: row;
          justify-content: center;
          transition: all 0.3s;
          .release-item-related-item{
            width: 0;
            height: 0;
            background: #fff;
            border-radius: 4px;
            overflow: hidden;
            transition: all 0.3s;
            img{
              width: 0;
              height: 0;
              object-fit: contain;
              object-position: center;
              border-radius: 4px;
              transition: all 0.3s;
            }
          }

        }



        &.show-related{
          padding: 8px;
          height: 100px;
          .release-item-related-size-container{
            height: auto;
            padding: 8px 16px;
          }
          .release-item-related-container{
            height: auto;
            padding: 8px 0;

            .release-item-related-item{
              width: 40px;
              height: 40px;
              img{
                width: 40px;
                height: 40px;
              }
            }
          }
        }


        &.no-related{
          height: 0;
          padding: 0;
          .release-item-related-size-container{
            height: 0;
            padding: 0;
          }
          .release-item-related-container{
            height: 0;
            padding: 0;
            .release-item-related-item{
              width: 0;
              height: 0;
              img{
                width: 0;
                height: 0;
              }
            }
          }
        }
      }

      .release-item-info-names{

        padding: 16px 16px 0 16px;
        .release-item-info-brand, .release-item-info-name{
          font-size: 16px;
          font-weight: 800;
          line-height: 16px;
          height: 16px;
          color: #191919;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 6px;
        }
        .release-item-info-name{
          margin-bottom: 8px;
        }
        .release-item-info-sku{
          font-size: 12px;
          font-weight: normal;
          color: #8999b1;
          line-height: 12px;
          height: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 8px;
        }
      }

      .release-item-info-properties{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 6px;
        padding: 0 16px 16px 16px;
        .release-item-info-property{
          display: flex;
          flex-direction: column;
          .release-item-info-property-value{
            display: flex;
            align-items: center;
            .release-item-info-price-info{
              font-size: 16px;
              font-weight: 800;
              color: #191919;
              margin-right: 8px;

              &.sale-price{
                color: #db3d46;
              }
            }
            .release-item-info-shop-badge{
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 4px;
              background: #23d1d8;
              &.sold-out-badge{
                background: #db3d46;
              }
              &.coming-soon-badge{
                background: #ffceba;
              }
            }
            .release-item-info-shop-count{
              font-size: 12px;
              font-weight: 800;
              color: #8999b1;
            }
          }
        }

        @media (max-width: 768px){
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }

    .release-item-buttons{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      .release-item-discount{
        flex: 1;
        display: flex;
        .release-item-discount-rate{
          color: #db3d46;
          font-size: 12px;
          font-weight: 500;
          background: #ffceba;
          padding: 4px 8px;
          border-radius: 4px;
          margin-right: 4px;
        }

        .release-item-trending{
          color: #1a989d;
          font-size: 12px;
          font-weight: 500;
          background: #def9fa;
          padding: 4px 8px;
          border-radius: 4px;
        }
      }

      .release-item-buttons-item{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .release-item-buttons-item-alert{
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #fff;
          border: 1.5px solid #e0ecf5;
          margin-right: 6px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>