<script>
import UDialog from "@/components/ui/dialog.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import {getCountry, getCountryList} from "@/lib/utils";

export default {
  name: "setting-dialog",
  components: {UDialog},
  data() {
    return {
      selectedTab: 0,
      countrySearch: ""
    }
  },
  computed: {
    ...mapState({
      settingDialogVisible: state => state.settingDialogVisible,
    }),

    currentCurrency() {
      const currency = this.$store.state.currency ? this.$store.state.currency.toUpperCase() : "EUR";
      let findItem = this.currencies.find((c) => c.code === currency);
      if(!findItem) {
        findItem = this.currencies[0];
      }
      return findItem
    },
    currencies() {
      return [
        {code: "USD", name: this.$t('label.currencyCode.usd'), symbol: "$"},
        {code: "EUR", name: this.$t('label.currencyCode.eur'), symbol: "€"},
        {code: "GBP", name: this.$t('label.currencyCode.gbp'), symbol: "£"},
        {code: "JPY", name: this.$t('label.currencyCode.jpy'), symbol: "¥"},
      ]
    },
    country(){
      return getCountry(this.$store.state.location.code)
    },
    countries() {
      return getCountryList(this.$store.state.countryCodes)
    },
    currentLocaleName () {
      return this.availableLocales.find((l)=>{ return l.code === this.currentLocale}).name
    },
    currentLocale () {
      return this.$i18n.locale
    },
    availableLocales () {
      return this.$i18n.locales
    },
    dialogSize() {
      return this.$device.isMobile ? "sm" : "md";
    }
  },
  methods: {
    ...mapMutations(["setSettingDialogVisible", "setCountry"]),
    setTab(index) {
      this.selectedTab = index;
    },
    setLocaleItem(locale) {
      this.$router.push(this.switchLocalePath(locale));
      this.closeDialog();
    },
    setCountryItem(country) {
      this.setCountry(country);
      this.$router.go(0);
      this.closeDialog();
    },
    setCurrencyItem(currency) {
      this.$store.commit("setCurrency", currency);
      this.$router.go(0);
      this.closeDialog();
    },
    closeDialog() {
      this.countrySearch = "";
      this.setSettingDialogVisible(false);
    }
  }
}
</script>

<template>
  <u-dialog :show="settingDialogVisible" :onClose="closeDialog" :size="dialogSize" noScroll
            :title="$t('text.settingTitle')" :subTitle="$t('text.settingSubtitle')">
    <div class="setting-container">
      <div class="setting-content">
        <ul class="setting-tabs">
          <li @click="setTab(0)" :class="{'active-tab': selectedTab === 0}">{{ $t('label.language') }}</li>
          <li @click="setTab(1)" :class="{'active-tab': selectedTab === 1}">{{ $t('label.region') }}</li>
          <li @click="setTab(2)" :class="{'active-tab': selectedTab === 2}">{{ $t('label.currency') }}</li>
        </ul>
        <div class="setting-tabs-content">
          <div class="setting-tabs-content-item" v-show="selectedTab === 0">
            <ul class="setting-content-list">
              <li class="setting-content-list-item active locale-item">
                <span class="lang-name">{{ currentLocaleName }}</span>
                <div class="active-icon">
                  <img src="~/assets/icons/ui/check-icon.svg" alt="check" />
                </div>
              </li>
              <li class="setting-content-list-item locale-item" v-for="locale in availableLocales" :key="locale.code" v-show="locale.code !== $i18n.locale" @click="setLocaleItem(locale.code)">
                <span class="lang-name">{{ locale.name }}</span>
              </li>
            </ul>
          </div>
          <div class="setting-tabs-content-item" v-show="selectedTab === 1">
            <div class="setting-content-list-search-wrap">
              <input type="text" class="setting-content-list-search" placeholder="Search" v-model="countrySearch" />
              <div class="setting-content-list-search-delete-icon" @click="countrySearch = ''" v-show="countrySearch">
                <img src="~/assets/icons/ui/search-history-delete.svg" alt="delete" />
              </div>
            </div>
            <ul class="setting-content-list country-list">
              <li class="setting-content-list-item active country-item" v-show="countrySearch === '' || $t(country.name).toLowerCase().includes(countrySearch.toLowerCase())">
                <img v-lazy-load :data-src="country.flag" class="country-flag" :alt="country.code" />
                <span class="country-name">{{ $t(country.name) }}</span>
                <div class="active-icon">
                  <img src="~/assets/icons/ui/check-icon.svg" alt="check" />
                </div>
              </li>
              <li class="setting-content-list-item country-item"
                v-for="c in countries" :key="c.code" v-show="c.code !== country.code && (countrySearch && $t(c.name).toLowerCase().includes(countrySearch.toLowerCase()) || !countrySearch)" @click="setCountryItem(c.code)">
                <img v-lazy-load :data-src="c.flag" class="country-flag" :alt="c.code" />
                <span class="country-name">{{ $t(c.name) }}</span>
              </li>
            </ul>
          </div>
          <div class="setting-tabs-content-item" v-show="selectedTab === 2">
            <ul class="setting-content-list">
              <li class="setting-content-list-item active currency-item">
                <span class="currency-name">{{currentCurrency.symbol}} {{ currentCurrency.name }}</span>
                <div class="active-icon">
                  <img src="~/assets/icons/ui/check-icon.svg" alt="check" />
                </div>
              </li>
              <li class="setting-content-list-item currency-item"
                  v-for="currency in currencies" :key="currency.code" v-show="currency.code !== currentCurrency.code" @click="setCurrencyItem(currency.code)">
                <span class="currency-name">{{ currency.symbol }} {{ currency.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </u-dialog>
</template>

<style scoped lang="scss">
.setting-container{
  display: flex;
  flex-direction: column;

  .setting-content{
    .setting-tabs-content{

      .setting-tabs-content-item{
        position: relative;
        display: flex;
        .setting-content-list-search-wrap{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          padding: 10px 0;
          z-index: 1;
          background-color: #fff;
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
          .setting-content-list-search{
            width: 100%;
            padding: 10px;
            border: 1px solid #dfe6f0;
            border-radius: 5px;
            font-size: 16px;
            outline: none;
          }
          .setting-content-list-search-delete-icon{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }
        .setting-content-list{
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;
          height: 40vh;
          overflow-y: auto;
          overflow-x: hidden;

          &.country-list{
            margin-top: 66px;
            height: calc(40vh - 66px);
          }
          .setting-content-list-item{
            display: flex;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #dfe6f0;
            cursor: pointer;
            position: relative;

            .country-flag{
              width: 28px;
              height: 20px;
              border-radius: 3px;
              margin-right: 5px;
            }
            .lang-name, .country-name, .currency-name{
              margin-left: 10px;
              font-size: 16px;
            }
            .active-icon{
              position: absolute;
              right: 10px;
              display: none;
            }
            &:hover{
              background-color: #f5f5f5;
            }
            &.active{
              background-color: #f5f5f5;
              .active-icon{
                display: block;
              }
            }
          }
        }
      }
    }
    .setting-tabs{
      display: flex;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 8px;
      border-bottom: 1.5px solid #dfe6f0;

      li{
        cursor: pointer;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        color: #191919;
        &:hover{
          font-weight: 800;
        }

        &.active-tab{
          font-weight: 800;
          border-bottom: 2px solid #191919;
        }
      }
    }
  }

  /** mobile **/
  @media (max-width: 767px) {
    .setting-content-list{
      height: 40vh;
    }
  }
}
</style>