<script>

import ReleaseAlert from "@/components/v2/thumbnails/release-alert.vue";
import ReleaseFavorite from "@/components/v2/thumbnails/release-favorite.vue";
import UiButton from "@/components/v2/ui/UiButton.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "my-notification",
  components: {UiButton, ReleaseFavorite, ReleaseAlert},
  data() {
    return {
      sneakers: [],
      isLoading: false,
      removable: null,
      removeComplete: false,
    }
  },
  computed: {
    ...mapState([ 'notifications']),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['removeNotification']),
    async fetchData() {
      this.isLoading = true
      try {
        let {data} = await this.$axios.get('price-alerts');
        this.sneakers = data
      } catch (e) {
        console.log('alerts ', e.response ? e.response.status : e);
      }
      this.isLoading = false
    },

    removeAlert(item) {
      this.removable = item;
      this.removeComplete = false;
      // this.$refs.alertRemoveModal.show();
    },
    async remove() {
      // console.log('to remove - ' + this.removable);
      try {
        await this.removeNotification(this.removable);
        let idx = this._.indexOf(this.sneakers, {slug: this.removable})
        this.sneakers.splice(idx,1)
      }
      catch (e) {
        console.log(e)
      }

      this.removeComplete = true;
      this.removable = null;
      // this.$refs.alertRemoveModal.hide()
    },
    cancel() {
      this.removable = null;
      this.removeComplete = false;
    },
  }
}
</script>

<template>
  <div class="flex flex-col w-full">
    <div v-if="isLoading" class="flex justify-center items-center w-full h-64">
      <img src="~/assets/images/ui/icons/circle-spinner.png" alt="loading" class="w-8 h-8 animate-spin">
    </div>
    <div class="flex flex-col w-full justify-center items-center h-64" v-if="sneakers.length === 0">
      <h5 class="text-white text-sm mb-1">No notifications found</h5>
    </div>
    <div class="flex flex-row flex-wrap [&>*:nth-child(odd)]:border-r" v-else>
      <div  class="flex flex-col w-1/2 relative aspect-[2/3] justify-between border-b border-black " v-for="(release, index) in sneakers" :key="index">
    <span class="absolute top-4 left-4 z-10 text-gray-high text-xs">{{release.sku}}</span>
        <div class="flex flex-col w-full aspect-square items-center justify-center flex-1">
          <client-only>
            <img class="object-contain object-center" :data-src="thumb(release, 'big')" v-lazy-load />
          </client-only>
        </div>
        <div class="flex flex-row w-full p-4 bg-white justify-between items-center  relative">
          <div class="flex flex-col space-y-1 flex-1">
            <span class="text-black text-xs">{{release.brand}}</span>
            <span class="text-black text-xs">{{release.name}}</span>
            <span class="text-black text-xs">{{release.alert.sent? ' ('+$t('Already sent')+')':''}}</span>
            <span class="text-black text-xs">
                        {{$t('Price Alert set for')}}
                        <span>{{price(release.alert.minimum, release.alert.currency)}}</span>
                        {{$t('until')}}
                        <span>{{release.alert.readableExpires}}</span>
            </span>
          </div>
        </div>
        <div class="flex flex-col w-full border-t border-black">
          <ui-button :click="() => removeAlert(release.slug)" color="white" block>{{$t('Delete Alert')}}</ui-button>
        </div>
      </div>
    </div>
    <div class="flex flex-col p-4 justify-center items-center w-full fixed bottom-0 top-0 left-0 right-0 bg-black bg-opacity-50 z-50" v-if="removable">
      <div class="flex flex-col bg-white p-4 w-[300px] lg:w-[400px] rounded-lg">
        <div v-if="removeComplete === false" class="flex flex-col w-full justify-center items-center">
          <div class="flex flex-col w-full justify-center items-center">
            <p class="text-center text-black text-base">{{$t('Do you really want to remove')}}</p>
            <p class="text-center text-black text-base">{{$t('your price alert?')}}</p>
          </div>
          <div class="flex flex-row w-full justify-center items-center gap-2 mt-4">
            <UiButton :click="cancel" color="danger" block size="sm">
              {{$t('label.cancel')}}
            </UiButton>
            <UiButton :click="remove" color="black" block size="sm">
              {{$t('Yes Please')}}
            </UiButton>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-col w-full justify-center items-center">
            <p class="text-center text-black text-base">{{$t('text.priceAlertRemoved')}}</p>
          </div>
          <div class="flex flex-row w-full justify-center items-center mt-4">
            <UiButton :click="cancel" color="black" block size="sm">
              {{$t('label.OK')}}
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>