<script>
import HomeSquareImage from "./components/home-square-image.vue";
import HomeSectionTitle from "./components/home-section-title.vue";

const contents = [
  {
    title: 'The History of the Air Max 1',
    link: '/contents/the-history-of-the-air-max-1',
    image: require('~/assets/images/ui/background/home-content-1-bg.png')
  },
  {
    title: 'History of Air Jordans',
    link: '/contents/history-of-air-jordans',
    image: require('~/assets/images/ui/background/home-content-2-bg.png')
  },
  {
    title: 'Adidas History: 1949 to Now',
    link: '/contents/adidas-history-1949-to-now',
    image: require('~/assets/images/ui/background/home-content-3-bg.png')
  },
  {
    title: 'History of Yeezy',
    link: '/contents/history-of-yeezy',
    image: require('~/assets/images/ui/background/home-content-4-bg.png')
  },
  {
    title: 'History of converse',
    link: '/contents/history-of-converse',
    image: require('~/assets/images/ui/background/home-content-5-bg.png')
  },
  {
    title: 'History of Asics',
    link: '/contents/history-of-asics',
    image: require('~/assets/images/ui/background/home-content-6-bg.png')
  }
];


export default {
  name: "HomeContentsSection",
  components: {HomeSectionTitle, HomeSquareImage},

  data() {
    return {
      selectedContentIndex: 0,
      contents
    }
  },
  computed: {
    backgroundPositionY() {
      return `${this.scrollY / 2}px`
    },
    contentImage() {
      return contents[this.selectedContentIndex].image
    },
  },
  methods: {
    selectContent(index) {
      this.selectedContentIndex = index
    }
  }
}
</script>

<template>
  <section
      class="flex flex-col lg:flex-row w-full border-b border-black"
      :style="{backgroundPositionY}"
  >
    <HomeSquareImage position="left" :image="contentImage" k="content"/>
    <div class="w-full lg:basis-1/2 lg:aspect-square flex flex-col justify-between">
      <HomeSectionTitle :title="$t('text.homePage.homeContentsSectionTitle')" :subtitle="$t('text.homePage.homeContentsSectionSubTitle')"/>
      <ul class="flex flex-col w-full p-4 lg:p-8 space-y-4">
        <li v-for="(content, index) in contents" :key="index" @mouseover="selectContent(index)">
          <nuxt-link :to="localePath(content.link)" :class="{
            'text-back text-md': true,
            'no-underline': index !== selectedContentIndex,
            'font-semibold underline': index === selectedContentIndex
          }">
            {{content.title}}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </section>
</template>
