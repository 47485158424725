<template>
  <div class="best-prices-card" v-if="products.length > 0">
    <div class="heading"> {{ $t('best-prices') }} </div>
    <div class="body">
        <ul class="prices-list">
            <li class="price-item" v-for="(product, index) in products.splice(0, 3)" :key="index">
                <BestPricesItem :product="product" />
            </li>
        </ul>
        <div class="see-all-prices" v-if="products.length > 3">
            <a href="#shops-list">
                <span>
                    {{ $t('see-all-prices') }}
                </span>
                <ArrowRightWhite/>
            </a>
        </div>
    </div>
  </div>
</template>

<script>
import BestPricesItem from './BestPricesItem.vue';
import ArrowRightWhite from "../icons/arrow-right-white";

export default {
    name: "BestPrices",
    components: { BestPricesItem , ArrowRightWhite},
    computed: {
        products () {
            let array = this.$parent.notFeatured;
            array = array.filter(item => item.availability === "InStock");
            return array;
        }
    },
}
</script>

<style>

</style>