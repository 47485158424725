<script>

import ReleaseAlert from "@/components/v2/thumbnails/release-alert.vue";
import ReleaseFavorite from "@/components/v2/thumbnails/release-favorite.vue";
import UiButton from "@/components/v2/ui/UiButton.vue";
import {mapActions, mapState} from "vuex";
import ReleaseList from "~/components/v2/sections/release/release-list.vue";
import ReleaseThumbnail from "~/components/v2/thumbnails/release-thumbnail.vue";

export default {
  name: "my-favorites",
  components: {ReleaseThumbnail, ReleaseList, UiButton, ReleaseFavorite, ReleaseAlert},
  data() {
    return {
      releases: [],
      isLoading: false,
    }
  },
  computed: {
    ...mapState([ 'favorites']),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.releases = [];
      this.isLoading = true
      try{
        let {data} = await this.$axios.get('favorites');
        console.log('data', data)
        this.$store.commit('setFavorites', data);
        this.releases = data;
      }
      catch(e) {
        console.log('fav ', e.response ? e.response.status : e);
      }
      this.isLoading = false
    },
  }
}
</script>

<template>
  <div class="flex flex-col w-full">
    <div v-if="isLoading" class="flex justify-center items-center w-full h-64">
      <img src="~/assets/images/ui/icons/circle-spinner.png" alt="loading" class="w-8 h-8 animate-spin">
    </div>
    <div class="flex flex-col w-full justify-center items-center h-64" v-if="releases.length === 0">
      <h5 class="text-white text-sm mb-1">No favorite.</h5>
    </div>
    <div class="flex flex-row flex-wrap w-full [&>*:nth-child(odd)]:border-r" v-else>
      <div class="flex border-r border-b border-black basis-1/2" v-for="(sneaker, i) in releases" :key="i" >
        <ReleaseThumbnail :release="sneaker"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>