<script>
export default {
  name: "u-select",
  props: {
    value: { type: String, default: "" },
    options: { type: Array, default: () => [] },
    error: { type: String, default: "" },
    bb: { type: Boolean, default: true },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    select(option) {

      if(option.href){
        if(option.target === '_blank')
          window.open(option.href, '_blank');
        else
          this.$router.push(option.href);
      }

      this.$emit("input", option.value);
      this.isOpen = false;
    }
  },
  computed: {
    selectedOption() {
      const val = this.options.find(option => option.value === this.value);
      return val ? val : (this.options && this.options.length > 0 ? this.options[0] : {
        label: "",
        value: ""
      });
    }
  }
}
</script>

<template>
  <div class="u-select">
    <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
      :class="{'bb-none': !bb}" @click="toggle">
      <span class="default-option-label-wrap">
        <span class="default-option-label">{{selectedOption.label}}</span>
      </span>
      <span class="select-arrow" :class="{'open': isOpen}">
        <img src="~/assets/icons/ui/select-arrow.svg"  alt="select-arrow" width="22" height="22"/>
      </span>
    </button>

    <ul class="u-select-items" :class="{'open': isOpen}" role="listbox" aria-labelledby="listbox-label">
      <li class="u-select-item" v-for="(option, index) in options" :key="index" @click="select(option)" :class="{'selected': option.value === value}">
        <span class="select-item-label">{{option.label}}</span>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.u-select {
  display: flex;
  flex-direction: column;
  position: relative;
  > button {
    position: relative;
    display: flex;
    width: 100%;
    height: 48px;
    background-color: #fff;
    border: 1.5px solid #191919;
    border-radius: 4px;
    padding: 0;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    color: #191919;
    text-align: center;
    outline: none;
    cursor: pointer;

    &.bb-none {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: none;
    }

    .select-arrow {
      position: absolute;
      right: 10px;
      top: 50%;

      transition: transform 0.3s ease-in-out;

      transform: translateY(-50%);

      &.open {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .default-option-label-wrap{
      display: flex;
      flex-shrink: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      .default-option-label {
        display: block;
        text-align: center;
        margin-left: 8px;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #191919;

      }
    }

  }

  .u-select-items {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    border: 1.5px solid #191919;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &.open {
      opacity: 1;
      visibility: visible;
    }
    .u-select-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      padding: 0 8px;
      font-weight: normal;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
      }

      &.selected {
        font-weight: bold;
        background-color: #f5f5f5;
      }
    }
  }

}
</style>