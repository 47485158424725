<template>
     <lazy-hydrate when-visible>
        <article class="suggestion" id="suggestion">
            <div class="line">
                <div class="suggestion-title">{{$t('You might also like')}}</div>
            </div>
            <div class="line row">
                <template v-for="related in sneaker.related">
                    <SneakerThumbnail :isNewTab="true" :sneaker="related"></SneakerThumbnail>
                </template>
            </div>
        </article>
    </lazy-hydrate>
</template>

<script>

import SneakerThumbnail from "@/components/Sneakers/Thumbnail";

export default{
    name: "RelatedProducts",
    components: {
        SneakerThumbnail
    },
    props: {
        sneaker: {
            default: {},
            type: Object,
        }
    }
}
</script>