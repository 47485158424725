<template>
  <div class="flex flex-col w-full">
    <ui-breadcrumbs :items="breadcrumbItems"></ui-breadcrumbs>

    <div class="flex flex-col w-full">
      <div class="flex flex-col w-full p-4"
           v-if="data.introduction">
        <link-parser :content="data.introduction"/>
      </div>
      <div class="flex flex-col w-full p-4">
        <h2 class="text-2xl font-bold text-gray-800">{{$t('category-latest-title', data.replacements)}}</h2>
        <p class="text-gray-800 text-sm">{{$t('category-latest-subtitle', data.replacements)}}</p>
      </div>

      <div class="flex flex-row flex-wrap border-t border-b border-black">
        <release-carousel :releases="data.latest" :cols="$device.isDesktop ? 4 : 2"></release-carousel>
      </div>


      <div class="flex flex-col w-full p-4">
        <p v-html="$t('category-latest-text_html', data.replacements)">
        </p>
      </div>


      <div class="flex flex-row justify-center p-4">
        <ui-button :to="sneakerLocalePath(data.showAllRoute.path, {sort:'latest', ...data.showAllRoute.query})">{{$t('Show more')}}</ui-button>
      </div>

      <div class="flex flex-col w-full p-4">
        <h2 class="text-2xl font-bold text-gray-800"
        >{{$t('category-cheapest-title', data.replacements)}}</h2>
        <p class="text-gray-800 text-sm"
        >{{$t('category-cheapest-subtitle', data.replacements)}}</p>
      </div>


      <div class="flex flex-row flex-wrap border-t border-b border-black">
        <release-carousel :releases="data.cheapest" :cols="$device.isDesktop ? 4 : 2"></release-carousel>
      </div>

      <div class="flex flex-col w-full p-4"
           v-if="data.priceChart.datasets[0].data.length">
        <h2 class="text-2xl font-bold text-gray-800"
        >{{$t('category-price_chart-title', data.replacements)}}</h2>
        <p class="text-gray-800 text-sm"
        >{{$t('category-price_chart-subtitle', data.replacements)}}</p>
        <!--         <client-only>-->
        <!--            <line-chart v-if="showLine" :dataset="data.priceChart" :options="chartOptions" :width="null" :height="null"/>-->
        <!--         </client-only>-->
        <client-only>
          <trend-chart class="py-4"
                       :datasets="data.priceChart.datasets"
                       :labels="{
                               xLabels: data.priceChart.labels,
                               yLabels: 5,
                               yLabelsTextFormatter: val=>{return price(val)}
                             }"

                       :grid="{horizontalLines: true}"
          ></trend-chart>
        </client-only>

      </div>


      <div class="flex flex-col w-full p-4">
        <p v-html="$t('category-price_chart-text_html', data.replacements)">
        </p>
      </div>

      <!-- price alarm -->

      <div class="flex flex-col w-full p-4">
        <h2 class="text-2xl font-bold text-gray-800"
        >{{$t('category-price_alert-title', data.replacements)}}</h2>
        <p class="text-gray-800 text-sm"
         >{{$t('category-price_alert-subtitle', data.replacements)}}</p>
        <l-img src="~/assets/images/price-alert-screenshot.png" img-class="w-100 img-fluid" />
      </div>

      <div class="flex flex-col w-full p-4">
        <p v-html="$t('category-price_alert-text_html', data.replacements)">
        </p>
      </div>
      <!-- All colors and models -->

      <div class="flex flex-col w-full p-4">
        <h2 class="text-2xl font-bold text-gray-800"
        >{{$t('category-all_models-title', data.replacements)}}</h2>
        <p class="text-gray-800 text-sm"
        >{{$t('category-all_models-subtitle', data.replacements)}}</p>
      </div>

      <div class="flex flex-row flex-wrap border-t border-b border-black">
        <release-carousel :releases="data.all" :cols="$device.isDesktop ? 4 : 2"></release-carousel>
      </div>
      <div class="flex flex-col w-full p-4">
        <ui-button :to="sneakerLocalePath(data.showAllRoute.path, data.showAllRoute.query)">{{$t('Show all')}}</ui-button>
      </div>

      <!-- shops -->
<!--      <div class="flex flex-col w-full p-4">-->
<!--        <h2 class="text-2xl font-bold text-gray-800"-->
<!--        >{{$t('category-shops-title', data.replacements)}}</h2>-->
<!--        <p class="text-gray-800 text-sm"-->
<!--        >{{$t('category-shops-subtitle', data.replacements)}}</p>-->
<!--      </div>-->
<!--      <div class="row line justify-content-center justify-content-lg-start d-none d-md-flex shops">-->
<!--        <div class="col-6 col-md-4 col-lg-2 logo-wrap" v-for="(shop, index) in data.shops" :key="index">-->
<!--          <nuxt-link no-prefetch :to="localePath({name:'shops-shop', params:{shop:  shop.slug}})">-->
<!--            <l-img img-class="shop-logo" :src="shop.logo" :alt="shop.name"></l-img>-->
<!--          </nuxt-link>-->
<!--        </div>-->
<!--      </div>-->

      <!-- seo text -->
      <div class="flex flex-col w-full p-4"
           v-if="currentRouteMetas.seo_description">
        <div class="flex flex-row">
          <link-parser :content="currentRouteMetas.seo_description"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import UiBreadcrumbs from "@/components/v2/ui/UiBreadcrumbs.vue";
import LinkParser from "@/components/linkParser.vue";
import SneakerThumbnail from "@/components/Sneakers/Thumbnail.vue";
import ReleaseCarousel from "@/components/v2/thumbnails/release-carousel.vue";
import ArrowRight from "@/components/icons/arrow-right.vue";
import UiButton from "@/components/v2/ui/UiButton.vue";
import TrendChart from "vue-trend-chart";

export default {
  name: "release-model",
  components: {UiButton, ArrowRight, ReleaseCarousel, SneakerThumbnail, LinkParser, UiBreadcrumbs, TrendChart},
  props     : {
    data: {
      type: Object,
      default: () => {
      }
    },
    breadcrumbItems: {
      type: Array
    }
  },
  computed: {
    subHeader() {
      if(this.data.replacements) {
        return {
          minPrice: this.data.replacements.minPrice,
          shopsCount: this.data.replacements.shops,
          totalCount: this.data.replacements.totalCount,
        }
      }
    },
  },
  mounted() {
    this.showLine = true;
  },
  data() {
    return {
      currentRouteMetas: this.$store.state.currentRouteMetas,
      sneakerRouteHeader: this.$store.state.sneakerRouteHeader,
      showLine: false,
    }
  },
}
</script>

<style scoped>

</style>