<script>
export default {
  name: "u-dialog-content"
}
</script>

<template>
  <div class="u-dialog-content">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">

</style>