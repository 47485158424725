<script>
import UiTabs from "../../ui/UiTabs.vue";
import UiLabelInput from "../../ui/UiLabelInput.vue";
import UiButton from "../../ui/UiButton.vue";
import {mapActions, mapMutations} from "vuex";
import formMixin from "../../../../plugins/formMixin";
import DrawerHeader from "../../layouts/components/drawer-header.vue";

export default {
  name: "forgot-password-drawer",
  created() {
    this.$root.$on('global-drawer-close', this.closeDrawer);
  },
  components: {DrawerHeader, UiButton, UiLabelInput, UiTabs},
  mixins: [formMixin],
  data() {
    return {
      titles: {
        forgot: this.$t('Reset Password'),
        afterForgot: this.$t('We have e-mailed your password reset link!'),
      },
      model: {
        email: ''
      },
      errors: {
        email: { status: null , message: null}
      },
      isSubmitted: false
    }
  },
  methods: {
    ...mapMutations(['setGlobalDrawerSection']),
    ...mapActions(['closeGlobalDrawer']),


    closeDrawer() {
      this.model.email = '';
    },
    async submitForgot(){
      if (this.errors.email.status) {
        return
      }
      this.$nuxt.$loading.start();
      try {
        await this.$axios.post('/password/email', {email: this.model.email, locale: this.$i18n.locale});
        this.isSubmitted = true;
      }
      catch (error) {
        console.log('forgot ', error);
        console.log(error.response.data.errors);
        this._.each(error.response.data.errors, (message, input) => {
          this.errors[input].status = true;
          this.errors[input].message = this._.join(message, '<br>');
        })
      }
      this.$nuxt.$loading.finish();
    },
  },
  watch: {
    'model.email': function (val) {
      this.checkEmail(val);
    }
  },
}
</script>

<template>
  <div>
    <drawer-header :title="isSubmitted ? titles.afterForgot : titles.forgot" :onBack="() => {
      setGlobalDrawerSection('sign');
      closeDrawer();
    }"></drawer-header>
    <form @submit.prevent="submitForgot" class="flex flex-col p-4">
      <div class="flex flex-col">
        <UiLabelInput :label="$t('label.yourEmail')" v-model="model.email" :error="errors.email" :required="true"></UiLabelInput>
        <UiButton type="submit" class="mt-4" size="md" color="primary"
                  :disabled="!model.email">{{$t('Send Reset Link')}}</UiButton>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>