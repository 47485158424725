<script>
export default {
  name: "UiBreadcrumbs",
  props: {
    items:{
      type: Array,
      required: true,
    }
  }
}
</script>

<template>
  <div class="flex flex-row w-full justify-start items-center border-b border-black py-3 px-4 lg:px-8
  text-xs font-inter text-font-line-color flex-wrap">
    <template v-for="level in items">
      <nuxt-link no-prefetch class="text-xs font-inter text-font-line-color mr-1" :to="level.lnk">{{level.text}}</nuxt-link>
      <div class="text-xs font-inter text-font-line-color mr-1" v-if="level !== items[items.length - 1]">/</div>
    </template>
  </div>
</template>

<style scoped>

</style>