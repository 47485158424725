<script>
export default {
  name: "UiButton",
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    bold: {
      type: Boolean,
      required: false,
      default: true,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
    },
    click: {
      type: Function,
      required: false,
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
    target: {
      type: String,
      required: false,
      default: '_self',
    },
    underline: {
      type: Boolean,
      required: false,
      default: false,
    },
    fontSize: {
      type: String,
      required: false,
      default: 'md',
    },
    height: {
      type: String,
      required: false,
      default: 'md',
    },
  }
}
//'black': '#131416',
// 'gray-low': '#e8ebed',
//     'gray-medium': '#c9cdd2',
//     'gray-high': '#6c7781',
//     'danger': '#db3d45',
//     'primary': '#23d1d8',
</script>

<template>
  <div class="flex flex-col w-full" :style="{width: width ? width + 'px' : block ? '100%' : 'auto'}">
    <nuxt-link v-if="to" :to="localePath(to)" :class="{
      'flex flex-col py-2 px-4 rounded justify-center items-center': true,
      'underline': underline,
      'font-bold': bold,
      'w-full flex-1': block,
      'cursor-not-allowed opacity-50': disabled,
      'bg-black text-white border-black': color === 'black',
      'bg-gray-low text-black border-gray-low': color === 'gray-low',
      'bg-gray-medium text-black border-gray-medium': color === 'gray-medium',
      'bg-gray-high text-white border-gray-high': color === 'gray-high',
      'bg-danger text-white border-danger': color === 'danger',
      'bg-primary text-white border-primary': color === 'primary',
      'h-6 text-xs px-2': size === 'xs',
      'h-8 text-sm px-3': size === 'sm',
      'h-10 text-base px-4': size === 'md',
      'h-12 text-lg px-5': size === 'lg',
      'text-xs': fontSize === 'xs',
      'text-sm': fontSize === 'sm',
      'text-base': fontSize === 'md',
      'text-lg': fontSize === 'lg',
      'min-h-6': height === 'xs',
      'min-h-8': height === 'sm',
      'min-h-10': height === 'md',
      'min-h-12': height === 'lg',

    }" :target="target">
      <slot></slot>
    </nuxt-link>
    <button v-if="click || type === 'submit'" @click="() => {
      if(disabled) return;
      if(click) click();
    }"
            :disabled="disabled"
            :type="type === 'submit' ? 'submit' : 'button'"
            :class="{
      'hover:underline rounded-xs w-full': true,
      'bg-black text-white border-black': color === 'black',
      'bg-gray-low text-black border-gray-low': color === 'gray-low',
      'bg-gray-medium text-black border-gray-medium': color === 'gray-medium',
      'bg-gray-high text-white border-gray-high': color === 'gray-high',
      'bg-danger text-white border-danger': color === 'danger',
      'bg-primary text-white border-primary': color === 'primary',
      'h-6 text-xs px-2': size === 'xs',
      'h-8 text-sm px-3': size === 'sm',
      'h-10 text-base px-4': size === 'md',
      'h-12 text-lg px-5': size === 'lg',
      'cursor-not-allowed opacity-50': disabled
    }">
      <slot></slot>
    </button>
  </div>
</template>

<style scoped>

</style>