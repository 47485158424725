import { render, staticRenderFns } from "./return-policy.vue?vue&type=template&id=43779c08&scoped=true"
import script from "./return-policy.vue?vue&type=script&lang=js"
export * from "./return-policy.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43779c08",
  null
  
)

export default component.exports