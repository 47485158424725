<template>
  <div v-if="showPriceAlertDialog" class="flex flex-col p-4 justify-center items-center w-full fixed bottom-0 top-0 left-0 right-0 bg-black bg-opacity-50 z-50">
    <div class="flex flex-col bg-white w-[300px] lg:w-[400px]" v-if="!complete">
      <div class="flex flex-row w-full relative border-b border-black p-4">
        <div class="flex-1 text-base font-bold text-black">
          {{$t('Minimum price alert')}}
        </div>
        <div class="absolute right-4 top-4 cursor-pointer flex items-center h-6">
          <img src="~/assets/images/ui/icons/close.png" alt="close" class="h-6 cursor-pointer" @click="closePriceAlertDialog" />
        </div>
      </div>
      <div v-if="!_.isEmpty(sneaker)" class="flex flex-row border-b border-black px-4">
        <div class="text-base font-bold text-black">
          <img :src="thumb(sneaker)" :alt="sneaker.brand + ' ' +sneaker.name" class="w-32 h-32 object-cover object-center" />
        </div>
        <div class="flex-1 flex flex-col justify-between w-full p-4">
          <div>
            <div class="text-black text-xs font-normal mb-2">{{sneaker.brand}}</div>
            <div class="text-black text-xs font-normal">{{sneaker.name}}</div>
          </div>
          <div>
            <div class="text-black text-xs font-normal">{{releasePrice(sneaker)}}</div>
          </div>
        </div>

      </div>
      <div class="flex flex-col w-full border-b border-black p-4">
        <div class="text-black text-xs font-normal mb-2">{{$t('Desired minimum value')}}</div>
        <div class="text-primary text-xl font-semibold mb-2">{{price(minimum ? minimum : maxPrice)}}</div>
        <div class="flex flex-col w-full">
          <client-only>
            <range-slider
                :style="{
              width: '100%'


                }"
                min="1"
                :max="Math.floor(maxPrice) + 1"
                step="1"
                v-model="minimum">
            </range-slider>
          </client-only>
        </div>
      </div>
      <div class="flex flex-col w-full border-b border-black p-4">
        <div class="text-base font-bold text-black">
          <div class="mb-2">{{$t('Timeout')}}</div>
          <div class="font-normal text-xs mb-4">
            {{$t('After the specified time, the alert will be deleted')}}
          </div>
        </div>
        <div class="flex flex-col w-full gap-2">
          <div class="flex flex-row w-full gap-2">
            <div :class="{
              'flex flex-col justify-center items-center w-1/2 py-2 border border-black cursor-pointer hover:bg-primary hover:text-white hover:border-primary': true,
              'bg-primary text-white border-primary': expires === 1,
            }"
                 @click="expires = 1">
              1 {{$t('month')}}
            </div>
            <div :class="{
              'flex flex-col justify-center items-center w-1/2 py-2 border border-black cursor-pointer hover:bg-primary hover:text-white hover:border-primary': true,
              'bg-primary text-white border-primary': expires === 2,
            }" @click="expires = 2">
              2 {{$t('month')}}
            </div>
          </div>
          <div class="flex flex-row w-full gap-2">
            <div :class="{
              'flex flex-col justify-center items-center w-1/2 py-2 border border-black cursor-pointer hover:bg-primary hover:text-white hover:border-primary': true,
              'bg-primary text-white border-primary': expires === 3,
            }" @click="expires = 3">
              3 {{$t('month')}}
            </div>
            <div :class="{
              'flex flex-col justify-center items-center w-1/2 py-2 border border-black cursor-pointer hover:bg-primary hover:text-white hover:border-primary': true,
              'bg-primary text-white border-primary': expires === 4,
            }" @click="expires = 4">
              4 {{$t('month')}}
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full border-b border-black p-4">
        <UiLabelInput :disabled="loggedIn" type="email" v-model="email" :label="$t('E-mail address')"/>
      </div>

      <div class="flex flex-col w-full p-4">
        <UiButton type="button" size="md" color="primary" :click="add" block>
          {{$t('Set alert')}}
        </UiButton>
      </div>

    </div>
    <div class="flex flex-col bg-white w-[300px] lg:w-[400px]" v-else>
      <div class="flex flex-col w-full justify-center items-center p-4">

        <p class="text-center text-black text-base">{{$t('Succesfully added alert')}}</p>
        <p class="text-center text-black text-base">{{$t('You will be informed with your selection when the price reaches the one you set')}}</p>
      </div>
      <div class="flex flex-row w-full justify-center items-center p-4">
        <UiButton :click="closePriceAlertDialog" color="black" block size="sm">
          {{$t('label.OK')}}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import RangeSlider from "vue-range-slider";
import UiLabelInput from "~/components/v2/ui/UiLabelInput.vue";
import UiButton from "~/components/v2/ui/UiButton.vue";

export default {
  name : 'price-alert-setting-dialog',
  data(){
    return {
      // sneaker : {},
      email : this.$store.state.auth.user ? this.$store.state.auth.user.email :'',
      error : false,
      minimum : 0,
      expires : 2,
      complete: false,
    }
  },
  computed:{
    ...mapState({sneaker: 'chosenForNotify'}),
    ...mapState(['showPriceAlertDialog']),
    ...mapGetters(['currency']),
    maxPrice(){
      return this.sneaker.price_eur ? this.sneaker['price_' + this.currency] :
          this.findReleasePrice(this.sneaker)
    },
  },
  watch: {
    sneaker() {
      this.complete = false;
      this.minimum = this.maxPrice;
      this.error = false;
      this.expires = 2;
      this.email =  this.$store.state.auth.user ? this.$store.state.auth.user.email : '';
    }
  },
  methods: {
    ...mapMutations(['addNotification']),
    ...mapActions(['closePriceAlertDialog']),
    async add() {
      this.error = false;
      this.$nuxt.$loading.start();
      try {
        await this.$axios.post('/price-alerts', this.params() )
        if (this.loggedIn) {
          this.addNotification(this.sneaker.slug)
        }
        this.complete = true;
      }
      catch (e) {
        console.log('alerts ', e.response ? e.response.status : e);
        this.error = true;
      }
      this.$nuxt.$loading.finish();
    },
    // remove(slug){
    // 	if(auth())
    // 		return authApi.delete('/price-alerts/' + slug)
    // 			.then(()=>{
    // 				localApi.delete('/price-alerts/' + slug).then(()=>{
    // 					this.removed(slug)
    // 				})
    // 			});
    // },
    params() {
      return { email: this.email,
        minimum: this.minimum,
        expires : this.expires,
        slug : this.sneaker.slug,
        locale : this.$i18n.locale,
        currency : this.currency.toUpperCase()
      }
    },
  },
  components:{UiButton, UiLabelInput, RangeSlider}
}
</script>