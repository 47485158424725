<script>
export default {
  name: "u-accordion-item",
  props: {
    title: { type: String, default: "" },
    boldTitle: { type: Boolean, default: false },
  },
  data() {
    return {
      itemsData: [],
      open: false,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open;
    }
  },
  computed: {
  }
}
</script>

<template>
  <div class="u-accordion-item">
    <div class="accordion-header" @click="toggle">
      <div class="accordion-title" :style="boldTitle ? 'font-weight: 700' : 'font-weight: 500'">{{ title }}</div>
      <div class="accordion-icon">
        <img src="~/assets/icons/ui/accordion-arrow.svg" alt="accordion-arrow" :class="{'open': open}" />
      </div>
    </div>
    <div class="accordion-content" :class="{'open': open}">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.u-accordion-item {
  border-top: 1.5px solid #e0ecf5;
  &:last-child {
    border-bottom: 1.5px solid #e0ecf5;
  }
  .accordion-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 55px;
    .accordion-title {
      font-size: 16px;
      font-weight: 500;
      color: #191919;
    }
    .accordion-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      img {
        transition: transform 0.3s ease-in-out;
        transform: rotate(180deg);
        &.open {
          transform: rotate(0deg);
        }
      }
    }
  }
  .accordion-content {
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    color: #191919;

    &.open {
      max-height: 1000px;
    }
  }
}

</style>