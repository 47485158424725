<script>

export default {
  name: "u-container"
}
</script>

<template>
  <section class="u-container">
    <slot></slot>
  </section>
</template>

<style scoped lang="scss">
  .u-container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;


    /** pad **/
    @media (max-width: 1279px) {
      padding: 0 20px;
    }

    /** ipad **/
    @media (max-width: 1023px) {
      margin: 0 10px;
    }

    /** mobile **/
    @media (max-width: 767px) {
      margin: 0 10px;
      padding: 0;
    }

  }
</style>