<script>
import UiTabs from "../../ui/UiTabs.vue";
import SignInForm from "./components/sign/sign-in-form.vue";
import SignUpForm from "./components/sign/sign-up-form.vue";
import DrawerHeader from "../../layouts/components/drawer-header.vue";
import {mapActions, mapState} from "vuex";
import UiLabelInput from "@/components/v2/ui/UiLabelInput.vue";
import MyAccountForm from "@/components/v2/sections/drawers/components/mypage/my-account-form.vue";
import MyNotification from "@/components/v2/sections/drawers/components/mypage/my-notification.vue";
import MyFavorites from "@/components/v2/sections/drawers/components/mypage/my-favorites.vue";
import UiButton from "~/components/v2/ui/UiButton.vue";

export default {
  name: "mypage-drawer",
  components: {
    UiButton,
    MyFavorites, MyNotification, MyAccountForm, UiLabelInput, DrawerHeader, SignUpForm, UiTabs, SignInForm},
  created() {
    this.$root.$on('global-drawer-close', this.closeDrawer);
  },
  data() {
    return {
      tabs: [
        {title: this.$t('My favorite pairs')},
        {title: this.$t('My notifications')},
        {title: this.$t('My account')},
      ],
      tabIndex: 0,
    }
  },
  computed: {
    ...mapState({storeUser: store=>store.auth.user}),
    user() {
      return {...this.storeUser}
    },
    headerTitle() {
      return this.tabs[this.tabIndex].title
    }
  },
  methods: {
    ...mapActions(['closeGlobalDrawer']),
    closeDrawer() {
    },
    logout(){
      this.$store.dispatch('logout');
      this.closeGlobalDrawer();
      this.$router.push(this.localePath('index'));
    },
  }
}
</script>

<template>
  <div class="flex flex-col w-full h-full">
    <drawer-header :title="headerTitle"></drawer-header>
    <div class="flex flex-col items-center min-h-36 lg:min-h-44 justify-center border-b border-black flex-none">
      <h1 class="text-center text-2xl font-bold">{{ user.name }}</h1>
      <p class="text-center text-base mt-1 mb-2">{{ user.email }}</p>
      <UiButton :click="logout" color="black">{{$t('Logout')}}</UiButton>
    </div>
    <div class="w-full overflow-y-auto h-full pb-32 lg:pb-0">
      <div class="flex flex-col w-full h-full">
        <div class="flex border-b border-black flex-row justify-start gap-2 px-2 flex-none">
          <div
              v-for="(tab, index) in tabs"
              :key="index"
              @click="() => {
            tabIndex = index;
          }"
              :class="{
              'py-2': true
            }"
          >
            <div
                :class="{

              'cursor-pointer rounded-[16px] min-h-8 flex flex-col justify-center items-center px-3 text-xs lg:text-sm': true,
              'bg-black text-white border border-black': tabIndex === index,
              'bg-white text-black border border-black': tabIndex !== index,
              }">
              {{ tab.title }}
            </div>
          </div>
        </div>
        <div class="p-0 overflow-y-auto flex-auto pb-32 lg:pb-0">
          <div v-if="tabIndex === 0">
            <div>
              <my-favorites></my-favorites>
            </div>
          </div>
          <div v-if="tabIndex === 1">
            <my-notification></my-notification>
          </div>
          <div v-if="tabIndex === 2">
            <my-account-form></my-account-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>