<template>
  <div @click.prevent="favClick" class="cursor-pointer rounded-full  flex items-center justify-center">
    <img src="~/assets/images/ui/icons/heart.png" alt="bell icon" class="w-6 h-6" v-if="!isFav" />
    <img src="~/assets/images/ui/icons/heart-fill.png" alt="bell icon" class="w-6 h-6" v-else />
  </div>
</template>

<script>
	import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

    export default {
		name: 'release-favorite',
		props: ['sneaker'],
		computed: {
			...mapState(['favorites', 'onceLoggedIn']),
			isFav() {
				return this._.indexOf(this.favorites, this.sneaker.slug) !== -1
			}
		},
		methods: {
			...mapActions(['addFavourites', 'openGlobalDrawer']),
			...mapMutations(['setChosenFavorite']),
			async favClick() {
				if (this.loggedIn) {
					this.setChosenFavorite(this.sneaker);
					try {
						await this.addFavourites(this.sneaker);
						if (this.isFav) {
							this.$root.$emit('bv::show::modal','favoriteModal');
							setTimeout( () => {
								this.$root.$emit('bv::hide::modal','favoriteModal');
							}, 5000);
						}
					}
					catch (e) {
						console.log(e);
					}
				} else {
          this.openGlobalDrawer('sign')
				}

			}
		}
    }
</script>
