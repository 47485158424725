<script>
import formMixin from "../../../../../../plugins/formMixin";
import {mapActions, mapMutations, mapState} from "vuex";
import UiLabelInput from "../../../../ui/UiLabelInput.vue";
import UiButton from "../../../../ui/UiButton.vue";
import UiCheckbox from "../../../../ui/UiCheckbox.vue";
import LinkParser from "../../../../../linkParser.vue";
import UiLabelCheckbox from "../../../../ui/UiLabelCheckbox.vue";

export default {
  name: "sign-up-form",
  components: {UiLabelCheckbox, LinkParser, UiCheckbox, UiButton, UiLabelInput},
  mixins: [formMixin],
  created() {
    this.$root.$on('change-sign-tab', this.closeDrawer);
  },
  data() {
    return {
      model: {
        email          : '',
        password       : '',
        confirmPassword: '',
        terms          : false,
        subscribe      : false
      },
      errors: {
        email          : {status: null, message: null},
        password       : {status: null, message: null},
        // passwordConfirm: {status: null, message: null},
        terms          : {status: null, message: null},
        recaptcha_token: {status: null, message: null},
      },
      titles: {
        login: ('<span class="atention">'+this.$t('Hey!')+'</span> '+ this.$t('Welcome Back')),
        forgot: this.$t('Reset Password'),
        afterForgot: this.$t('We have e-mailed your password reset link!'),
        afterReg   : ('<span class="atention">' + this.$t('Perfect!') + '</span> ' + this.$t('Never miss a pair again')),
        reset: this.$t('Reset Password'),
        afterReset: ('<span class="atention">'+this.$t('Perfect!')+'</span> '+this.$t('Your password has changed!')),
      },
      submitTexts: {
        signUp: this.$t('Complete Registration'),
      },
      registrationComplete: false
    }
  },

  computed: {
    ...mapState(['protectedPage', 'location', 'currentRouteMetas']),
  },
  methods: {
    ...mapActions(['closeGlobalDrawer']),
    async registerSubmit()
    {
      if (!this.checkForm()) {
        return
      }
      const reToken = await this.submitRecaptcha();
      this.$axios.post('/register', {
        email          : this.model.email,
        password       : this.model.password,
        subscribe      : this.model.subscribe,
        location       : this.location.code,
        locale         : this.$i18n.locale,
        recaptcha_token: reToken
      }).then(resp => {
        this.registrationComplete = true;
      }).catch(e => {
        console.log(e.response.data.errors);
        this._.each(e.response.data.errors, (message, input) => {
          this.errors[input].status = true;
          this.errors[input].message = this._.join(message, '<br>');
        })
      })
    },
    async submitRecaptcha()
    {
      let token = null;
      try {
        token = await this.$recaptcha.execute('register');
        // console.log('ReCaptcha token:', token);
      } catch (error) {
        console.log('Login error:', error);
      }
      return token;
    },
    checkForm()
    {
      this.checkEmail(this.model.email);
      this.checkPassword(this.model.password);
      // this.checkConfirmPassword(this.model.confirmPassword);
      this.checkTerms();
      for (let err in this.errors) {
        if (this.errors[err].status) {
          return false
        }
      }
      return true
    },
    checkTerms()
    {
      this.errors.terms.status = !this.model.terms;
      this.errors.terms.message = this.errors.terms.status === true ? this.$t('text.pleaseAcceptTermsAndConditions') : null;
    },

    closeDrawer() {
      this.model.email = '';
      this.model.password = '';
      this.errors.email.status = null;
      this.errors.password.status = null;
      this.errors.recaptcha_token.status = null;
    },
  }
}
</script>

<template>
  <div>
    <template v-if="registrationComplete">
      <div class="flex flex-col p-4 min-h-64 justify-center items-center">
        <p class="text-lg text-center font-bold" v-html="titles.afterReg"></p>
        <span class="text-center mt-4 text-sm text-gray-high font-normal leading-5">{{$t('Please check you E-Mail and confirm your account')}}</span>
        <UiButton @click="closeGlobalDrawer" size="md" color="primary" class="mt-4">{{$t('Close')}}</UiButton>
      </div>
    </template>
    <form @submit.prevent="registerSubmit" class="flex flex-col p-4" v-else>
      <div class="flex flex-col gap-1">
        <UiLabelInput :label="$t('label.yourEmail')" v-model="model.email" :error="errors.email" :required="true" type="email"></UiLabelInput>
        <UiLabelInput :label="$t('label.yourPassword')" v-model="model.password" :error="errors.password" :required="true" type="password"></UiLabelInput>

        <UiLabelCheckbox v-model="model.terms" :error="errors.terms" :checked="model.terms" color="primary">
          <span @click="() => {
            $router.push(localePath('terms'));
            closeGlobalDrawer();
          }" style="cursor: pointer;">{{$t('label.acceptTermsAndConditions')}}</span>
        </UiLabelCheckbox>
        <UiLabelCheckbox v-model="model.subscribe" :checked="model.subscribe" color="primary">{{$t('subscribe-newsletter')}}</UiLabelCheckbox>

        <UiButton type="submit" class="mt-4" size="md" color="primary"
                  :disabled="!model.email || !model.password">{{submitTexts.signUp}}</UiButton>
      </div>
    </form>
  </div>
</template>