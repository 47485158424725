<template>

  <div class="mb-5 flex flex-col w-full border-b border-black items-center">
<!--    <footer-link-page/>-->

    <div class="loading-overlay" id="checkout-loading" :style="{display: loading ? 'flex' : 'none'}">
      <div class="loading-spinner"></div>
    </div>

    <div class="p-6 flex flex-col w-full lg:min-w-[800px]">
      <h2 class="text-2xl font-bold text-font-line-color mb-4">Leave Feedback</h2>

      <div class="flex flex-col p-4 border border-black mb-4" v-if="successMessage">
        <span class="text-green-500 text-sm font-semibold">{{successMessage}}</span>
      </div>

      <div class="flex flex-col p-2 px-4 border border-black mb-4 rounded" v-if="errorMessage">
        <span class="text-red-500 text-sm font-semibold">{{errorMessage}}</span>
      </div>

      <form @submit.prevent="sendFeedback">
        <div class="flex flex-col mb-4">
          <label for="requestType" class="text-sm text-gray-high mb-1">Request Type</label>
          <UiSelect :items="[
              {
                label: 'Error for Sneakers123',
                value: 'Error for Sneakers123'
              },
              {
                label: 'General feedback',
                value: 'General feedback'
              },
              {
                label: 'Account issues',
                value: 'Account issues'
              },
              {
                label: 'Shipping issues',
                value: 'Shipping issues'
              },
              {
                label: 'Other',
                value: 'Other'
              }
          ]" :click="(selected) => {
            form.requestType = selected.label;
          }" :title="'Select request type'" color="white" size="md" />
        </div>
        <div class="flex flex-col mb-4">
          <UiLabelInput label="E-mail" v-model="form.email" :required="true" type="email"></UiLabelInput>
        </div>
        <div class="flex flex-col mb-4">
          <UiLabelInput label="Subject" v-model="form.subject" :required="true"></UiLabelInput>
        </div>
        <div class="flex flex-col mb-4">
          <label for="description" class="text-sm text-gray-high mb-1">Description</label>
          <textarea class="border border-black p-2 rounded-[2px] mt-1" id="description" rows="3" v-model="form.description"></textarea>
        </div>
        <div class="flex flex-col mb-4 justify-center items-center">
          <UiButton type="submit" color="black" size="lg" block>Submit</UiButton>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
    import FooterLinkPage from "../components/footerLinkPage";
    import UiSelect from "@/components/v2/ui/UiSelect.vue";
    import UiLabelInput from "@/components/v2/ui/UiLabelInput.vue";
    import UiButton from "@/components/v2/ui/UiButton.vue";

    export default {
        name: "contact",
        components: {UiButton, UiLabelInput, UiSelect, FooterLinkPage},
        middleware: 'loadMetaNonQuery',
        methods: {
            async sendFeedback() {
              this.errorMessage = '';
              this.successMessage = '';
              if(this.form.email === '' || this.form.subject === '' || this.form.description === '') {
                this.errorMessage = 'Please fill out all fields';
                return;
              }

              if(this.form.email.indexOf('@') === -1) {
                this.errorMessage = 'Please enter a valid email address';
                return;
              }

              this.loading = true;
              await this.$axios.post(`v3/feedback`, this.form);
              this.loading = false;

              this.successMessage = 'Thank you for your feedback!';
              this.form = {
                requestType: 'Error for Sneakers123',
                email: '',
                subject: '',
                description: ''
              };

            }
        },
        data(){
            return {
              loading: false,
              errorMessage: '',
              successMessage: '',
                form: {
                    requestType: 'Error for Sneakers123',
                    email: '',
                    subject: '',
                    description: ''
                }
            }
        }
    }
</script>
