<template>
    <div>
      <article class="px-5 py-5 flex flex-col w-full bg-white border-b border-black">
        <div class="line">
          <div>
            <div class="container">
              <h1 class="text-2xl text-black font-bold mb-2">Shipping and Return Policy</h1>
              <h3 class="text-lg text-black font-semibold mb-2">Ordering Process</h3>
              <h4 class="text-base text-black font-semibold mb-2">Shopping with Sneakers123:</h4>
              <p class="text-sm text-black font-normal mb-2">
                You can conveniently shop from featured retailers directly on Sneakers123. When you find a product with the "Check Out" button, you can place an order with the featured retailer through Sneakers123. The retailer will handle the processing, fulfillment, and delivery of your item.
              </p>
              <h4 class="text-base text-black font-semibold mb-2">Placing Orders:</h4>
              <p class="text-sm text-black font-normal mb-2">
                To make a purchase on Sneakers123, select your size if necessary, and click the "Check Out" button. While guest checkout is available, signing in is recommended for a quicker experience. Before finalizing your purchase, you can review your entire order. Once submitted, we will place your order with the supplying store, and you will receive a confirmation email from them.
              </p>
              <h3 class="text-base text-black font-semibold mb-2">Shipping Information</h3>
              <p class="text-sm text-black font-normal mb-2">
                Shipping options vary depending on the store and delivery destination. During checkout, an estimated shipping cost based on the best standard domestic options offered by the store will be provided. You will see the available shipping choices for your destination as you proceed through checkout.
              </p>
              <h3 class="text-base text-black font-semibold mb-2">Returns & Exchanges</h3>
              <p class="text-sm text-black font-normal mb-2">
                Return and exchange policies differ by store. We recommend checking the retailer's website for the most detailed information regarding their specific policies.
              </p>
              <h4 class="text-base text-black font-semibold mb-2">Important Note:</h4>
              <p class="text-sm text-black font-normal mb-2">
                While we strive to keep this information accurate, we cannot guarantee its completeness or accuracy. We are not liable for any errors, and this information is intended to be indicative only. For comprehensive details, please refer to the retailer’s own website, including any exceptions and restrictions.
              </p>
              <h3 class="text-base text-black font-semibold mb-2">Return Policy</h3>
              <p class="text-sm text-black font-normal mb-2">
                Return Window: <b>7 Days</b>
              </p>
              <p class="text-sm text-black font-normal mb-2">
                Due to our collaboration with retail partners globally, return policies are customized for each individual partner. These policies can vary depending on factors such as the specific item and the geographical locations of both the retailer and the delivery destination.
              </p>
              <h4 class="text-base text-black font-semibold mb-2">Finding Return Information:</h4>
              <ul>
                <li>Check the Sneakers123 product detail page before making a purchase for more information.</li>
                <li>Review the specific returns policy of our retail partners by visiting the customer service section on their website.</li>
              </ul>
              <p class="text-sm text-black font-normal mb-2">
                For inquiries about the status of your return or refund, contact our Customer Service team at support@sneakers123.com.
              </p>
              <h4 class="text-base text-black font-semibold mb-2">Contact Support</h4>
              <p class="text-sm text-black font-normal mb-2">
                For any support regarding your order, shipping, or returns, please contact our Customer Service team at support@sneakers123.com.
              </p>
              <h3 class="text-base text-black font-semibold mb-2">Payment Security</h3>
              <p class="text-sm text-black font-normal mb-2">
                Your security is our top priority. Sneakers123 uses the industry-standard Transport Layer Security (TLS) protocol to encrypt all data transmissions, ensuring that potentially sensitive information is protected.
              </p>
              <h4 class="text-base text-black font-semibold mb-2">Registered Members:</h4>
              <p class="text-sm text-black font-normal mb-2">
                If you make a purchase as a registered member, some of your payment details will be saved to facilitate faster checkout in the future. For added security, only the last 4 digits of your credit card and the expiration date will be displayed. You will always be prompted for your username and password during checkout to maintain security.
              </p>
              <h4 class="text-base text-black font-semibold mb-2">Using PayPal:</h4>
              <p class="text-sm text-black font-normal mb-2">
                If you use PayPal checkout, the handling of customer information by PayPal will be governed by PayPal’s terms and conditions, which you can review at PayPal User Agreement.
              </p>
              <h4 class="text-base text-black font-semibold mb-2">Password Changes:</h4>
              <p class="text-sm text-black font-normal mb-2">
                If you change your password, all stored payment details will be automatically deleted, and you will need to re-enter your payment information during your next purchase. We will notify you via email about this change.
              </p>
              <p class="text-sm text-black font-normal mb-2">
                For any further questions or assistance, please contact our support team at support@sneakers123.com.
              </p>
              <!-- button -->
              <a href="/en/contact">Contact Support</a>
            </div>
          </div>
        </div>
      </article>
    </div>
</template>

<script>
    import SimpleHeader from "../components/SimpleHeader";

    export default {
        name: "return-policy",
        components: {SimpleHeader},
    }
</script>

<style scoped>

</style>