<template>
    <footer id="footer">
        <div class="container-fluid">
            <div class="row primary-footer justify-content-center">
                <div class="col-md-3 d-flex justify-content-md-center align-content-md-center mb-2">
                    <ul class="list-unstyled">
                        <li class="first-child">
                            <span role="button" @click.prevent="toggleCollapse('aboutCollapse')" >
                                <strong>{{$t('About Sneakers123')}}</strong>
                            </span>
                        </li>
                        <li>
                            <b-collapse id="aboutCollapse" v-model="aboutCollapse">
                                <ul>
                                    <li><router-link :to="localePath('story')">{{$t('Story')}}</router-link></li>
                                    <li><router-link :to="localePath('contact')">{{$t('Contact')}}</router-link></li>
                                </ul>
                            </b-collapse>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 d-flex justify-content-md-center align-content-md-center mb-2">
                    <ul class="list-unstyled">
                        <li class="first-child">
                            <span @click.prevent="toggleCollapse('legalCollapse')">
                                <strong>{{$t('Legal')}}</strong>
                            </span>

                        </li>
                        <li>
                            <b-collapse id="legalCollapse" v-model="legalCollapse">
                                <ul>
                                    <li><router-link :to="localePath('data-protection')">{{$t('Data Protection')}}</router-link></li>
                                    <li><router-link :to="localePath('terms')">{{$t('Terms and Conditions')}}</router-link></li>
                                  <li v-if="$i18n.locale === 'en'"><router-link :to="localePath('return-policy')">{{$t('Return Policy')}}</router-link></li>
                                  <li><router-link :to="localePath('imprint')">{{$t('Imprint')}}</router-link></li>
                                </ul>
                            </b-collapse>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 d-flex justify-content-md-center align-content-md-center mb-2">
                    <ul class="list-unstyled">
                        <li class="first-child">
                            <span @click.prevent="toggleCollapse('otherCollapse')">
                                <strong>{{$t('Other')}}</strong>
                            </span>
                        </li>
                        <li>
                            <b-collapse id="otherCollapse" v-model="otherCollapse">
                                <ul>
                                    <li><a href="/blog" v-if="$i18n.locale === 'de'">{{$t('Blog')}}</a></li>
                                    <li><router-link :to="localePath('models')">{{$t('All Models')}}</router-link></li>
<!--                                    <li><router-link :to="localePath('black-friday')">{{$t('Black Friday')}}</router-link></li>-->
<!--                                    <li><a href="https://fussballschuhe123.com/" target="_blank">Fussballschuhe123.com</a></li>-->
                                    <!--<li><router-link :to="localePath('jobs')">{{$t('Jobs')}}</router-link></li>-->
                                </ul>
                            </b-collapse>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 d-flex justify-content-md-center align-content-md-center mb-2">
                    <ul class="list-unstyled">
                        <li class="first-child">
                            <span @click.prevent="toggleCollapse('mediaCollapse')">
                                <strong>{{$t('Social Media')}}</strong>
                            </span>
                        </li>
                        <li>
                            <b-collapse id="mediaCollapse" v-model="mediaCollapse">
                                <ul>
<!--                                    <li><a href="https://www.facebook.com/Sneakers123com" target=”_blank” rel="nofollow">Facebook</a></li>-->
                                    <li><a href="https://www.instagram.com/sneakers123com/" target=”_blank” rel="nofollow">Instagram</a></li>
                                    <!--<li><a href="https://twitter.com/">Twitter</a></li>-->
                                </ul>
                            </b-collapse>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="app-footer-wrap">
            <div class="app-footer">
                <div >
                    <div class="footer-location-modal">
                        <a class="bg-transparent text-black text-capitalize d-flex pt-2 px-4 " :style="{ cursor: 'pointer' }" @click="$bvModal.show('location-modal')">
                            <p class="mb-2 font-weight-bold">{{userRegion}}</p>
                            <p class="mb-2 font-weight-bold"> &nbsp;&nbsp;|&nbsp;&nbsp; </p>
                            <p class="mb-2 font-weight-bold">{{language}}</p>
                        </a>
                    </div>
                </div>
                <div class="d-flex align-items-center download-buttons">
                    <span class="px-4">Download Sneakers123 APP</span>
                    <div>
                        <a href="https://apps.apple.com/us/app/sneakers123-find-sneakers/id1015070329" target="_blank">
                            <img src="~/assets/images/logo-appstore.png" alt="Sneakers123 iOS App" width="120" height="36">
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.sneaker123&hl=de_AT&gl=US" target="_blank">
                            <img src="~/assets/images/playstore-logo.png" alt="Sneakers123 iOS App" width="120" height="36">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid secondary-footer">
            <div class="row align-items-center footer-text">
                <span class="col-12">© 2015 - 2024 {{appName}} • {{$t('All rights reserved')}}<!-- • {{$t('Made in Vienna')}}--></span>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
    export default {
        name: "footerComponent",
        computed: {
            ...mapState(["location","countries"]),
            availableLocales () {
                return this.$i18n.locales
            },
            currentLang(){
              return this.$i18n.locale
            },
            currentLangName(){
                return this.availableLocales.find((l)=>{ return l.code === this.currentLang}).name
            },
            userRegion () {
                if (Object.keys(this.location).length > 0) {
                    return this.location.names[this.currentLang];   
                }
                return "N/A"
            }
        },
        data() {
            return {
                appName: process.env.appName,
                isMobile: false,
                mediaCollapse: true,
                otherCollapse: true,
                legalCollapse: true,
                aboutCollapse: true,
                language:"",
              collapseAllOpen: false
            }
        },
        mounted() {
            this.language = this.currentLangName

          this.collapseAllOpen = !!this.$route.path.startsWith('/en/product')

            const checkStates = () => {
                this.isMobile = window.innerWidth < 767;
                this.mediaCollapse = this.otherCollapse = this.legalCollapse = this.aboutCollapse = !this.isMobile || this.collapseAllOpen;
            }
            // this.isMobile = window.innerWidth < 767;
            checkStates();
            window.addEventListener('resize', () => {
                checkStates();
            })
        },
        methods: {
            toggleCollapse(id) {
                if (this.isMobile && !this.collapseAllOpen) {
                    let init = this[id];
                    this.mediaCollapse = this.otherCollapse = this.legalCollapse = this.aboutCollapse = false;
                    this[id] = !init
                }
            },
        }
    }
</script>
