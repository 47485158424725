<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {debounce} from "lodash-core";

export default {
  name: "search-drawer",
  computed: {
    ...mapState(["totalSneakersCount"]),
    ...mapState(['previousSearches']),
  },
  created() {
    this.$root.$on('global-drawer-close', this.closeDrawer);
  },
  data() {
    return {
      searchInput: "",
      searchFocus: false,
      result: [],
      searchString: this.$store.state.searchString,
      initFocus: false,
      searchInputRef: "searchInputRef",
      initMounted: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.initFocus) return;
      this.initFocus = true;
      this.$refs.searchInputRef.focus();
    });
  },
  methods: {
    ...mapActions(['setSearchString', 'closeGlobalDrawer']),
    ...mapMutations(['deletePreviousSearch', 'clearPreviousSearches']),

    searchClear()
    {
      this.searchInput = '';
      this.showResult = false;
      this.searchFocus = false;
      //search input focus out
      this.$nextTick(() => {
        if(this.$refs.searchInputRef){
          this.$refs.searchInputRef.blur();
        }
      });
    },
    onChange: debounce(function (event) {
      this.searchInput = event.target.value;
      this.$axios.get(process.env.apiUrl + 'search-suggestion?q=' + this.searchInput).then(({data}) => {
        this.result = data;
        this.showResult = data.length > 0;
      }).catch((e) => {
        console.log('Suggestion error: ' + e)
      });
    }, 150),
    clearSearchHistory()
    {
      this.searchClear();
      this.clearPreviousSearches();
    },
    clearSearchHistoryItem(item)
    {
      this.deletePreviousSearch(item);
    },
    search(search)
    {
      this.searchClear()
      this.searchString = search;
      document.getElementsByTagName("BODY")[0].scrollIntoView({behavior: "smooth"});
      this.setSearchString(search);
      this.closeDrawer();
      this.navigateSneaker();
    },

    closeDrawer() {
      this.closeGlobalDrawer();
      this.searchClear();
      this.result = [];
      this.initFocus = false;
    },
  }
}
</script>

<template>
  <div class="flex flex-col bg-white w-full h-full">
    <div class="flex flex-col relative">
      <input type="text" :placeholder="$t('placeholders.searchbar', {total: $store.state.totalSneakersCount})"
             v-model="searchInput"
             @keydown.enter.prevent="search(searchInput)"
             @focus="searchFocus = true"
             @keyup="onChange"
             :ref="searchInputRef"
             class="w-full h-16 px-4 text-base text-gray-900 pl-16"
      />
      <div v-show="searchInput" @click="searchClear" class="absolute right-0 top-0 h-16 w-16 flex items-center justify-center cursor-pointer">
        <img src="~/assets/images/ui/icons/input-clear.png" alt="close" class="h-5" />
      </div>

      <div class="absolute left-0 top-0 h-16 w-16 flex items-center justify-center cursor-pointer lg:hidden" @click="closeDrawer">
        <img src="~/assets/images/ui/icons/back-arrow.png" alt="close" class="h-6" />
      </div>

      <div class="absolute left-0 top-0 h-16 w-16 items-center justify-center cursor-pointer lg:flex hidden">
        <img src="~/assets/images/ui/icons/search.png" alt="close" class="h-6" />
      </div>
    </div>

    <div class="overflow-y-auto h-[calc(100vh-56px)] border-t border-black pb-32 lg:pb-0">
      <ul class="p-0 m-0 list-none">
        <li class="flex items-center p-4 border-b border-gray-200" v-for="item in result" :key="item" @click="search(item)">
          <div class="flex items-center">
            <img src="~/assets/icons/ui/search-result.svg" alt="search-result" class="w-4.5 h-4.5" />
          </div>
          <div class="flex-1 ml-3 text-base text-gray-900">{{item}}</div>
          <div class="flex items-center">
            <img src="~/assets/icons/ui/search-arrow.svg" alt="search-arrow" class="w-2.5 h-2.5" />
          </div>
        </li>
      </ul>

      <div v-show="previousSearches.length > 0">
        <div class="flex items-center p-4 text-md text-black font-bold">
          {{$t('previous-searches')}}
          <div class="ml-auto cursor-pointer text-gray-high font-normal" @click="clearSearchHistory">
            {{$t('Clear All')}}
          </div>
        </div>
        <ul class="p-0 m-0 list-none">
          <li class="flex items-center p-4 border-b border-gray-200" v-for="item in previousSearches" :key="item">
            <div class="flex items-center">
              <img src="~/assets/icons/ui/search-history.svg" alt="search-history" class="w-4.5 h-4.5" />
            </div>
            <div class="flex-1 ml-3 text-base text-gray-900 cursor-pointer" @click="search(item)">{{item}}</div>
            <div class="flex items-center cursor-pointer" @click="clearSearchHistoryItem(item)">
              <img src="~/assets/icons/ui/search-history-delete.svg" alt="search-history-delete" class="w-2.5 h-2.5" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>