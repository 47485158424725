<script>
export default {
  name: "UiCheckbox",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked() {
      return this.checked;
    }
  }
}
//'black': '#131416',
// 'gray-low': '#e8ebed',
//     'gray-medium': '#c9cdd2',
//     'gray-high': '#6c7781',
//     'danger': '#db3d45',
//     'primary': '#23d1d8',
</script>

<template>
  <label>
    <input type="checkbox" class="hidden" :checked="checked">
    <span class="text-black text-base">
      <slot></slot>
    </span>
  </label>
</template>

<style scoped>

</style>