<template>
    <div>
    </div>
</template>

<script>

export default {
  name      : "my-notifications",
  async asyncData({redirect, app}){
    redirect(app.localePath({name:'index'}));
  }
}
</script>

<style scoped>

</style>