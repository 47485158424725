<template>
  <div class="fixed top-0 left-0 w-full h-full bg-black z-50 flex items-center justify-center">
    <div class="flex flex-col w-full justify-center items-center">
      <img src="~/assets/images/ui/go-logo.png" alt="Sneakers123.com loading..." class="bounce2 mb-4" style="width: 200px;">
      <h2 class="text-white text-sm mb-1">{{$t('We are redirecting you to our partner')}}</h2>
      <h3  class="text-white text-sm mb-1" v-show="shopName">{{$t('text.movingToPartnerStore')}} : <span @click="() => forceRedirect()">{{shopName}}</span></h3>

      <div class="flex items-center justify-center mt-4 mb-4">
        <img src="~/assets/images/ui/icons/circle-spinner.png" alt="loading" class="w-8 h-8 animate-spin">
      </div>

      <h5 class="text-white text-sm mb-1">Taking to long? <a :href="url" class="text-primary font-semibold">Move to store now</a></h5>
    </div>
  </div>
</template>

<script>
    import atob from 'atob';
    import axios from "axios";
    export default {
        name    : 'RedirectingToPartner',
        async asyncData({$auth, req, query, app, store})
        {

          let isDebug = false;
          try{

            const cookie = req.headers.cookie;

            const cookies = cookie.split(';').reduce((acc, cookie) => {
              const [key, value] = cookie.split('=');
              acc[key.trim()] = value;
              return acc;
            }, {});

            const clientIp = req.headers['cf-connecting-ip'] || req.headers['x-real-ip'] || req.headers['x-forwarded-for'] || null;
            const clientCountryCode = req.headers['cf-ipcountry'] ? req.headers['cf-ipcountry'].toUpperCase() : null;
            const referer = req.headers.referer || null;

            if(query.debug === 'true'){
              isDebug = true;
            }
            const url = process.env.newApiUrl + '/v1/clicks/actions';
            const {data} = await axios.post(url, {
              gacid: cookies._ga || null,
              gclid: cookies.SNK_GCLID || null,
              snkProductId: query.productId || null,
              snkShopId: query.shopId || null,
              snkUserId: $auth && $auth.user ? $auth.user.id : null,
              productId: query.catalogProductId || null,
              shopId: query.catalogShopId || null,
              userId: query.catalogUserId || null,
              url: query.url ? atob(query.url) : null,
            }, {
              headers: {
                'Content-Type': 'application/json',
                'X-User-Agent': req.headers['user-agent'] || null,
                'X-Country-Code':(store.state.location.code || 'US').toUpperCase(),
                'X-Currency-Code': (store.state.currency || 'USD').toUpperCase(),
                'X-Language-Code': (app.i18n.locale || 'en').toLowerCase(),
                'X-Client-Uuid': store.state.clientUid || null,
                ...(referer ? {'X-Referer': referer} : {}),
                ...(clientIp ? {'X-Client-Ip': clientIp} : {}),
                ...(clientCountryCode ? {'X-Client-Country-Code': clientCountryCode} : {}),
              }
            });

            if(data && data.data && data.data.redirectUrl){
              return {
                clickAction: data.data,
                url: data.data.redirectUrl,
                urlWithClickActionId: data.data.urlWithClickActionId,
                envMode: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
                serverError: false,
                serverErrorMessage: null,
                isDebug: isDebug
              }
            }

            return {
              clickAction: null,
              url: query.url ? atob(query.url) : null,
              urlWithClickActionId: false,
              envMode: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
              serverError: true,
              serverErrorMessage: 'No redirect URL found in response data.',
              isDebug: isDebug
            }
          } catch (e) {
            return {
              clickAction: null,
              url: query.url ? atob(query.url) : null,
              urlWithClickActionId: false,
              envMode: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
              serverError: true,
              serverErrorMessage: 'Error while fetching redirect URL.',
              isDebug: isDebug
            }
          }
        },

      data() {
        return {
          wctUrl: null,
          affiliateUrl: null,
        }
      },
      head()
      {
        return {
          title: 'Redirecting to our partner',
          meta : [
            {hid: 'robots', name: 'robots', content: 'noindex,follow'},
          ],
          script: [
            {
              hid: 'forceRedirect',
              innerHTML: `if(window !== undefined){
                setTimeout(() => {
                  console.log('Force Redirecting to:', '${this.redirectUrl || this.url}');
                  window.location.replace('${this.redirectUrl || this.url}');
                }, 5000)
              };`

            }
          ],
          __dangerouslyDisableSanitizersByTagID: {
            forceRedirect: ['innerHTML'],
          },
        }
      },
        mounted()
        {
          if(this.isDebug){
            console.log({
              clickAction: this.clickAction || 'No click action found.',
              url: this.url || 'No URL found in query params.',
              envMode: this.envMode || 'No env mode found.',
              serverError: this.serverError || 'No server error found.',
              serverErrorMessage: this.serverErrorMessage || 'No server error message found.'
            });
          }

          if(this.url){
            if(window !== undefined){
              // 8초간 아무 이벤트도 없을 경우 자동으로 이동
              setTimeout(() => {
                window.location.replace(this.redirectUrl || this.url || '/');
              }, 8000);
            }

            setTimeout(() => {
              this.redirect(this.url);
            }, 500);

          } else {
            this.$router.go(-1)
          }
        },
        computed: {
            redirectUrl() {
                if(this.wctUrl){
                    return this.wctUrl;
                }

                return this.url;
            },
            shopName() {
              if(this.clickAction && this.clickAction.product && this.clickAction.product.shop && this.clickAction.product.shop.name){
                return this.clickAction.product.shop.name;
              }


              if(this.clickAction && this.clickAction.snkProduct && this.clickAction.snkProduct.shop && this.clickAction.snkProduct.shop.name){
                return this.clickAction.snkProduct.shop.name;
              }

              if(this.clickAction && this.clickAction.shop && this.clickAction.shop.name){
                return this.clickAction.shop.name;
              }

              if(this.clickAction && this.clickAction.snkShop && this.clickAction.snkShop.name){
                return this.clickAction.snkShop.name;
              }

              return null;
            }
        },
        methods: {
          forceRedirect(){
            window.location.replace(this.redirectUrl);
          },
            getTrackingURL(affiliateUrl) {
                if (typeof _wct !== "undefined" && _wct.getTrackingURL !== undefined) {
                    console.log('wecantrack found!', _wct);
                    if(!this.urlWithClickActionId){
                      _wct.auto_tagging = true;
                      _wct.click_id_placeholder = this.clickAction ? this.clickAction.id : '';
                    }
                    let trackingLink = _wct.getTrackingURL(affiliateUrl);

                    if (trackingLink){
                      this.wctUrl = trackingLink;
                      return trackingLink;
                    }
                }
                console.log('wecantrack error!');
                return affiliateUrl;
            },

                redirect(to) {

                   let tTo = this.getTrackingURL(to);

                   // console.log(tTo);
                  if(this.isDebug) {
                    console.log('Redirecting to:', tTo);
                    return;
                  }
                  window.location.replace(tTo);
                }
        },

    }
</script>

<style scoped>

.bounce2 {
  animation: bounce2 4s ease infinite;
}
@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-15px);}
  60% {transform: translateY(-5px);}
}
</style>