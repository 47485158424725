<script>
export default {
  name: "u-dialog-footer"
}
</script>

<template>
  <div class="u-dialog-footer">
    <slot></slot>
  </div>

</template>

<style scoped lang="scss">
.u-dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8px;
}
</style>