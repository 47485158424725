<script>
import UContainer from "~/components/ui/container.vue";
import {mapGetters} from "vuex";

export default {
  name: "release-item-row",
  props: {
    release: { type: Object, default: {} },
    nameWidth: { type: String, default: '280px' },
    handleClick: { type: Function, default: (release) => {} }
  },
  computed: {
    ...mapGetters(['currency']),
  },
  methods: {
    clickRelease(release) {
      this.handleClick(release);
      this.$router.push(this.localePath({name: 'product', params : { product : release.slug}}))
    },
    getPriceRange(element)
    {
      return element['priceRange' + this._.capitalize(this.currency)];
    },
    thumb(release, size = 'small')
    {
      let thumb = release.thumbnail_url || release.image;
      try {
        return this._.isEmpty(thumb)
            ? require(`~/assets/img/coming-soon-img/${release.brand.toLowerCase().replace(' ', '-')}-coming-soon-${size}-min.jpg`)
            : thumb
      } catch (e) {
        return require('~/assets/img/placeholder_thumb.png');
      }
    },
    replaceByDefault(e) {
      e.target.src = require('~/assets/img/placeholder_thumb.png');
    }
  },
  components: {UContainer},
}

</script>

<template>
  <div class="release-related-item-wrap" @click="clickRelease(release)">
    <div class="release-related-item">
      <div class="release-related-item-image">
        <client-only>
          <img :src="thumb(release)" alt="release-related-item-image" @error="replaceByDefault" />
        </client-only>
      </div>
      <div class="release-info-wrap">
        <div class="release-related-item-brand">
          {{ release.brand }}
        </div>
        <div class="release-related-item-name" :style="{maxWidth: nameWidth}">
          {{ release.name }}
        </div>
        <div class="release-related-item-sku" v-if="release.sku">
          {{ release.sku }}
        </div>
        <div class="release-related-item-price-only-price">
          <div class="release-related-item-price">
            {{ getPriceRange(release) ? getPriceRange(release) : $t('sold-out') }}
          </div>
        </div>
        <!--        <div class="release-related-item-price-sale-price" v-if="release.sale_price_exchange !== release.price_exchange && release.sale_price_exchange > 0">-->
        <!--          <div class="release-related-item-price">-->
        <!--            {{currencySymbol(release.currency)}}{{priceFormat(release.sale_price_exchange)}}-->
        <!--          </div>-->
        <!--          <div class="release-related-item-price-discount">-->
        <!--            {{currencySymbol(release.currency)}}{{priceFormat(release.price_exchange)}}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="release-related-item-price-only-price" v-else>-->
        <!--          <div class="release-related-item-price">-->
        <!--            {{currencySymbol(release.currency)}}{{priceFormat(release.price_exchange)}}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>

.release-related-item-wrap{
  background: #fff;
  border: 1.5px solid #dfe6f0;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 10px;
  .release-item-link {

    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }

    &:visited {
      text-decoration: none;
    }

    &:link {
      text-decoration: none;
    }
  }
  .release-related-item {
    display: flex;
    position: relative;
    flex-direction: row;

    &:nth-child(4n) {
      margin-right: 0;
    }

    .release-info-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .release-related-item-brand {
      font-size: 14px;
      font-weight: 800;
      color: #191919;
      padding: 0 16px;
    }

    .release-related-item-price-sale-price {
      display: flex;
      flex-direction: row;
      padding: 0 16px;
      .release-related-item-price-discount {
        font-size: 12px;
        font-weight: normal;
        line-height: 22px;
        text-decoration: line-through;
        color: #191919;
      }

      .release-related-item-price {
        font-size: 14px;
        font-weight: 800;
        margin-right: 3px;
        color: #db3d46;
      }
    }
    .release-related-item-price-only-price {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      .release-related-item-price {
        font-size: 14px;
        font-weight: 800;
        color: #191919;
      }


    }

    .release-related-item-name {
      font-size: 14px;
      font-weight: 800;
      color: #191919;
      padding: 0 16px;

      /** 말줄임 **/
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      min-height: 20px;
      max-width: 280px;
    }

    .release-related-item-sku {
      font-size: 12px;
      font-weight: normal;
      color: #8999b1;
      padding: 0 16px;
    }
    .release-related-item-discount-rate {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 1;
      display: flex;
      padding: 4px 8px;
      background: #ffceba;
      color: #db3d46;
      border-radius: 4px;
      font-size: 14px;
    }
    .release-related-item-buttons {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      padding: 16px;
      .release-button-alert {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        border: 1.5px solid #e0ecf5;
        margin-right: 6px;
        cursor: pointer;
      }
      .release-button-like {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        border: 1.5px solid #e0ecf5;
        cursor: pointer;
      }
    }

    .release-related-item-image {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        border-radius: 12px;
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
    }
  }
}

</style>