<template>
  <div class="bg-black text-white p-8">
    <link-parser :content="content"/>
  </div>
</template>

<script>
  import LinkParser from "@/components/linkParser.vue";

  export default {
    name: "home-description-section",
    components: {LinkParser},
    props: {
      content: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>