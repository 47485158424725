<script>
import {mapGetters, mapState} from "vuex";
import ReleaseAlert from "./release-alert.vue";
import ReleaseFavorite from "./release-favorite.vue";

export default {
  name: "release-thumbnail",
  components: {ReleaseAlert, ReleaseFavorite},
  props: {
    release: { type: Object, default: {} },
  },
  data() {
    return {
      hoverRelatedIndex: null,
      showRelated: false,
      imageWidth: 0,
      related: [],
    }
  },
  computed: {
    ...mapState(['sizeFormat']),
    query()
    {
      let query = {};
      if (this.$route.query.sizes)
        query.sizes = this.$route.query.sizes;
      return query;
    },
    hasManyAvailableSizes()
    {
      return (
          (this.selectedRelated.sizes_eu && this.selectedRelated.sizes_eu.length >= 6)
          || (this.selectedRelated.sizes_us && this.selectedRelated.sizes_us.length >= 6)
          || (this.selectedRelated.sizes_uk && this.selectedRelated.sizes_uk.length >= 6)
      ) || (
          (this.selectedRelated.sizes_eu && this.selectedRelated.sizes_eu.length === 0)
          || (this.selectedRelated.sizes_us && this.selectedRelated.sizes_us.length === 0)
          || (this.selectedRelated.sizes_uk && this.selectedRelated.sizes_uk.length === 0)
      )
    },
    getAvailableSizes()
    {
      let roundedSizes = [];
      let sizes = this.selectedRelated['sizes_' + this.sizeFormat];
      for (let size in sizes) {
        let roundedSize = Math.floor(sizes[size]);
        if (!roundedSizes.includes(roundedSize))
          roundedSizes.push(roundedSize);
      }
      return roundedSizes;
    },

    infoHeight(){
      const baseHeight = 138;
      return baseHeight;
      // return this.showRelated
      //     ? this.release.related && this.release.related.length > 0
      //         ? baseHeight + 100
      //         : baseHeight
      //     : baseHeight;
    },
    releaseLink(){
      return this.localePath({name: 'product', params : { product : this.release.slug}})
    },
    selectedRelated(){
      return this.hoverRelatedIndex !== null && this.related.length > 0 && this.related.length >= this.hoverRelatedIndex
          ? this.related[this.hoverRelatedIndex]
          : this.release;
    }
  },
  mounted() {

    if(this.$device.isDesktop) {
      let observer = new IntersectionObserver(this.handleIntersection);
      this.$nextTick(() => {
        observer.observe(this.$refs.releaseItemWrap);
      })
    }

    if(typeof window !== 'undefined'){
      this.imageWidth = this.$refs.releaseItemWrap.offsetWidth;
    }
  },
  methods: {

    handleIntersection(entries, observer) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          this.loadRelated();
          observer.unobserve(entry.target);
        }
      });
    },
    loadRelated(){
      this.$axios.get('releases/related/' + this.release.slug,{ progress: false })
          .then(({data}) => {
            this.related = data;
          })
    },
    goToRelease(){

    }
  },
}
</script>

<template>
  <section ref="releaseItemWrap"
           @mouseover="showRelated = true"
           @mouseleave="showRelated = false"
           class="flex flex-col w-full">
    <nuxt-link :to="localePath(releaseLink)" class="flex flex-col relative aspect-[2/3] justify-between w-full">
    <span class="absolute top-4 left-4 z-10 text-gray-high text-xs"
    >{{selectedRelated.sku}}</span>
      <div class="absolute top-4 right-4 z-10 flex space-x-1">
        <release-alert :sneaker="selectedRelated" v-show="!selectedRelated.sold_out"></release-alert>
        <release-favorite :sneaker="selectedRelated"/>
      </div>
      <div class="flex flex-col w-full aspect-square items-center justify-center flex-1" :class="{'show-related': showRelated, 'no-related': !related}">
        <client-only>
          <img class="object-contain object-center" :data-src="thumb(selectedRelated)" v-lazy-load :alt="selectedRelated.brand + ' ' + selectedRelated.name + ' ' + selectedRelated.sku"
               :style="{width: imageWidth + 'px', height: imageWidth + 'px'}" />
          <img class="object-contain object-center" src="~/assets/img/sneakers123-thumb.jpg" alt="sneaker thumbnail"  slot="placeholder"
               :style="{width: imageWidth + 'px', height: imageWidth + 'px'}" />
        </client-only>
      </div>
      <div class="flex flex-row w-full p-4 bg-white justify-between items-center  relative">
        <div class="flex flex-col space-y-1 w-full">
          <span class="text-black text-xs">{{selectedRelated.brand}}</span>
          <span class="text-black text-xs text-ellipsis text-nowrap max-w-[150px] lg:max-w-[200px] overflow-hidden">{{selectedRelated.name}}</span>
          <span class="text-black text-xs">
          <span class="text-danger mr-1 font-semibold" v-if="selectedRelated.sale &&  selectedRelated.percentage > 0">{{selectedRelated.percentage}}%</span>
          <span class="text-xs">{{releasePrice(selectedRelated)}}</span>
        </span>
        </div>

        <div class="lg:hidden flex flex-col space-y-1 h-full flex-1 justify-end items-end absolute bottom-4 right-4">
          <span class="text-black text-xs">{{selectedRelated.sold_out ? $t('sold-out') : selectedRelated.shop_count + ' ' + $t('shops')}}</span>
        </div>

        <div class="hidden lg:flex flex-col space-y-1 h-full flex-1 justify-end items-end  absolute bottom-4 right-4">
          <span class="text-black text-xs">{{selectedRelated.sold_out ? $t('sold-out') : selectedRelated.shop_count + ' ' + $t('shops')}}</span>
        </div>
      </div>
      <client-only>
        <div class="flex flex-col bg-black transition-all"
             :class="{'h-24 p-4': showRelated, 'h-0 p-0': !related}" v-if="$device.isDesktop">
          <div class="overflow-hidden text-ellipsis text-nowrap text-xs text-white transition-all" :class="{'h-0 p-0': !showRelated, 'text-center mb-1': showRelated}">
            <span v-if="hasManyAvailableSizes">{{ $t('several-sizes-available') }}</span>
            <span v-else>Available Size : {{ getAvailableSizes.join(', ') }}</span>
          </div>
          <div class="h-0 flex-nowrap flex flex-row justify-center transition-all gap-1" :class="{'h-12': showRelated}">
            <div v-for="(relatedRelease, i) in related" @mouseover="hoverRelatedIndex = i"  @mouseleave="hoverRelatedIndex = null">
              <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : relatedRelease.slug}, query: query })">
                <img v-lazy-load :data-src="thumb(relatedRelease)" class="object-contain object-center rounded-sm transition-all" :class="{'w-12 h-12': showRelated, 'w-0 h-0': !showRelated}">
              </nuxt-link>
            </div>
          </div>
        </div>
      </client-only>
    </nuxt-link>
  </section>

</template>

<style scoped>

</style>