<script>
import {mapActions, mapMutations, mapState} from "vuex";
import UiCheckboxGroup from "../../../ui/UiCheckboxGroup.vue";

export default {
  name: "release-list-filter-drawer",
  components: {UiCheckboxGroup},
  computed: {
    ...mapState(["showReleaseListFilter"]),
  },
  methods: {
    ...mapActions(["toggleReleaseListFilter"]),
  }
}
</script>

<template>
  <div :class="{
    'fixed top-0 lg:top-16 left-0 w-full h-full z-40 flex justify-end': true,
    'hidden': !showReleaseListFilter
  }">
    <div class="absolute left-0 top-0 w-full h-full bg-black opacity-50 z-10" @click="toggleReleaseListFilter"></div>
    <div :class="{
    'flex flex-col bg-white z-20 transition-all duration-300 ease-in-out h-full': true,
    'w-full lg:w-1/3': showReleaseListFilter,
    'w-0': !showReleaseListFilter
    }">
      <div class="flex flex-row items-center justify-between h-14 border-b border-black px-4 relative w-full">
        <div class="absolute left-4 cursor-pointer flex items-center h-8"
            v-if="$parent.hasSelectedFilters"  v-on:click="$parent.resetLocalQueries">{{$t('reset-all')}}</div>
        <div class="text-lg font-bold text-black flex items-center justify-center flex-1 w-full"
        >{{$t('Filter')}}</div>
        <div class="absolute right-4 cursor-pointer flex items-center h-8">
          <img src="~/assets/images/ui/icons/close.png" alt="close" class="h-6 cursor-pointer" @click="toggleReleaseListFilter" />
        </div>
      </div>
      <div class="overflow-y-auto h-[calc(100vh-52px)] lg:h-[calc(100vh-112px)] pb-32 lg:pb-0">
        <div class="p-4 pr-0 border-b border-black">
          <div class="text-lg font-bold text-black pb-4">{{$t('Size')}}</div>
          <div>
            <ul class="list-none p-0 m-0 flex flex-wrap">
              <li v-for="size in $parent.actualSizes" :key="size" class="pr-4 pb-4 basis-1/4">
                <label :class="{
                  'flex items-center border border-black p-2 cursor-pointer': true,
                  'bg-primary text-white border-primary': $parent.querySizes.includes($parent.sizeFormat + size),
                }">
                  <input type="checkbox" name="group3[]" :value="$parent.sizeFormat + size" v-model="$parent.querySizes" class="hidden" />
                  <span class="text-base text-center w-full">{{size}}</span>
                </label>
              </li>
            </ul>
            <span class="text-sm cursor-pointer underline" @click="$parent.toggleIntermediateSizes">{{ $t('toggle-intermediate-sizes')}}</span>
          </div>
        </div>
        <div class="p-4 border-b border-black">
          <div class="text-lg font-bold text-black pb-4">{{$t('Brand')}}</div>
          <div>
            <ul class="list-none p-0 m-0 flex flex-col">
              <li v-for="brand in $parent.brandList" :key="brand.slug" class="w-full mb-2">
                <label class="flex items-center cursor-pointer">
                  <input type="checkbox" class="hidden" :value="brand.slug" v-model="$parent.queryBrands" name="group4[]">
                  <!-- checkbox -->
                  <span class="w-5 h-5 border border-black rounded-sm mr-2 flex items-center justify-center" :class="{
                    'bg-primary border-primary': $parent.queryBrands.includes(brand.slug),
                  }">
                    <svg class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </span>
                  <span class="text-black text-base">
                    {{brand.brand}}
                  </span>
                </label>
              </li>
            </ul>
            <span class="text-sm cursor-pointer underline" @click="$parent.toggleShowBrands">{{ $t('text.showAndHide') }}</span>
          </div>
        </div>
        <div class="p-4 border-b border-black">
          <div class="text-lg font-bold text-black pb-4">{{$t('Gender')}}</div>
          <div>
            <ul class="list-none p-0 m-0 flex flex-col">
              <li class="w-full mb-2">
                <label class="flex items-center cursor-pointer">
                  <input type="checkbox" class="hidden" value="men" v-model="$parent.queryGender" name="group2[]">
                  <!-- checkbox -->
                  <span class="w-5 h-5 border border-black rounded-sm mr-2 flex items-center justify-center" :class="{
                    'bg-primary border-primary': $parent.queryGender.includes('men'),
                  }">
                    <svg class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </span>
                  <span class="text-black text-base">
                    {{$t('filters.men') | upper}}
                  </span>
                </label>
              </li>

              <li class="w-full mb-2">
                <label class="flex items-center cursor-pointer">
                  <input type="checkbox" class="hidden" value="women" v-model="$parent.queryGender" name="group2[]">
                  <!-- checkbox -->
                  <span class="w-5 h-5 border border-black rounded-sm mr-2 flex items-center justify-center" :class="{
                    'bg-primary border-primary': $parent.queryGender.includes('women'),
                  }">
                    <svg class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </span>
                  <span class="text-black text-base">
                    {{$t('filters.women') | upper}}
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="p-4 pr-0 border-b border-black">
          <div class="text-lg font-bold text-black pb-4">{{$t('Color')}}</div>
          <div>
            <ul class="list-none p-0 m-0 flex flex-wrap gap-2">
              <!--"black", "blue", "white", "grey", "lyellow", "red", "orange", "yellow", "green", "marine",
        "dark-grey", "light-grey", "pink", "greenyellow", "rainbow"-->
              <li v-for="color in $parent.colors" class="w-1/6">
                <label  :class="{
                    'flex flex-col w-full aspect-square items-center justify-center flex-1 border-2 p-2 cursor-pointer rounded-full shadow-filterColor': true,
                    'bg-[#000]': color === 'black',
                    'bg-[#00f]': color === 'blue',
                    'bg-[#fff]': color === 'white',
                    'bg-[#808080]': color === 'grey',
                    'bg-[#F5F5E1]': color === 'lyellow',
                    'bg-[#f00]': color === 'red',
                    'bg-[#ffa500]': color === 'orange',
                    'bg-[#ff0]': color === 'yellow',
                    'bg-[#008000]': color === 'green',
                    'bg-[#0380ad]': color === 'marine',
                    'bg-[#6c7781]': color === 'dark-grey',
                    'bg-[#f7f7f7]': color === 'light-grey',
                    'bg-[#ffc0cb]': color === 'pink',
                    'bg-[#adff2f]': color === 'greenyellow',
                    'bg-gradient-to-r from-red-500 via-orange-500 to-violet-500 rotate-gradient transform -rotate-45': color === 'rainbow',

                    'border-transparent': !$parent.queryColor.includes(color) && color !== 'light-grey' && color !== 'white',
                    'border-gray-light': color === 'light-grey',
                    'border-gray-medium': color === 'white',
                    'border border-primary': $parent.queryColor.includes(color),
                  }">
                  <input type="checkbox" name="group5[]" :value="color" v-model="$parent.queryColor" class="hidden" />
                  <!-- Check -->
                  <span>
                    <svg :class="{
                      'hidden': !$parent.queryColor.includes(color),
                      'w-8 h-8': true,
                      'transform -rotate-[-45deg]': color === 'rainbow',
                      'text-white': !['white', 'light-grey', 'lyellow', 'yellow'].includes(color),
                      'text-black': ['white', 'light-grey', 'lyellow', 'yellow'].includes(color),
                    }" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="p-4 pr-0 border-b border-black">
          <div class="text-lg font-bold text-black pb-4">{{$t('Sale')}}</div>
          <div>
            <ul class="list-none p-0 m-0 flex flex-wrap">
<!--              <label class="container-box" @click="resetDiscount(-1)">{{$t('filters.sale')}}%-->
<!--                <input type="checkbox" name="group2[]" value="sale" v-model="queryFilters" >-->
<!--                <span class="checkmark"></span>-->
<!--              </label>-->
<!--              <template v-for="amount in [20,50,70]" >-->
<!--                <label class="container-box" @click="resetDiscount(amount)">{{$t('discount-from', {amount: amount})}}-->
<!--                  <input type="radio" name="group2[]" :value="amount" v-model="queryDiscountFrom" >-->
<!--                  <span class="checkmark"></span>-->
<!--                </label>-->
<!--              </template>-->
              <li class="w-full mb-2" @click="$parent.resetDiscount(-1)">
                <label class="flex items-center cursor-pointer">
                  <input type="checkbox" class="hidden" value="sale" v-model="$parent.queryFilters" name="group6[]">
                  <!-- checkbox -->
                  <span class="w-5 h-5 border border-black rounded-sm mr-2 flex items-center justify-center" :class="{
                    'bg-primary border-primary': $store.state.queries.filters.includes('sale'),
                  }">
                    <svg class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </span>
                  <span class="text-black text-base">
                    {{$t('filters.sale')}}
                  </span>
                </label>
              </li>

              <li class="w-full mb-2" v-for="amount in [20,50,70]" :key="amount" @click="$parent.resetDiscount(amount)">
                <label class="flex items-center cursor-pointer">
                  <input type="checkbox" class="hidden" :value="amount" v-model="$parent.queryDiscountFrom" name="group6[]">
                  <!-- checkbox -->
                  <span class="w-5 h-5 border border-black rounded-sm mr-2 flex items-center justify-center" :class="{
                    'bg-primary border-primary': $store.state.queries.discount_from === amount,
                  }">
                    <svg class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </span>
                  <span class="text-black text-base">
                    {{$t('discount-from', {amount: amount})}}
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>