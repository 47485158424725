<template>
    <div class="mt-5 pt-5 flex flex-col items-center justify-center">
        <h1 v-if="err.statusCode === 404" class="text-xl font-bold">Page not found</h1>
        <h1 v-else class="text-xl font-bold">{{err.message ? err.message : 'Something went wrong'}}</h1>
        <h2 class="text-lg font-semibold">Error - {{err.statusCode}}</h2>
        <nuxt-link :to="localePath({name: 'index'})" v-if="err.statusCode !== 429">
            <img src="~/assets/images/ui/logo-color-black.png" alt="sneakers123" class="w-40 mt-5">
        </nuxt-link>
        <br>
    </div>
</template>

<script>
    export default {
        props: ['error'],
        computed: {
            err() {
                return this.$store.state.serverErrorObj.status ? this.$store.state.serverErrorObj : this.error
            }
        }
    }
</script>

<style scoped>

</style>