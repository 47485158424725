var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nuxt-link',{attrs:{"to":_vm.localePath({name: 'new-go',query: _vm.goProductQuery }),"target":"_blank"}},[_c('div',{staticClass:"flex flex-row justify-between w-full py-4 border-b border-gray-low cursor-pointer",on:{"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('div',{staticClass:"flex flex-col h-16 w-16 rounded-full border border-gray-low"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"rounded-full w-full h-16 object-contain object-center p-1",attrs:{"data-src":_vm.product.shop.logo,"alt":_vm.product.shop.name + ' logo'}})]),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-1 pl-4 justify-center items-start"},[_c('div',{staticClass:"text-sm text-black font-semibold flex flex-row justify-start items-center mb-1"},[_vm._v(_vm._s(_vm.product.shop.name)+" "),_c('span',{staticClass:"ml-1"},[_c('img',{staticClass:"w-4 h-4",attrs:{"src":require("assets/images/ui/icons/check-product.png")}})])]),_vm._v(" "),_c('div',{staticClass:"text-xs text-gray-medium"},[_vm._v(_vm._s(_vm.$t('last-time-check', {time: _vm.product.last_check_human})))])]),_vm._v(" "),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row justify-end items-center mb-1"},[(_vm.product.sale_price)?_c('div',{staticClass:"text-gray-high text-xs line-through mr-1"},[_vm._v("\n        "+_vm._s(_vm.getProductPrice)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{class:{
                  'text-sm': true,
                  'text-danger': _vm.product.sale_price,
                  'text-black': !_vm.product.sale_price
                }},[_vm._v(_vm._s(_vm.product.sale_price ? _vm.getSalePrice : _vm.getProductPrice))])]),_vm._v(" "),_c('div',{staticClass:"flex flex-row justify-end items-center"},[_c('div',{class:{
                'border border-black h-8 text-sm flex flex-col justify-center items-center cursor-pointer px-2': true,
                'bg-white text-black': !_vm.hovered,
                'bg-black text-white': _vm.hovered
              }},[_vm._v(_vm._s(_vm.$t('shop-now-text')))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }