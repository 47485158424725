var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full",style:({width: _vm.width ? _vm.width + 'px' : _vm.block ? '100%' : 'auto'})},[(_vm.to)?_c('nuxt-link',{class:{
    'flex flex-col py-2 px-4 rounded justify-center items-center': true,
    'underline': _vm.underline,
    'font-bold': _vm.bold,
    'w-full flex-1': _vm.block,
    'cursor-not-allowed opacity-50': _vm.disabled,
    'bg-black text-white border-black': _vm.color === 'black',
    'bg-gray-low text-black border-gray-low': _vm.color === 'gray-low',
    'bg-gray-medium text-black border-gray-medium': _vm.color === 'gray-medium',
    'bg-gray-high text-white border-gray-high': _vm.color === 'gray-high',
    'bg-danger text-white border-danger': _vm.color === 'danger',
    'bg-primary text-white border-primary': _vm.color === 'primary',
    'h-6 text-xs px-2': _vm.size === 'xs',
    'h-8 text-sm px-3': _vm.size === 'sm',
    'h-10 text-base px-4': _vm.size === 'md',
    'h-12 text-lg px-5': _vm.size === 'lg',
    'text-xs': _vm.fontSize === 'xs',
    'text-sm': _vm.fontSize === 'sm',
    'text-base': _vm.fontSize === 'md',
    'text-lg': _vm.fontSize === 'lg',
    'min-h-6': _vm.height === 'xs',
    'min-h-8': _vm.height === 'sm',
    'min-h-10': _vm.height === 'md',
    'min-h-12': _vm.height === 'lg',

  },attrs:{"to":_vm.localePath(_vm.to),"target":_vm.target}},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.click || _vm.type === 'submit')?_c('button',{class:{
    'hover:underline rounded-xs w-full': true,
    'bg-black text-white border-black': _vm.color === 'black',
    'bg-gray-low text-black border-gray-low': _vm.color === 'gray-low',
    'bg-gray-medium text-black border-gray-medium': _vm.color === 'gray-medium',
    'bg-gray-high text-white border-gray-high': _vm.color === 'gray-high',
    'bg-danger text-white border-danger': _vm.color === 'danger',
    'bg-primary text-white border-primary': _vm.color === 'primary',
    'h-6 text-xs px-2': _vm.size === 'xs',
    'h-8 text-sm px-3': _vm.size === 'sm',
    'h-10 text-base px-4': _vm.size === 'md',
    'h-12 text-lg px-5': _vm.size === 'lg',
    'cursor-not-allowed opacity-50': _vm.disabled
  },attrs:{"disabled":_vm.disabled,"type":_vm.type === 'submit' ? 'submit' : 'button'},on:{"click":() => {
    if(_vm.disabled) return;
    if(_vm.click) _vm.click();
  }}},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }