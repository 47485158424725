<template>
  <div class="flex flex-col w-full">
    <ui-breadcrumbs :items="breadcrumbItems"></ui-breadcrumbs>
    <div class="border-b border-black py-[72px] text-center">
      <h1 class="text-2xl font-bold text-font-line-color">{{$t('All Models')}}</h1>
    </div>
    <div class="flex flex-col items-center py-4 justify-center gap-4 border-b border-black">
      <div v-for="(categories, brand) in models" :key="brand" class="flex flex-col w-full">
        <div class="flex flex-row items-center justify-between px-6 pb-5 pt-14 border-b border-black w-full">
          <div><h2 class="text-base font-bold hover:underline">
            <nuxt-link :to="sneakerLocalePath(brand)" >{{brand | titleCase}}</nuxt-link>
          </h2></div>
          <div><span class="text-base font-semibold">{{brandCounts[brand]}}</span></div>
        </div>
        <ul class="flex flex-row flex-wrap px-6 py-4 w-full border-b border-black">
          <li v-for="category in categories" :key="category.title" class="flex flex-row w-full lg:w-1/3 justify-between items-center pr-0 lg:pr-24 py-3.5">
            <nuxt-link :to="sneakerLocalePath(category.url)" class="text-black text-sm hover:underline">
              {{category.title}}
            </nuxt-link>
            <span class="text-sm text-gray-high ">{{category.products}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
    import SimpleHeader from "../components/SimpleHeader";
    import LinkParser from "../components/linkParser";
    import Breadcrumbs from "../components/Breadcrumbs";
    import staticMetasMixin from "../plugins/staticMetasMixin";
    import {mapState} from "vuex";
    import UiBreadcrumbs from "@/components/v2/ui/UiBreadcrumbs.vue";

    export default {
        name: "models",
        components: {UiBreadcrumbs, Breadcrumbs, LinkParser, SimpleHeader},
        middleware: 'loadMetaNonQuery',
        mixins: [staticMetasMixin],
        data() {
            return {
                breadcrumbItems: [
                    {text: 'Home', lnk: this.localePath('index')},
                    {text: this.$t('All Models'), lnk: this.localePath('models')},
                ],
            }
        },
        computed:{
            ...mapState(['currentRouteMetas']),
              schemaBreadcrumbs()  {
                  let bItems = this.breadcrumbItems.map((item, index)=>{
                      return {
                          "@type": "ListItem",
                          "position": index + 1,
                          "item": {
                              "@id": process.env.appUrl + item.lnk,
                              "name": (item.jsonText ? item.jsonText: item.text)
                          }
                      }
                  })
                  return {
                      script: [
                          {
                              hid: 'breadcrumbs',
                              type: 'application/ld+json',
                              innerHTML: JSON.stringify({
                                  "@context": "http://schema.org",
                                  "@type": "BreadcrumbList",
                                  "itemListElement": bItems
                              }, null, 2),
                          },
                      ]
                  }
              },
            seoHTML() {
                return this.replaceShopAndSneakerCount(this.currentRouteMetas.seo_description)
            }
        },
        async asyncData({$axios})
        {
            try {
                let {data} = await $axios.get('models');
                return {
                    models: data.models,
                    brandCounts: data.brandCounts
                }
            }
            catch (e) {
                console.log('models ', e.response ? e.response.status : e)
            }
        },
    }
</script>
