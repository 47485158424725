<template>
  <div class="search-header-wrap">
    <div class="search-header-container">
      <search-input></search-input>
      <div class="search-header-last-view-items" v-if="viewedList.length > 0">
        <div class="search-header-last-view-items-list">
          <div class="search-header-last-view-item" v-for="i in viewedLength" :key="i">
            <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : viewedList[i - 1].slug}})">
              <img :src="thumb(viewedList[i - 1])" alt="Sneakers123" class="search-header-last-view-item-img" @error="replaceByDefault"   />
            </nuxt-link>
          </div>
        </div>
        <div class="search-header-last-view-items-title" @click="lastViewToggle">
          <div class="search-header-last-view-items-title-text">{{$t('Last views')}}</div>
          <div class="search-header-last-view-items-title-text-icon">
            <img src="~/assets/icons/ui/accordion-arrow.svg" alt="arrow" />
          </div>
        </div>

        <u-dialog :onClose="lastViewToggle"
                  :show="lastViewVisible"
                  :title="$t('Last views')"
                  size="md">
          <div class="last-viewed-container">
            <release-item-row v-for="elem in viewedList" :key="elem.slug" :release="elem" name-width="175px" :handle-click="lastViewToggle" />
          </div>
        </u-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {debounce, filter} from "lodash-core";
import UDialog from "@/components/ui/dialog.vue";
import UDialogHeader from "@/components/ui/dialog-header.vue";
import UDialogContent from "@/components/ui/dialog-content.vue";
import ReleaseItem from "@/components/new/release/release-item.vue";
import ReleaseItemRow from "@/components/new/release/release-item-row.vue";
import SearchInput from "@/components/new/search/search-input.vue";

export default {
  name: "search-header-layout",
  components: {SearchInput, ReleaseItemRow, ReleaseItem, UDialogContent, UDialogHeader, UDialog},
  data()
  {
    return {
      lastViewVisible: false,
    }
  },
  methods: {
    lastViewToggle()
    {
      this.lastViewVisible = !this.lastViewVisible
    },
    replaceByDefault(e) {
      e.target.src = require('~/assets/img/placeholder_thumb.png');
    }

  },
  computed: {
    ...mapState(['viewedSneakers']),
    ...mapGetters(['currency']),
    viewedLength()
    {
      return this.viewedList.length < 3 ? this.viewedList.length : 3
    },
    viewedList(){
      const filtered = filter(this.viewedSneakers, e=>{ return e.slug !== this.$route.params.product });
      return filtered.slice(0, filtered.length > 12 ? 12 : filtered.length);
    }
  },
};
</script>

<style lang="scss" scoped>
.search-header-wrap {
  background-color: #fff;
  .search-header-container {
    padding: 0 15px;
    padding-bottom: 16px;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;


  }

  .search-header-last-view-items {
    display: flex;
    align-items: center;
    .search-header-last-view-items-list {
      display: flex;
      .search-header-last-view-item {
        margin-right: 10px;
        cursor: pointer;
        .search-header-last-view-item-img {
          border: 1.5px solid #dfe6f0;
          border-radius: 50%;
          width: 48px;
          height: 48px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .search-header-last-view-items-title {
      margin-left: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-direction: row;
      .search-header-last-view-items-title-text {
        font-size: 16px;
        font-weight: 800;
        color: #191919;
      }
      .search-header-last-view-items-title-text-icon {
        margin-left: 5px;
        img {
          width: auto;
          height: 7px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .last-viewed-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }

  /* mobile */
  @media (max-width: 1024px) {
    .search-header-last-view-items {
      display: none;
    }

    .search-header-container{
      padding-bottom: 8px;
    }
  }
}
</style>