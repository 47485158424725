<template>
  <div class="flex flex-col w-full">
    <ui-breadcrumbs :items="breadcrumbItems"></ui-breadcrumbs>
    <div class="border-b border-black py-[72px] text-center">
      <h1 class="text-2xl font-bold text-font-line-color" v-if="filledSearchString">'{{filledSearchString}}'</h1>
      <h1 class="text-2xl font-bold text-font-line-color" v-else>'Sneaker'</h1>
    </div>
    <div class="flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black">
      <UiButton :click="toggleReleaseListFilter" :width="300">FILTER</UiButton>
      <UiSelect :click="(selected) => {
        this.querySort = selected.value;
      }" :width="300"
                :title="$t('Sort by')"
                :items="[
          {
            label: $t('sort.relevant'),
            value: 'relevant'
          },
          {
            label: $t('sort.latest'),
            value: 'latest'
          },
          {
            label: $t('sort.high'),
            value: 'high'
          },
          {
            label: $t('sort.low'),
            value: 'low'
          }
      ]"></UiSelect>
    </div>
    <div class="flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black">
      <p class="text-xs font-inter text-font-line-color">{{$t('labels.showing', {total: numberFormat(loadedSneakers.total, 0)})}}</p>
    </div>
    <div class="flex flex-row flex-wrap w-full [&>*:nth-child(odd)]:border-r" v-if="loadedSneakers.total > 0">
      <div class="flex lg:border-r border-b border-black lg:last:border-r-0 basis-1/2 lg:basis-1/4"
           v-if="placeSneaker(i)" v-for="(sneaker, i) in loadedSneakers.data" :key="i" >
        <ReleaseThumbnail :release="sneaker"/>
      </div>
    </div>

    <div class="flex flex-row items-center px-6 py-[200px] justify-center gap-4 border-b border-black"
         v-if="loadedSneakers.total === 0">
      <p class="text-xs font-inter text-font-line-color">{{$t('No sneakers found matching your criteria')}}</p>
    </div>

    <div class="flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black"
         v-if="loadedSneakers.total > 0">
      <div class="w-full lg:min-w-[800px]">
        <b-pagination-nav
            class="sneaker-pagination"
            @input="goToTop"
            :value="queryPage"
            :number-of-pages="Math.ceil(loadedSneakers.total/loadedSneakers.per_page)"
            align="center"
            :link-gen="linkGen"
            size="md"
            use-router
        ></b-pagination-nav>
      </div>
    </div>

    <div class="flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black bg-black text-white "
         v-if="seoText &&  loadedSneakers.data && loadedSneakers.data.length < 61">
      <div class="w-full">
        <link-parser :content="seoHTML"></link-parser>
      </div>
    </div>
    <release-list-filter-drawer></release-list-filter-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import debounce                                         from 'lodash/debounce';
import {capitalize, indexOf, toLower, remove, without, upperFirst, map, endsWith} from "lodash-core";
import UiBreadcrumbs from "../../ui/UiBreadcrumbs.vue";
import UiButton from "../../ui/UiButton.vue";
import UiSelect from "../../ui/UiSelect.vue";
import {goToTop, numberFormat} from "../../../../lib/utils";
import ReleaseThumbnail from "../../thumbnails/release-thumbnail.vue";
import SneakerThumbnail from "../../../Sneakers/Thumbnail.vue";
import AdvertisementThumbnail from "../../../AdvertisementThumbnail.vue";
import Pagination from "../../../Sneakers/Pagination.vue";
import LinkParser from "../../../linkParser.vue";
import ReleaseListFilterDrawer from "./components/release-list-filter-drawer.vue";

export default {
  name: "release-list",
  fetchOnServer: true,
  props     : {
    fixed       : {
      type   : Boolean,
      default: () => {return false}
    },
    brandsSlider: {
      type   : Boolean,
      default: false
    },
    headerData  : {
      type   : Object,
      default: () => {
        return {
          title: '',
          image: ''
        }
      }
    },
    seoText     : {
      type   : String,
      default: () => {return ''}
    },
    breadcrumbItems :{
      type: Array
    }
  },
  components: {
    ReleaseListFilterDrawer,
    LinkParser,
    Pagination,
    AdvertisementThumbnail,
    SneakerThumbnail,
    ReleaseThumbnail,
    UiSelect,
    UiButton,
    UiBreadcrumbs
  },
  data()
  {
    return {
      lastBannerPage        : 1,
      advertisementPositions: {},
      isMobile              : this.$device.isMobileOrTablet,
      loaded                : false,
      isMobileFiltersVisible: false,
      isMobileSortVisible   : false,
      newResultNumber       : null,
      waitForOtherFilters   : false,
      colors               : ["black", "blue", "white", "grey", "lyellow", "red", "orange", "yellow", "green", "marine",
        "dark-grey", "light-grey", "pink", "greenyellow", "rainbow"],
      sizes                : {
        eu: ["<17", "18", "18.5", "19", "19.5", "20", "20.5", "21", "21.5", "22", "22.5", "23", "23.5", "24", "24.5", "25",
          "25.5", "26", "26.5", "27", "27.5", "28", "28.5", "29", "29.5", "30", "30.5", "31", "31.5", "32", "32.5", "33",
          "33.5", "34", "34.5", "35", "35.5", "36", "36.5", "37", "37.5", "38", "38.5", "39", "39.5", "40", "40.5", "41",
          "41.5", "42", "42.5", "43", "43.5", "44", "44.5", "45", "45.5", "46", "46.5", "47>"],
        us: ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5",
          "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "15.5", "16", "16.5", "17", "17.5",
          "18", "18.5", "19", "19.5", "20>"],
        uk: ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5",
          "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14", "14.5", "15", "15.5", "16", "16.5", "17",
          "17.5", "18", "18.5", "19>"]
      },
      showIntermediateSizes: false,
      showBrands: false,
    }
  },
  computed  : {
    ...mapState(['filters', 'sizeFormat', 'brands',
      'searchString', 'totalSneakersCount', 'totalShopsCount', 'loadedSneakers', 'showReleaseListFilter']),
    ...mapGetters(['currency']),
    numberOfLoaded(){
      let loaded = this.loadedSneakers.current_page * this.loadedSneakers.per_page;
      return (loaded > this.loadedSneakers.total) ? this.loadedSneakers.total: loaded;
    },
    seoHTML()
    {
      return this.replaceShopAndSneakerCount(this.seoText )
    },
    subHeader()
    {
      try {
        return {
          minPrice  : this.loadedSneakers.aggregations_result['min_price_' + this.currency].value,
          shopsCount: this.loadedSneakers.aggregations_result.total_shops.value,
          totalCount: this.loadedSneakers.total
        }
      }
      catch (e) {
        return {}
      }
    },
    filteredSuggestions()
    {
      return this.loadedSneakers.suggestions
          ? this.loadedSneakers.suggestions
              .filter(elem => this.$route.path.indexOf(elem.url) === -1)
              .sort((a, b) => b.clicks - a.clicks)
          : [];
    },
    progress()
    {
      return this.numberOfLoaded * 100 / this.loadedSneakers.total
    },
    hasSelectedFilters()
    {
      return this.queryFilters.length || this.queryGender.length || this.querySizes.length
          || this.queryColor.length || this.queryBrands.length || this.querySort !== 'relevant' || this.queryDiscountFrom>0;
    },
    filledSearchString()
    {
      let text = {};
      text["brands"] = this.queryBrands.map(e => this.brandNameBySlug(e)).join(' & ');
      text["gender"] = this.queryGender.map(e => this.$t('filters.' + e)).join(' & ');
      text["color"] = this.queryColor.map(e => this.$t('filters.' + e)).join(' & ');
      text["sizes"] = this.querySizes.map(e => { return e.substr(2); }).join(' & ');

      // category
      if(this.queryCategory.length){
        if(this.queryCategory[0].includes(text["brands"])){
          text["brands"] = this.queryCategory;
        }else
          text["brands"] += ' ' + this.queryCategory
      }
      if(this.queryDiscountFrom > 1)
        text["discount_from"] = this.$t('discount-from', {amount: this.queryDiscountFrom} );

      this.queryFilters.forEach(el => {
        text[el] = this.$t('filters.' + el);
      });

      // this.fillAppliedFilters();

      let q = this.searchString;
      if (q) {
        this._.each(this.brands, brand => {
          let pattern = new RegExp(brand.brand, "gi");
          q = this.queryBrands.includes(brand.slug) ? q.replace(pattern, '') : q;
        })
        this._.each(this.colors, color => {
          let pattern = new RegExp(this.$t('filters.' + color), "gi");
          q = this.queryColor.includes(color.toLowerCase()) ? q.replace(pattern, '') : q;
        })
      }
      text["q"] = this._.trim(q.replace(/"\s*(.*?)\s*"/g, '"$1"'));
      text = this._.pickBy(text, t => { return t});
      if (text.reseller) {
        return 'Sneaker Reseller';
      }


      if (!this._.isEmpty(text)) {

        return this.fix(this.$t('sneaker-title-general', this.querySizes.length > 1, text));
      }
      return 'Sneaker';
    },
    actualSizes(){
      if (this.showIntermediateSizes)
        return this.sizes[this.sizeFormat];
      else {
        let sizes = this.sizes[this.sizeFormat];
        let intermediateSizes = [];
        for (let index in sizes) {
          let containsDot = sizes[index].indexOf('.');
          if (containsDot === -1)
            intermediateSizes.push(sizes[index]);
        }
        return intermediateSizes;
      }
    },

    brandList()
    {
      if(this.showBrands)
        return this.brands;
      else
        if(this.brands.length > 10)
          return this.brands.slice(0, 10);
        else
          return this.brands;
    },
    queryFilters:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'filters', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
        if(!this.$device.isDesktop && this.showReleaseListFilter){
          this.toggleReleaseListFilter();
        }
      }, 200),
      get(){
        return this.$store.state.queries.filters
      }
    },
    queryGender:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'gender', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
        if(!this.$device.isDesktop && this.showReleaseListFilter){
          this.toggleReleaseListFilter();
        }
      }, 200),
      get(){
        return this.$store.state.queries.gender
      }
    },
    querySizes:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'sizes', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
        if(!this.$device.isDesktop && this.showReleaseListFilter){
          this.toggleReleaseListFilter();
        }
      }, 200),
      get(){
        return this.$store.state.queries.sizes
      }
    },
    queryColor:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'color', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
        if(!this.$device.isDesktop && this.showReleaseListFilter){
          this.toggleReleaseListFilter();
        }
      }, 600),
      get(){
        return this.$store.state.queries.color
      }
    },
    queryBrands:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'brands', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
        if(!this.$device.isDesktop && this.showReleaseListFilter){
          this.toggleReleaseListFilter();
        }
      }, 200),
      get(){
        return this.$store.state.queries.brands
      }
    },
    queryCategory:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'category', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
        if(!this.$device.isDesktop && this.showReleaseListFilter){
          this.toggleReleaseListFilter();
        }
      }, 200),
      get(){
        return this.$store.state.queries.category
      }
    },
    querySort:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'sort', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
        if(!this.$device.isDesktop && this.showReleaseListFilter){
          this.toggleReleaseListFilter();
        }
      }, 200),
      get(){
        return this.$store.state.queries.sort
      }
    },
    queryDiscountFrom:{
      set: debounce(function (value){
        if(value !== -1) {
          this.$store.commit('changeQuery', {field: 'discount_from', data: value})
          if(!this.waitForOtherFilters)
            this.navigateSneaker();
        }else{
          this.$store.commit('changeQuery', {field: 'discount_from', data: 0})
        }
      }, 200),
      get(){
        return this.$store.state.queries.discount_from
      }
    },
    queryPage:{
      set: debounce(function (value){
        this.$store.commit('changeQuery', {field: 'page', data: value})
        if(!this.waitForOtherFilters)
          this.navigateSneaker();
      }, 10),
      get(){
        return this.$store.state.queries.page
      }
    },
  },
  methods   : {
    numberFormat,
    goToTop,
    ...mapActions(["toggleReleaseListFilter"]),
    ...mapMutations(['changeQuery','resetQueries', 'setNextPage', 'setSearchString','setLoadingMoreSneakers']),
    toggleIntermediateSizes() {
      this.showIntermediateSizes = !this.showIntermediateSizes;
    },
    toggleShowBrands(){
      this.showBrands = !this.showBrands;
    },
    startCase(text) {
      return map(text.split(' '), upperFirst).join(' ')
    },
    resetDiscount(amount)
    {
      if(amount == this.queryDiscountFrom)
        this.queryDiscountFrom = 0;
      else{
        if(amount === -1)
          this.queryDiscountFrom = -1;
      }
    },
    resetLocalQueries() {
      this.waitForOtherFilters = true;
      this.querySort = 'relevant';
      this.querySizes = [];
      this.queryColor = [];
      this.queryBrands = [];
      this.queryFilters = [];
      this.queryGender = [];
      this.queryCategory = [];
      this.queryDiscountFrom = 0;
      this.navigateSneaker().then(()=>{
        this.waitForOtherFilters = false;
      })
    },
    getBanner(position)
    {
      if (this.loadedSneakers.banners)
        return this.loadedSneakers.banners[this.advertisementPositions[position]];
    },
    placeSneaker(position)
    {
      if(this._.isEmpty(this.loadedSneakers.banners))
        return true;

      if (this._.isEmpty(this.advertisementPositions)) {
        let initialRandom = Math.floor(Math.random() * (3 - 1 + 1) + 1);
        this.advertisementPositions[initialRandom] = 0;
      }
      /* show banner only if total sneakers result >= 5 */
      if (this.loadedSneakers.total < 5)
        return true;

      let currentPage = this.$store.state.queries.page;
      if (currentPage === this.lastBannerPage) {
        if (position in this.advertisementPositions) {
          this.setNextPosition(position);
          return false;
        }
      } else if ((currentPage - this.lastBannerPage) === 3) {
        /* show banner again */
        if (position in this.advertisementPositions) {
          this.lastBannerPage = currentPage;
          this.setNextPosition(position);
          return false;
        }
      } else if (position in this.advertisementPositions)
        return false;

      return true;
    },
    setNextPosition(position)
    {

      let nextPosition = position + 3 * 60;
      if (!(nextPosition in this.advertisementPositions)) {
        let keys = Object.keys(this.advertisementPositions).map(function (item) {
          return +item;
        });
        let maxKey = Math.max(...keys);
        this.advertisementPositions[nextPosition] = this.getNextBannerIndex(this.advertisementPositions[maxKey]);
      }
    },
    getNextBannerIndex(currentBannerIndex)
    {
      let nextBannerIndex = currentBannerIndex + 1;
      if (this.loadedSneakers.banners && nextBannerIndex > this.loadedSneakers.banners.length - 1) {
        nextBannerIndex = 0;
      }
      return nextBannerIndex;
    },
    async clearSearchString()
    {
      this.setSearchString('');
      this.navigateSneaker();
    },
    getSelectedFilterValues(filter)
    {
      let filtersList = [];
      let filters = this.$store.state.queries[filter]
      if(filter === 'filters')
        filters = without(filters, 'sale');

      for (let item in filters) {
        filtersList.push((filter != 'sizes' && filter != 'brands')
            ? this.$t('filters.' + this.$store.state.queries[filter][item])
            : filter == 'brands'
                ? this.brandNameBySlug(this.$store.state.queries[filter][item])
                : this.$store.state.queries[filter][item]);
      }
      if (filtersList) {
        let value = filtersList.join(', ');
        if (value.length > 25)
          value = value.substring(0, 25) + '...';
        return value;
      }
      return '';
    },
    getSelectedFilterValuesSale()
    {
      let text = '';
      if(indexOf(this.queryFilters, 'sale') > -1)
        text += this.$t('filters.sale');
      if(this.queryDiscountFrom > 0)
        text += ', ' + this.$t('discount-from', {amount: this.queryDiscountFrom});
      return text
    },
    mobileFilters()
    {
      this.isMobileFiltersVisible = !this.isMobileFiltersVisible;
      if (this.isMobileFiltersVisible) {
        this.newResultNumber = null;
      }
    },
    mobileSort()
    {
      this.isMobileSortVisible = !this.isMobileSortVisible;
    },
    delStyle()
    {
      // if (document.getElementById('collapseFilter'))
      //     document.getElementById('collapseFilter').removeAttribute('style');
    },
    hiddenSidebar()
    {
      // this.delStyle();
    },
    removeFilter({key, item, filter, index})
    {
      if(key === 'discount_from') {
        this.queryDiscountFrom = 0;
        return
      }
      let arr = [...filter];
      arr.splice(index, 1);
      this['query' + this._.capitalize(key)] = arr
    },
    nextPage()
    {
      this.setLoadingMoreSneakers(true);
      this.setNextPage();
      this.navigateSneaker();
    },
    fix(str)
    {
      return this._.replace(str, /[\s]{2,}/gi, ' ').trim();
    },
    checkResize()
    {
      if(window.innerWidth < 991.99)
        this.isMobile = true;
      else
        this.isMobile = false;
    },
    linkGen(pageNum)
    {
      let path = this.$route.path.replace(/\/page\/.*/gm, '');
      if((path+'/' === this.sneakerRootPage().path) && pageNum ===1)
        path = path + '/';

      let pagePath =  pageNum === 1 ? path : path + (endsWith(path, '/') ? '':'/') + 'page/' + pageNum;
      return this.$route.fullPath.replace(this.$route.path, pagePath)
    },
  },
  inheritAttrs: true,
  mounted()
  {
    this.delStyle();
    this.lastBannerPage = this.$store.state.queries.page;
    // if (this.isMobile){

    //         this.navigateSneaker();
    // }

    // if (window.innerWidth < 991.99)
    //     this.isMobile = true;
    // this.$nextTick(function() {
    //     window.addEventListener('resize', this.checkResize);
    // })
  },
  beforeDestroy() {
    this.$store.commit('setLoadedSneakers', {});
    window.removeEventListener('resize', this.checkResize);
  },
  head(){
    let pagLinks = [];
    if(this.loadedSneakers.current_page > 1)
      pagLinks.push({hid: 'rel-prev', rel: 'prev', href: process.env.appUrl + this.linkGen(this.loadedSneakers.current_page-1)})
    if(this.loadedSneakers.current_page < this.loadedSneakers.last_page)
      pagLinks.push( {hid: 'rel-next', rel: 'next', href: process.env.appUrl + this.linkGen(this.loadedSneakers.current_page + 1)})
    return {
      link: pagLinks
    }

  }
}
</script>

<style scoped>

</style>