<script>
export default {
  name: "UiCheckboxGroup",
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "checkbox",
    },
    model: {
      required: true,
    }
  },
  computed: {
    checked() {
      return this.list.includes(this.value);
    }
  }
}
//'black': '#131416',
// 'gray-low': '#e8ebed',
//     'gray-medium': '#c9cdd2',
//     'gray-high': '#6c7781',
//     'danger': '#db3d45',
//     'primary': '#23d1d8',
</script>

<template>
  <label class="flex items-center cursor-pointer">
    <input type="checkbox" class="hidden" :value="value" v-model="model" :name="name">
    <!-- checkbox -->
    <span class="w-5 h-5 border border-black rounded-sm mr-2 flex items-center justify-center" :class="{
      'bg-black': checked,
    }">
      <svg class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
      </svg>
    </span>
    <span class="text-gray-high text-sm">
      <slot></slot>
    </span>
  </label>
</template>

<style scoped>

</style>