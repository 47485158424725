<script>
export default {
  name: "u-dialog",
  props: {
    noPadding: {
      type: Boolean,
      default: false
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    noScroll: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "none"
    },
    width: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    backgroundCloseEnabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    maxWidth() {
      return this.width || "";
    }
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleClose);
  },
  watch: {
    show(val) {
      if(val) {
        window.history.pushState({ dialogOpen: true }, '');
        window.addEventListener("popstate", this.handleClose);
        window.document.body.classList.add("body-scroll-lock");
      } else {
        window.document.body.classList.remove("body-scroll-lock");
        window.removeEventListener("popstate", this.handleClose);
      }
    }
  },
  methods: {
    handleClose() {
      //check window history state
      if (window.history.state && window.history.state.dialogOpen) {
        this.$router.go(-1);
      }

      this.onClose();
    }
  }
}
</script>

<template>
  <div class="u-dialog" :class="{ 'u-dialog-show': show, 'u-dialog-full': size === 'full', 'u-dialog__content__no-padding' : noPadding }">
    <div class="u-dialog__overlay" @click="backgroundCloseEnabled && handleClose()"></div>
    <div class="u-dialog__content" :class="{ 'none-size': size === 'none' }">
      <div class="u-dialog__content__header" :class="`u-dialog__content__header__${size === 'none' ? 'md' : size}`" v-if="!noHeader">
        <div class="u-dialog-header">
          <h3 class="u-dialog-title">
            {{ title }}
            <button class="u-dialog-close" @click="handleClose">
              <img src="~/assets/icons/ui/close-icon.svg" alt="close" />
            </button>
          </h3>
          <h5 class="u-dialog-sub-title" v-if="subTitle">
            {{ subTitle }}
          </h5>
        </div>
      </div>
      <div class="u-dialog__content__body"  :style="{ maxWidth }"
        :class="{ 'no-scroll': noScroll, 'u-dialog__content__body__none': size === 'none',
        'u-dialog__content__body__xs': size === 'xs', 'u-dialog__content__body__sm': size === 'sm',
        'u-dialog__content__body__md': size === 'md', 'u-dialog__content__body__lg': size === 'lg' }"
      >
        <slot></slot>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.u-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
  transition: all 0.3s ease;
  display: flex;
  opacity: 0;
  visibility: hidden;
  &.u-dialog-show{
    opacity: 1;
    visibility: visible;
  }
  .u-dialog__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .u-dialog__content {
    position: relative;
    z-index: 2;
    margin: auto;
    padding: 24px 0;
    //max-width: 500px;
    overflow-x: hidden;
    overflow-y: hidden;
    background: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.33);

    .u-dialog__content__header {
      padding: 0 24px;
      margin-bottom: 24px;
    }
    .u-dialog__content__body{
      padding: 0 24px;
      overflow-x: hidden;
      overflow-y: auto;

      &.no-scroll{
        overflow: hidden;
      }
    }


    .u-dialog__content__header__lg {
      width: 800px;
      max-width: 800px;
    }

    .u-dialog__content__header__md {
      width: 500px;
      max-width: 500px;
    }

    .u-dialog__content__header__sm {
      width: 340px;
      max-width: 340px;
    }

    .u-dialog__content__header__xs {
      width: 200px;
      max-width: 200px;
    }

    .u-dialog__content__body__lg {
      width: 800px;
      max-width: 800px;
      max-height: 50vh;
    }

    .u-dialog__content__body__md {
      width: 500px;
      max-width: 500px;
      max-height: 50vh;
    }

    .u-dialog__content__body__sm {
      width: 340px;
      max-width: 340px;
      max-height: 50vh;
    }

    .u-dialog__content__body__xs {
      width: 200px;
      max-width: 200px;
      max-height: 50vh;
    }

    /** mobile **/
    @media (max-width: 767px) {

      //max-height: 70vh;
      //overflow: hidden;

      .u-dialog__content__body{
        padding: 0 16px;
      }
      .u-dialog__content__header{
        padding: 0 16px;
        margin-bottom: 16px;
      }
      &.none-size{
        padding: 16px;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
      }



      .u-dialog__content__header__lg, .u-dialog__content__header__md, .u-dialog__content__header__sm, .u-dialog__content__header__xs {
        width: 340px;
        max-width: 340px;
      }

      .u-dialog__content__body__lg, .u-dialog__content__body__md, .u-dialog__content__body__sm, .u-dialog__content__body__xs {
        width: 340px;
        max-width: 340px;
        max-height: 50vh;
      }
    }
  }



  &.u-dialog-full{

    .u-dialog__content{
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 0;


      .u-dialog__content__body{
        padding: 0 24px;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }
  }

  &.u-dialog__content__no-padding{
    padding: 0;
    .u-dialog__content{
      padding: 0;
      margin-bottom: 0;
      .u-dialog__content__body{
        padding: 0;
      }
    }
  }

}

.u-dialog__content__header {
  margin-bottom: 15px;
}

.u-dialog-title {
  font-size: 20px;
  font-weight: 800;
  color: #191919;
  position: relative;
  margin-bottom: 6px;
  white-space: normal;
  padding-right: 30px;
}

.u-dialog-sub-title {
  font-size: 14px;
  font-weight: normal;
  color: #191919;
  margin-bottom: 6px;
}
.u-dialog-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 1.5px solid #fff;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}

</style>