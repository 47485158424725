<template>
  <div class="flex flex-col w-full">
    <HomeSearchSection/>
    <!--    <HomeTopButtonsSection/>-->
    <HomeStoreReleaseSection :shops="home.shops" />
    <HomeContentsSection/>
    <HomeStorePopularSneakersSection :products="home.shopPopularProducts" :totalShopCount="home.totalShopCount"/>
    <HomeStylesSection/>
    <HomeStoreSneakersRankSection :products="home.shopRankProducts" :totalShopCount="home.totalShopCount"/>
    <HomeGenderSection/>
    <HomeStepSection/>
    <HomeBrandsSection/>
    <HomeReleasesSection :releases="home.trending" />
    <home-description-section v-show="home.metas.seo_description" :content="seoHTML"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import staticMetasMixin from "../plugins/staticMetasMixin"
import {shuffle} from "lodash-core";
import HomeSearchSection from "../components/v2/sections/home/home-search-section.vue";
import Swiper from "swiper";
import HomeStoreReleaseSection from "../components/v2/sections/home/home-store-release-section.vue";
import HomeTopButtonsSection from "../components/v2/sections/home/home-top-buttons-section.vue";
import HomeContentsSection from "../components/v2/sections/home/home-contents-section.vue";
import HomeStorePopularSneakersSection from "../components/v2/sections/home/home-store-popular-sneakers-section.vue";
import HomeStylesSection from "../components/v2/sections/home/home-styles-section.vue";
import HomeStoreSneakersRankSection from "../components/v2/sections/home/home-store-sneakers-rank-section.vue";
import HomeGenderSection from "../components/v2/sections/home/home-gender-section.vue";
import HomeStepSection from "../components/v2/sections/home/home-step-section.vue";
import HomeBrandsSection from "../components/v2/sections/home/home-brands-section.vue";
import HomeReleasesSection from "../components/v2/sections/home/home-releases-section.vue";
import HomeDescriptionSection from "@/components/new/home/home-description-section.vue";

export default {
  components: {
    HomeDescriptionSection,
    HomeReleasesSection,
    HomeBrandsSection,
    HomeStepSection,
    HomeGenderSection,
    HomeStoreSneakersRankSection,
    HomeStylesSection,
    HomeStorePopularSneakersSection,
    HomeContentsSection,
    HomeTopButtonsSection,
    HomeStoreReleaseSection,
    HomeSearchSection,
  },
  mixins: [staticMetasMixin],
  computed: {
    ...mapState(['currentRouteMetas', 'totalSneakersCount', 'totalShopsCount']),
    seoHTML() {
      return this.replaceShopAndSneakerCount(this.home.metas.seo_description)
    },
    pageTitle() {
      return this.replaceShopAndSneakerCount(this.home.metas.title)
    },
    pageDescription() {
      return this.replaceShopAndSneakerCount(this.home.metas.description);
    }
  },
  methods: {
    ...mapActions(['showSearchBar', 'hideSearchBar']),
  },
  data() {
    return {
      home: {
        trending: [],
        shops: [],
        user_suggestions: [],
        metas: {},
        shopPopularProducts: [],
        shopRankProducts: [],
      }
    }
  },
  mounted() {
    //console.log('home', this.home);
  },
  async asyncData({app, $axios}) {
    try {
      let {data} = await $axios.get('home-v2', {params: {locale: app.i18n.locale}});

      const homeData = data;

      if(homeData.shopPopularProducts){
        homeData.shopPopularProducts = shuffle(homeData.shopPopularProducts);
        homeData.shopRankProducts = shuffle(homeData.shopRankProducts);
      }

      return {
        home: homeData,
      }
    } catch (e) {
      console.log('Home error:', e.response ? e.response.status : e);
    }

  }
}
</script>

<style lang="scss" scoped>
</style>

