var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loggedIn)?_c('header',{staticClass:"header",attrs:{"id":"header"}},[_c('div',{staticClass:"header-bg-img img_profile",style:(_vm.headerStyle)},[_c('div',{staticClass:"header-bg-mask"},[_c('h1',{staticClass:"header-name",domProps:{"innerHTML":_vm._s(_vm.user.name ? _vm.user.name : _vm.$t('Your name here'))}})]),_vm._v(" "),_c('camera-icon',{staticClass:"change-cover"})],1),_vm._v(" "),_c('div',{staticClass:"header-avatar"},[(_vm.cropperLoaded)?_c('avatar-cropper',{attrs:{"uploadHandler":_vm.uploadAvatar,"trigger":".change-photo","labels":{submit: 'Submit', cancel:'Cancel'}}}):_vm._e(),_vm._v(" "),_c('a',{staticClass:"header-avatar-img-link"},[_c('avatar',{staticClass:"header-avatar-img",attrs:{"src":_vm.user.avatar,"size":180,"username":_vm.avatarInitials}}),_vm._v(" "),_c('camera-icon',{staticClass:"change-photo"})],1)],1),_vm._v(" "),(_vm.cropperLoaded)?_c('avatar-cropper',{attrs:{"cropper-options":{
			aspectRatio: 1.5,
			autoCropArea: 1,
			viewMode: 1,
			movable: false,
			zoomable: false
			},"uploadHandler":_vm.uploadCover,"trigger":".change-cover","labels":{submit: 'Submit', cancel:'Cancel'}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }