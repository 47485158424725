<template>
    <div class="cookies-banner" v-if="!$store.state.cookiesAccept">
        <span class="cookies-banner-text">{{$t('We use Cookies')}}</span>
      <UiButton :click="() => $store.commit('acceptCookies')" color="primary">OK</UiButton>
    </div>
</template>

<script>
    import UiButton from "~/components/v2/ui/UiButton.vue";

    export default {
        name: "cookiesBanner",
      components: {UiButton}
    }
</script>