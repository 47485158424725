<script>
import {mapActions, mapMutations, mapState} from "vuex";
import UiLabelInput from "@/components/v2/ui/UiLabelInput.vue";
import UiSelect from "@/components/v2/ui/UiSelect.vue";
import {getCountryList} from "@/lib/utils";
import UiLabelCheckbox from "@/components/v2/ui/UiLabelCheckbox.vue";
import UiButton from "@/components/v2/ui/UiButton.vue";

export default {
  name: "my-account-form",
  components: {UiButton, UiLabelCheckbox, UiSelect, UiLabelInput},
  data() {
    return {
      errors: {
        email: {status: null, message: null},
        name: {status: null, message: null},
        last_name: {status: null, message: null},
        old_password: {status: null, message: null},
        new_password: {status: null, message: null},
        password_repeat: {status: null, message: null},
      },
      password1: true,
      password2: true,
      password3: true,
      passwords: {
        old_password: null,
        new_password: null,
        password_repeat: null,
      },
      successMessage: this.$t('Changes saved successfully!'),
      successUpdate: false,
      isLoading: false,
      subscription: {
        status: '',
        message: ''
      },
      subscriptionStatus: false,
      storeCurrency: this.$store.state.currencies,
    }
  },
  computed: {
    ...mapState(['currentRouteMetas', 'location']),
    ...mapState({storeUser: store=>store.auth.user}),
    getSubscriptionStatus() {
      return this.subscription.status === 'unsubscribed' || this.subscription.status === 'success' || this.subscription.status === 'fail';
    },

    currencies() {
      const result = [];
      for (let currency in this.storeCurrency) {
        result.push({value: currency, label: this.storeCurrency[currency]});
      }

      return result;
    },
    countries() {
      return getCountryList(this.$store.state.countryCodes)
    },
    user() {
      return {...this.storeUser}
    },
    inputClass() {
      return (name) => 'form-input ' + (
          this.errors[name] === undefined ? '' :
              this.errors[name].status === null ? '' :
                  (this.errors[name].message ? 'error' : 'valid'))
    },
    schemaBreadcrumbs() {
      return {}
    }
  },
  mounted() {
    this.subscriptionStatus = Boolean(this.user.newsletter);
  },
  methods: {
    ...mapActions(['getCountryInfo']),
    changeStatus(value) {
      this.subscription.status = value;
      if (value === 'unsubscribed')
        this.subscription.message = this.$t('unsubscription-success');
      else
        this.subscription.message = this.$t('subscription-error');
    },
    async toggleSubscription() {
      if (this.subscriptionStatus) {
        await this.$axios.post('mailchimp/subscribe', {'email': this.storeUser.email})
            .then((response) => {
              this.$auth.fetchUser()
              this.subscription.status = 'success';
              this.subscription.message = this.$t('subscription-success');
            })
            .catch((event) => {
              if (event.response && (event.response.status === 422 || event.response.status === 500)) {
                this.subscription.status = 'fail';
                this.subscription.message = this.$t('subscription-error');
              }
            });
      } else {
        this.$axios.post('mailchimp/unsubscribe', {'email': this.storeUser.email})
            .then((response) => {
              this.$auth.fetchUser()
            })
            .catch((e) => {
            });
      }
    },
    countryName(country) {
      let name = country.translations[this.$i18n.locale.toLowerCase()];
      return name ? name : country.name
    },
    async submitForm() {
      if (!this.checkForm()) {
        return
      }
      this.successUpdate = false;
      this.isLoading = true;
      this.$nuxt.$loading.start();
      try {
        let response = await this.$axios.post('profile', this._.merge(this.user, this.passwords, {locale: this.$i18n.locale}));

        await this.$auth.fetchUser();
        this.successUpdate = true;
        this.$store.dispatch('getCountryInfo', this.$store.state.auth.user.country)
        this.$store.commit('setCurrency', this.$store.state.auth.user.currency)
      } catch (error) {
        console.log('Account: ' + error)
        this._.each(error.response.data.errors, (message, input) => {
          if (!(input in this.errors)) {
            this.errors[input] = {}
          }
          this.errors[input].status = true;
          this.errors[input].message = this._.join(message, '<br>');
        })
      }
      this.$nuxt.$loading.finish();
      this.isLoading = false;
    },
    checkConfirmPassword(val) {
      if (val !== this.passwords.new_password) {
        this.errors.password_repeat.status = true;
        this.errors.password_repeat.message = this.$t('Please enter equal password');
      } else {
        this.errors.password_repeat.status = false;
        this.errors.password_repeat.message = '';
      }
    },
    checkNewPassword(val) {
      if (val === null) return;
      this.errors.new_password.status = (!val || val.length < 6);
      this.errors.new_password.message = this.errors.new_password.status === true ?
          (val ? this.$t('Please enter at least 6 characters') : this.$t('Please enter your password')) : null;
    },
    checkForm() {
      for (let err in this.errors) {
        if (this.errors[err].status) {
          return false
        }
      }
      return true
    }
  },
  watch: {
    'user.email': function (val) {
      this.checkEmail(val);
    },
    'passwords.new_password': function (val) {
      this.checkNewPassword(val);
    },
    'passwords.password_repeat': function (val) {
      this.checkConfirmPassword(val);
    },
    'subscriptionStatus': function (val) {
      this.toggleSubscription({target: {checked: val}});
    }
  }
}
</script>

<template>
  <form method="post" @submit.prevent="submitForm" autocomplete="off">
    <div class="flex flex-col gap-2 p-8">
      <div class="text-xl font-bold text-black">
        {{$t('Personal')}}
      </div>
      <ui-label-input :label="$t('E-Mail')" v-model="user.email" :error="errors.email"></ui-label-input>
      <ui-label-input :label="$t('Name')" v-model="user.name" :error="errors.name"></ui-label-input>
      <ui-label-input :label="$t('Last name')" v-model="user.last_name" :error="errors.last_name"></ui-label-input>
      <div class="flex flex-col mb-2">
        <label class="text-sm text-gray-high mb-1">{{ $t('Country') }}</label>
        <ui-select :items="countries.map(country => ({value: country.code, label: $t(country.name)}))"
                   :click="(selected) => user.country = selected"
                   color="white"
                   :init-value="user.country"
                   :paper-height="220"
                   size="md"></ui-select>
      </div>
      <div class="flex flex-col mb-2">
        <label class="text-sm text-gray-high mb-1">{{ $t('Favorite Currency') }}</label>
        <ui-select :items="currencies"
                   :click="(selected) => user.currency = selected"
                   color="white"
                   :init-value="user.currency"
                   :paper-height="220"
                   size="md"></ui-select>
      </div>

      <div v-if="user.has_password" class="flex flex-col gap-2">
        <div class="text-xl font-bold text-black mt-4">
          {{$t('Change Password')}}
        </div>
        <UiLabelInput :label="$t('Current Password')" v-model="passwords.old_password" type="password"
                      :error="errors.old_password"></UiLabelInput>
        <UiLabelInput :label="$t('New Password')" v-model="passwords.new_password" type="password"
                      :error="errors.new_password"></UiLabelInput>
        <UiLabelInput :label="$t('Repeat New Password')" v-model="passwords.password_repeat" type="password"
                      :error="errors.password_repeat"></UiLabelInput>
      </div>


      <div class="text-xl font-bold text-black mt-4">
        {{$t('Newsletter')}}
      </div>
      <div>
        <p class="text-sm text-black">
          {{$t('Get all your news and infos about upcoming sneaker releases and discounts in just one monthly email')}}
        </p>
      </div>
      <UiLabelCheckbox v-model="subscriptionStatus" :checked="subscriptionStatus" color="black">{{$t('subscribe-newsletter')}}</UiLabelCheckbox>
    </div>

    <div class="flex justify-center p-8 flex-col">
      <div class="w-full mb-4" v-if="this.successUpdate">
        <div class="text-green-500 text-center mt-4">
          {{successMessage}}
        </div>
      </div>
      <ui-button type="submit" :disabled="isLoading" block>{{$t('Save Changes')}}</ui-button>
    </div>
  </form>
</template>

<style scoped>

</style>