<script>
import Swiper from "swiper";

export default {
  name: "home-square-image",
  props: {
    k: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'left'
    },
    image: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      imageWrapper: 'imageWrapper' + this.k,
      imageWrapperTop: 0,
      imageWrapperHeight: 0,
      scrollY: 0
    }
  },
  methods: {
    throttleScroll(){
      this.scrollY = window.scrollY;
    }
  },

  mounted() {

    this.imageWrapperTop = this.$refs[this.imageWrapper].offsetTop
    this.imageWrapperHeight = this.$refs[this.imageWrapper].clientHeight

    this.$nextTick(()=>{
      window.addEventListener('scroll', this.throttleScroll);
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.throttleScroll);
  },
}
</script>

<template>
  <div
      :ref="imageWrapper"
      :class="{
    'w-full lg:basis-1/2 aspect-square border-[#131416] bg-cover bg-center bg-no-repeat ': true,
    'transition-[background-image] duration-500': true,
    'border-r': position === 'left',
    'border-l': position === 'right',
  }" :style="{
    backgroundImage: `url(${image})`,
    backgroundPositionY: this.scrollY < this.imageWrapperTop ? '0px' : `${(this.scrollY - this.imageWrapperTop) / 2}px`
  }"></div>
</template>

<style scoped>

</style>