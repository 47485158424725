<template>
  <div class="wrap">
	<button class="tranding-button">
	  <svg class="button-svg" width="12px" height="15px" viewBox="0 0 12 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g><path d="M3.25931511,5.06388548 C3.37598152,3.61536416 3.37598152,3.61536416 3.10937638,3.44477024 L2.8594074,3.27439446 L2.60943842,3.41073871 C2.25965247,3.59813025 1.99304733,3.88805266 1.74307834,4.14350725 L1.70980602,4.17775693 C-0.139793812,6.17165525 -0.539701526,9.37585421 0.760051864,11.7960192 C1.80962299,13.7388702 3.89255564,15 6.00876062,15 C6.09215471,15 6.17533551,15 6.24209344,15 C8.42484306,14.8976873 10.5244119,13.4321502 11.4408937,11.335721 C11.9574678,10.1769039 12.1240427,8.86450873 11.9073461,7.65442624 C11.6573771,6.22292068 10.8741978,4.8252285 9.54117212,3.35969142 C9.09135592,2.86536171 8.62490356,2.40528167 8.15823792,1.96199924 C7.59175531,1.41662224 7.00863655,0.837213716 6.49206242,0.240789426 C6.34191041,0.0704136511 6.14206319,-0.014883312 5.92536653,0.00213245045 C5.70888316,0.0191482129 5.50882266,0.138476701 5.39215625,0.325868239 C4.59234082,1.62124769 4.57570466,3.461786 5.34224777,5.11493277 C5.40900569,5.26851093 5.47555034,5.40485518 5.55894443,5.55821519 C5.65876139,5.76262249 5.75879164,5.95023218 5.82554957,6.13762372 C6.0586691,6.68300072 6.02539678,7.27942501 5.7754278,7.75673896 C5.4921865,8.26808444 4.95897621,8.52375718 4.37585745,8.42144445 C3.90919181,8.35316325 3.29280072,7.99539594 3.1426487,7.27942501 C3.12601254,7.16031467 3.10937638,7.04098619 3.12601254,6.87039226 C3.15949814,6.23993645 3.22604279,5.59224672 3.25931511,5.06388548 Z"></path></g>
	  </svg>
	  <span class="button-text">Trending</span>
	</button>
  </div>
         
</template>

<script>

    export default {
		name : 'Trending'
    }
</script>
