<template>
  <div>
    <p class="compare-slogan">
        {{ $t('compare-slogan', {minPrice: minPrice, maxPrice: maxPrice}) }}
    </p>
    <!-- For Desktop -->
    <ul class="compare-product-list d-lg-flex d-none">
        <li v-for="related in sneakers.related.slice(0, 5)" :key="related.id">
            <div class="custom_tooltip">
                <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : related.slug} })">
                    <img :src="related.thumbnail_url" :alt="related.name" width="55" height="55" v-if="related.thumbnail_url !== '' || related.thumbnail_url !== null">
                    <img src="~/assets/img/sneakers123-thumb.jpg" width="55" height="55" v-else>
                </nuxt-link>
                <div class="custom-tooltip-body top">
                    {{ `${related.name} from €${related.price_eur}` }}
                </div>
            </div>
            <!-- <div :title="`${related.name} from €${related.price_eur}`">
                <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : related.slug} })">
                    <img :src="related.thumbnail_url" :alt="related.name" width="55" height="55" v-if="related.thumbnail_url !== '' || related.thumbnail_url !== null">
                    <img src="~/assets/img/sneakers123-thumb.jpg" width="55" height="55" v-else>
                </nuxt-link>
            </div> -->
        </li>

        <li>
            <button class="more-compare-button" v-b-modal.compare-more-modal>
                <svg fill="#e3e3e3" height="35px" width="35px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-4.81 -4.81 41.67 41.67" xml:space="preserve" stroke="#e3e3e3">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    <g id="SVGRepo_iconCarrier"> <g> <path d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967 C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967 s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967 c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"/> </g> </g>
                </svg>
            </button>
        </li>
    </ul>
    <!-- For Tablets -->
    <ul class="compare-product-list d-lg-none d-md-flex d-none">
        <li v-for="related in sneakers.related.slice(0, 5)" :key="related.id">
            <div class="custom_tooltip">
                <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : related.slug} })">
                    <img :src="related.thumbnail_url" :alt="related.name" width="35" height="35" v-if="related.thumbnail_url !== '' || related.thumbnail_url !== null">
                    <img src="~/assets/img/sneakers123-thumb.jpg" width="35" height="35" v-else>
                </nuxt-link>
                <div class="custom-tooltip-body top">
                    {{ `${related.name} from €${related.price_eur}` }}
                </div>
            </div>
            <!-- <div :title="`${related.name} from €${related.price_eur}`">
                <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : related.slug} })">
                    <img :src="related.thumbnail_url" :alt="related.name" width="35" height="35" v-if="related.thumbnail_url !== '' || related.thumbnail_url !== null">
                    <img src="~/assets/img/sneakers123-thumb.jpg" width="35" height="35" v-else>
                </nuxt-link>
            </div> -->
        </li>

        <li>
            <button class="more-compare-button" style="width: 35px; height: 35px;" v-b-modal.compare-more-modal>
                <svg fill="#e3e3e3" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-4.81 -4.81 41.67 41.67" xml:space="preserve" stroke="#e3e3e3">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    <g id="SVGRepo_iconCarrier"> <g> <path d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967 C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967 s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967 c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"/> </g> </g>
                </svg>
            </button>
        </li>
    </ul>
    <b-modal id="compare-more-modal" :title="$t('similar-models-text')" hide-footer scrollable>
        <ul class="more-compare-list">
            <li v-for="related in sneakers.related" :key="related.id">
                <nuxt-link no-prefetch :to="localePath({name: 'product', params : { product : related.slug} })">
                    <div class="image">
                        <img :src="related.thumbnail_url" width="65" height="65" :alt="related.name">
                    </div>
                    <div class="info">
                        <div class="name"> {{ related.name }} </div>
                        <div class="price"> &euro; {{ related.price_eur }} </div>
                    </div>
                </nuxt-link>
            </li>
        </ul>
    </b-modal>
  </div>
</template>

<script>
export default {
    name: "CompareProduct",
    props: {
        sneakers: {
            default: {},
            type: Object,
        }
    },
    data() {
        return {
            minPrice: 0,
            maxPrice: 0,
        }
    },
    mounted() {
        if (this.sneakers.related.length){
            this.minPrice = this.sneakers.related[0].price_eur;
            this.maxPrice = this.sneakers.related[0].price_eur;
            for (const product of this.sneakers.related) {
                if (product.price_eur < this.minPrice) {
                    this.minPrice = product.price_eur;
                }
                if (product.price_eur > this.maxPrice) {
                    this.maxPrice = product.price_eur;
                }
            }
        }
    }
}
</script>
