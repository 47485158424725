<template>
    <li class="product-thumbnail-item">
        <a target="_blank" rel="nofollow" :href="localePath({name: 'new-go', query: goProductQuery })">
            <b-row class="product-wrapper m-0">
                <b-col cols="1" md="1" class="p-0 d-md-block d-none">
<!--                    <a :href="product.shop.cashback_redirect_url" target="_blank" class="cash-back-label" v-if="!(_.isEmpty(product.shop.cashback_amount_percentage) && _.isEmpty(product.shop.cashback_redirect_url))">-->
<!--                        <span> {{ $t('cashback-partner-text') }} </span>-->
<!--                    </a>-->
                    <a v-if=" ['Highlighted', 'Top'].includes(product.shop.position)" :href="product.shop.cashback_redirect_url" target="_blank" class="cash-back-label featured">
                        <span> {{ $t('featured-text') }} </span>
                    </a>
                </b-col>
                <b-col cols="3" md="2" class="d-flex align-items-center justify-content-md-center justify-content-start flex-column py-4">
                    <client-only>
                        <img class="shop-logo img-fluid mb-3" style="width: 100%;" width="100" height="100" v-lazy-load :data-src="product.shop.logo" :alt="product.shop.name + ' logo'" />
                    </client-only>
                    <h5 class="text-center mb-0" style="font-size: 10px;"> {{ product.shop.name }} </h5>

                    <!-- <div class="d-md-none d-block mobile-cashback" v-if="!(_.isEmpty(product.shop.cashback_amount_percentage) && _.isEmpty(product.shop.cashback_redirect_url))">
                        <span> {{ product.shop.cashback_amount_percentage }}% </span>
                        <span>CASHBACK</span>
                    </div> -->
                </b-col>
                <b-col :cols="product.available ? '6' : '8'" md="5">
                    <div class="main">
                        <div class="heading-1"> {{ sneaker.brand }}  {{ sneaker.name }} </div>
                        <!-- <div class="main-top">
                            <div class="coupon-info" :class="{countdown: isCountDown}" v-if="!_.isEmpty(product.shop.vouchers)">
                                <span v-if="product.shop.vouchers[0].type === 'Percentage'">
                                    {{ $t('voucher-code-percentage', {code: product.shop.vouchers[0].code, amount: product.shop.vouchers[0].amount }) }}
                                </span>
                                        <span v-else-if="product.shop.vouchers[0].type === 'Specific Amount'">
                                    {{ $t('voucher-code-currency', {
                                        code: product.shop.vouchers[0].code,
                                        amount: product.shop.vouchers[0].amount,
                                        currency: product.shop.vouchers[0].currency }) }}
                                </span>
                                        <span v-else-if="product.shop.vouchers[0].type === 'Shipping'">
                                        {{ $t('voucher-code-shipping', {code: product.shop.vouchers[0].code, amount: product.shop.vouchers[0].amount}) }}
                                        </span>
                            </div>
        
                        </div> -->
                        <div class="main-middle">
                            <div class="info mb-md-0 mb-3" style="display: flex;align-items: center;" >
                                <div style="width:5px;height:5px;border-radius: 50%;margin-right: 5px;" :style="$moment().diff($moment(product.last_check), 'days') <= 2 ? 'background-color:green' : $moment().diff($moment(product.last_check), 'days') > 4? 'background-color:red': 'background-color:orange' ">
                                </div>
                                <span class="last-check">{{ $t('last-time-check', {time: product.last_check_human}) }}</span>
                            </div>
                            <!-- v-if="$store.state.auth.loggedIn" -->
                            <div class="sizes mb-md-0 mb-3" v-if="!_.isEmpty(product.sizes)">
                                <div class="avaialble-sizes"> {{ $t('available-sizes-text') }} </div>
                                <ul class="available-sizes">
                                    <li v-for="(size,i) in product.sizes[$store.state.sizeFormat]" :key="i"
                                        :class="{selected: selectedSizes(size)}" v-html="sizeHtml(size)"></li>
                                </ul>
                            </div>
                            <div v-else class="sizes mb-md-0 mb-3">
                                <div class="avaialble-sizes"> {{ $t("no-size-available") }} </div>
                            </div>
                        </div>
                        <div class="main-bottom">
                            
                            <div class="payment-options mt-2" v-if="featured && !_.isEmpty(product.shop.payment_options)">
                                <div class="avaialble-sizes">{{$t('Payment options')}}:</div>
                                <div class="bottom-mid">
                                    <payment :methods="product.shop.payment_options"></payment>
                                </div>
                            </div>
                            <coupon :voucher="!_.isEmpty(product.shop.vouchers) ? product.shop.vouchers[0] : null" :isCountDown="isCountDown"></coupon>
                        </div>
                    </div>
                </b-col>
                <b-col cols="3" md="4">
                    <div class="d-flex align-items-center justify-content-center h-100">
                        <price :product="product" v-if="product.available"></price>
                        <div :class="noCase(status)" class="m-0 d-md-block d-none" v-if="!featured && status !== 'available'">{{$t(status) | titleCase}}</div>
                    </div>
                    <!--  -->
<!--                    <div class="cashback-wrapper d-md-block d-none">-->
<!--                        <div class="cashback-tag" v-if="!(_.isEmpty(product.shop.cashback_amount_percentage) && _.isEmpty(product.shop.cashback_redirect_url))">-->
<!--                            <span> {{ product.shop.cashback_amount_percentage }}% </span>-->
<!--                            <span>{{ $t('cashback-text') }}</span>-->
<!--                            <span class="tooltip-wrapper">-->
<!--                                <div class="tooltip-trigger">-->
<!--                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" baseProfile="tiny" version="1.2" viewBox="0 0 24 24">-->
<!--                                        <path d="M13.839 17.525c-.006.002-.559.186-1.039.186-.265 0-.372-.055-.406-.079-.168-.117-.48-.336.054-1.4l1-1.994c.593-1.184.681-2.329.245-3.225-.356-.733-1.039-1.236-1.92-1.416a4.776 4.776 0 0 0-.958-.097c-1.849 0-3.094 1.08-3.146 1.126a.5.5 0 0 0 .493.848c.005-.002.559-.187 1.039-.187.263 0 .369.055.402.078.169.118.482.34-.051 1.402l-1 1.995c-.594 1.185-.681 2.33-.245 3.225.356.733 1.038 1.236 1.921 1.416.314.063.636.097.954.097 1.85 0 3.096-1.08 3.148-1.126a.5.5 0 0 0-.491-.849z"></path>-->
<!--                                        <circle cx="13" cy="6.001" r="2.5"></circle>-->
<!--                                    </svg>-->
<!--                                    <div class="tooltip">-->
<!--                                        {{ product.shop.name }} provides up to {{ product.shop.cashback_amount_percentage }}% cashback on <a target="_blank" href="http://Sneakercashback.com"> <strong>SNEAKERCASHBACK.COM</strong> </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </div>-->
                    <!--  -->
                </b-col>
                <b-col cols="12"
                    class="d-flex align-items-end mb-3 d-md-none d-block"
                    v-if="product.available || (!(_.isEmpty(product.shop.cashback_amount_percentage) && _.isEmpty(product.shop.cashback_redirect_url)))"
                    :class="!(_.isEmpty(product.shop.cashback_amount_percentage) && _.isEmpty(product.shop.cashback_redirect_url)) ? 'justify-content-between' : 'justify-content-end'">
<!--                    <div class="mobile-cashback" v-if="!(_.isEmpty(product.shop.cashback_amount_percentage) && _.isEmpty(product.shop.cashback_redirect_url))">-->
<!--                        <span> {{ product.shop.cashback_amount_percentage }}% </span>-->
<!--                        <span>{{ $t('cashback-text') }}</span>-->
<!--                    </div>-->
                    <div v-if="product.available">
                        <a target="_blank"
                            :href="localePath({name: 'new-go', query: goProductQuery })"
                            class="buy-now-btn text-uppercase d-flex align-items-center">
                            {{$t('shop-now-text')}}
                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#ffffff"></path> </g></svg>
                        </a>
                    </div>
                </b-col>
                <b-col cols="12" class="d-md-none d-flex align-items-center justify-content-end mb-3" v-if="!featured && status !== 'available'">
                    <div :class="noCase(status)" class="m-0">{{$t(status) | titleCase}}</div>
                </b-col>
            </b-row>
        </a>
    </li>
</template>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7829825856881098"
    crossorigin="anonymous"></script>
    <ins class="adsbygoogle"
    style="display:block"
    data-ad-format="fluid"
    data-ad-layout-key="-h3-s-2x-ax+101"
    data-ad-client="ca-pub-7829825856881098"
    data-ad-slot="6578522698"></ins>
<script>
 (adsbygoogle = window.adsbygoogle || []).push({});
</script>  

<script>
    import {mapState, mapGetters} from 'vuex';
    import Price    from "./Price";
    import Payment  from "./Payment";
    import Coupon   from "./Coupon";
    import GoButton from "./GoButton";
    import redirectMixin from '../../plugins/redirectMixin.js'

    export default {
        watch: {
        },
        props: ['product', 'featured'],
        components: {GoButton, Coupon, Payment, Price},
        mixins: [redirectMixin],
        computed: {
            lnk() {
                return this.product.shop_url
            },
          lnkProduct() {

            return {
              url: this.product.shop_url,
              productId: this.product.snkProductId || this.product.id,
            }
          },
            url() {
                return this.product.url
            },
            partner() {
                return this.product.shop.name
            },
            status()
            {
                if (this.product.available)
                    return 'available';
                if (this.product.coming_soon)
                    return 'coming soon';
                if (this.product.sold_out)
                    return 'sold out';
            },
            isCountDown() {
                return (
                    this.product.shop.countdowns.length > 0 &&
                    this.product.shop.countdowns[0].is_active &&
                    (this.product.shop.countdowns[0].brand === this.$parent.$parent.$parent.$parent.sneaker.brand ||
                        this.product.shop.countdowns[0].release_id === this.$parent.$parent.$parent.$parent.sneaker.id)
                )
            },
            countDownExpiration() {
                return this.isCountDown ?  this.product.shop.countdowns[0].expiration : false;
            },
            sneaker () {
                return this.$parent.$parent.$parent.$parent.sneaker;
            }
        },
        methods   : {
            selectedSizes(size)
            {
                let selectedSizes = this.$parent.$parent.$parent.$parent['sizes_' + this.$store.state.sizeFormat];
                if (selectedSizes)
                    return selectedSizes.includes(size);
                return false;
            },
            noCase(w)
            {
                return this._.replace(this._.lowerCase(w), ' ', '')
            }
            // redirectUp()
            // {
            //     this.$router.push({name: 'redirecting', query: {redirect: this.lnk}});
            // }
        },
        filters   : {},
    }
</script>

<style>
.featured{
    background-color: #E43241;
}
</style>

<style scoped>
.col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

@media(max-width: 767px){
    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
</style>