<template>
    <svg height="0" width="0" class="content-placeholder">
        <defs>
            <clipPath id="svgPath">
                <rect x="0" y="0" rx="0" ry="0" width="68" height="68" />
                <rect x="74" y="15" width="200" height="12"></rect>
                <rect x="74" y="35" width="150" height="12"></rect>
                <rect x="0" y="75" width="320"

            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "suggestionSwipperPlaceholder"
    }
</script>

<style scoped>

</style>