<script>
import {mapGetters} from "vuex";
import UiButton from "../../../ui/UiButton.vue";
import redirectMixin from "../../../../../plugins/redirectMixin";

export default {
  name: "release-product-info",
  components: {UiButton},
  mixins: [redirectMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    release: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['currency']),
    lnkProduct() {
      return {
        url: this.product.shop_url,
        productId: this.product.id,
      }
    },
    getProductPrice() {
      let currency = this.currency;

      let convertedPrice = this.product['price_' + currency.toLowerCase()];
      if (convertedPrice != false || convertedPrice != undefined)
        return this.$root.price(convertedPrice, currency);

      return '-';
    },

    getSalePrice() {
      let currency = this.currency;
      let convertedSalePrice = this.product['sale_price_' + currency.toLowerCase()];
      if (convertedSalePrice != false || convertedSalePrice != undefined)
        return this.$root.price(convertedSalePrice, currency);

      return '-';
    },
  },
  methods: {

    discount(price, salePrice) {
      return Math.round(((price - salePrice) / price) * 100) + "%";
    },
  }
}
</script>

<template>
  <div class="flex flex-col p-4 lg:p-8">
    <h1 class="text-black text-base font-semibold">{{release.brand}} {{release.name}}</h1>
    <div class="flex flex-col w-full mt-2" v-if="product.id">
      <h2 class="text-gray-high text-xs line-through mb-1" v-if="product.sale_price">
        {{getProductPrice}}
      </h2>
      <div class="text-base font-semibold flex flex-row">
        <h2 class="text-danger mr-1" v-if="product.sale_price">{{discount(product.price, product.sale_price)}}</h2>
        <h2 class="text-black">{{product.sale_price ? getSalePrice : getProductPrice}}</h2>
      </div>
      <div class="text-xs text-black mt-3">
        <h4>From. {{product.shop.name}}</h4>
      </div>
      <div class="flex flex-col mt-3 w-full">
        <UiButton :to="localePath({name: 'new-go',query: goProductQuery })" class="w-full" color="primary" size="lg" height="lg" font-size="sm" target="_blank" block underline :bold="false">{{$t('label.buyDirect')}}</UiButton>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>