var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full"},[_c('ui-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_vm._v(" "),_c('div',{staticClass:"border-b border-black py-[72px] text-center"},[_c('h1',{staticClass:"text-2xl font-bold text-font-line-color"},[_vm._v(_vm._s(_vm.$t('All shops')))])]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center py-4 justify-center gap-4 border-b border-black"},[_c('div',{staticClass:"flex flex-row items-center px-6 py-4 justify-center gap-4 w-full"},[_c('UiSelect',{attrs:{"click":(selected) => {
        this.queries.country = selected.value;
      },"width":300,"title":_vm._f("capitalize")((_vm.queries.country ? _vm.$t('countries-abbr.' + _vm.queries.country) : _vm.$t('All countries'))),"items":[
                      ...(_vm.queries.country ? [{label: _vm.$t('All countries'), value: ''}] : []),
                      ..._vm.countrySelectItems
                  ]}}),_vm._v(" "),_c('UiSelect',{attrs:{"click":(selected) => {
        this.queries.brand = selected.value;
      },"width":300,"title":_vm._f("titleCase")((_vm.queries.brand ? _vm.queries.brand : _vm.$t('All Brands'))),"items":[
                      ...(_vm.queries.brand ? [{label: _vm.$t('All Brands'), value: ''}] : []),
                      ..._vm.brandSelectItems
                  ]}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-row w-full flex-wrap"},_vm._l((_vm.shops),function(shop,i){return _c('shop-thumbnail',{key:i,attrs:{"shop":shop}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }