<script>
export default {
  name: "u-dialog-header",
  props: {
    title: {
      type: String,
      default: ""
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<template>
  <div class="u-dialog-header">
    <h3 class="u-dialog-title">
      {{title}}
      <button class="u-dialog-close" @click="onClose">
        <img src="~/assets/icons/ui/close-icon.svg" alt="close" />
      </button>
    </h3>
  </div>
</template>

<style scoped lang="scss">

</style>