<template>
    <a :href="getBannerLink" target="_blank" class=" col-6 col-md-3 col-xl-3 mp6 advertisement-margin" v-if="banner && banner.image" @click="increaseClicksNumber">
        <div :style="{ 'background-image': 'url(' + banner.image + ')'  }" class="advertisement-image">
        </div>
    </a>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        name    : "AdvertisementThumbnail",
        props   : ['banner'],
        computed: {
            getBannerLink()
            {
                if(this.banner.link.includes(process.env.appUrl)) 
                    return process.env.appUrl + this.$i18n.locale + this.banner.link.substring(process.env.appUrl.length + 3)
                return this.banner.link;
            }
        },
        mounted()
        {
            if (this.banner && this.banner.image)
                this.increaseViewsNumber();
        },
        methods : {
            async increaseViewsNumber()
            {
                try {
                    await this.$axios.post(`banners/${this.banner.id}/views/plus`);
                }
                catch (e) {
                    console.log('adv', e.response ? e.response.status : e)
                }
            },
            async increaseClicksNumber()
            {
                try {
                    await this.$axios.post(`banners/${this.banner.id}/clicks/plus`);
                }
                catch (e) {
                    console.log('adv', e.response ? e.response.status : e)
                }
            }
        },
        watch   : {
            'banner': function (val) {
                if (val && val.image)
                    this.increaseViewsNumber();
            },
        }
    }
</script>
