<template>
	<div class="release-favorite" @click.prevent="favClick">
		<div class="custom_tooltip">
			<img src="~/assets/icons/ui/heart.svg" alt="heart icon" class="heart-icon" v-if="!isFav" />
      <img src="~/assets/icons/ui/heart-fill.svg" alt="heart icon" class="heart-icon" v-else />
			<div class="custom-tooltip-body left">
				{{ isFav ? $t('remove-from-list-text') : $t('add-to-list-text') }}
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
  .release-favorite {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border: 1px solid #e0ecf5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .heart-icon {
      width: 14px;
      height: 12px;
      margin-bottom: 2px;
    }
  }
</style>

<script>
	import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

    export default {
		name: 'release-favorite',
		props: ['sneaker'],
		computed: {
			...mapState(['favorites', 'onceLoggedIn']),
			isFav() {
				return this._.indexOf(this.favorites, this.sneaker.slug) !== -1
			}
		},
		methods: {
			...mapActions(['addFavourites']),
			...mapMutations(['setChosenFavorite']),
			async favClick() {
				if (this.loggedIn) {
					this.setChosenFavorite(this.sneaker);
					try {
						await this.addFavourites(this.sneaker);
						if (this.isFav) {
							this.$root.$emit('bv::show::modal','favoriteModal');
							setTimeout( () => {
								this.$root.$emit('bv::hide::modal','favoriteModal');
							}, 5000);
						}
					}
					catch (e) {
						console.log(e);
					}
				} else {
					this.$router.push(this.localePath({
						name: this.onceLoggedIn ? 'login' : 'register'
					}))
				}

			}
		}
    }
</script>
