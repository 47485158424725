var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showGlobalDrawer)?_c('div',{staticClass:"fixed inset-0 bg-black bg-opacity-50 z-40",class:{
        'top-16': _vm.globalDrawerSection !== 'search' || _vm.$device.isDesktop,
        'top-0': _vm.globalDrawerSection === 'search' && !_vm.$device.isDesktop
      },on:{"click":_vm.closeGlobalDrawer}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"fixed w-full lg:w-1/3 right-0 h-full bg-white border-l border-black transform transition-transform duration-300 ease-in-out z-50",class:{
        'translate-x-0': _vm.showGlobalDrawer,
        'translate-x-full': !_vm.showGlobalDrawer,
        'top-16': _vm.globalDrawerSection !== 'search' || _vm.$device.isDesktop,
        'top-0': _vm.globalDrawerSection === 'search' && !_vm.$device.isDesktop,
        'max-h-header-without': _vm.globalDrawerSection !== 'search',
        'lg:max-h-header-without': _vm.globalDrawerSection === 'search'
    }},[(_vm.globalDrawerSection === 'menu')?_c('mobile-menu-drawer'):_vm._e(),_vm._v(" "),(_vm.globalDrawerSection === 'search')?_c('search-drawer'):(_vm.globalDrawerSection === 'setting')?_c('setting-drawer'):(_vm.globalDrawerSection === 'sign')?_c('sign-drawer'):(_vm.globalDrawerSection === 'forgot-password')?_c('forgot-password-drawer'):(_vm.globalDrawerSection === 'mypage')?_c('mypage-drawer'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }