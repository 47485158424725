<template>
    <section class="shops-list mt-4" id="shops-list">
        <!-- <div class="switch-filters line mb-3 d-flex justify-content-between align-items-center" v-show="notFeaturedCount" :style="{ width: '100%' }">
            <div>
                <h3  style="padding-bottom: 0px;" class="font-weight-bold">{{$t('Available at', {count: notFeaturedCount})}}</h3>
            </div>
        </div> -->
        <transition-group tag="ul" class="line" name="list">
            <template v-for="product in moveTopItemToTop(products)">
                <product-thumbnail :product="product" v-bind:key="product.id"></product-thumbnail>
            </template>
        </transition-group>
    </section>
</template>

<script>
    import ProductThumbnail       from "./ProductThumbnail";
    import {mapGetters, mapState} from "vuex";
    import ArrowRight from "../icons/arrow-right";
    import CloseIcon from "~/components/icons/close-icon";

    export default {
        name      : 'ShopsList',
        props: ['sneaker', 'onlyInStock', 'isHigherPrice'],
        components: {ArrowRight, ProductThumbnail, CloseIcon},
        data() {
            return {
                showSizeMenu: false,
            }
        },
        methods: {
            toggleSizeMenu() {
                this.showSizeMenu = !this.showSizeMenu;
            },
            moveTopItemToTop(items) {
                let topIndex = -1;
                items.findIndex((item, index) => {
                    if (item.shop.position !== 'None' && item.availability === "InStock"){
                        topIndex = index;
                        if (topIndex > 0) {
                            let topItem = items.splice(topIndex, 1);
                            items.unshift(topItem[0]);
                        }
                    }
                });
                return items;
            },
        },
        computed  : {
            shopsAvailable() {
                return this.$parent.$parent.featured.length + this.$parent.$parent.notFeatured.length;
            },
            notFeaturedCount() {
                return this.$parent.$parent.notFeatured.length;
            },
            products () {
                let array = this.$parent.$parent.notFeatured;
                if (this.onlyInStock){
                    array = array.filter(item => item.availability === "InStock")
                }
                if (this.isHigherPrice){
                    array.sort((a, b) => b.price - a.price);
                }
                if (this.isHigherPrice === false) {
                    array.sort((a, b) => a.price - b.price);
                }
                return array;
            }
            // listLength() {
            //     return this.$auth.loggedIn ? this.$parent.$parent.notFeatured.length : 8 - this.$parent.$parent.featured.length
            // },
        },
    }
</script>

<style>
ul.line{
    list-style: none;
    margin: 0;
    padding: 0;
}
</style>