<template>
  <img v-lazy-load class="search-svg svg" lazy-default="~/assets/icon-def.svg" data-src="~/assets/icons/search-icon.svg" src="~/assets/icon-def.svg" width="23" height="23" alt="icon" :style="{
    width: '23px !important',
    height: '23px !important'
  }">
</template>
<script>
    export default {
        name: 'search-icon',
    }
</script>