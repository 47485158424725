<script>
export default {
  name: "UiLabelCheckbox",
  props: {
    checked: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      default: "black"
    },
    error: {
      type: Object,
      default: () => {
        return {
          status: false,
          message: ""
        }
      }
    },
    onChange: {
      type: Function,
      required: false
    }
  },
  methods: {
    updateChecked(event) {
      this.$emit('input', event.target.checked);
      console.log(event.target.checked);
      if (this.onChange) {
        this.onChange(event.target.checked);
      }
    }
  }
}
</script>

<template>
  <div class="flex flex-col mb-2">
    <label class="flex items-center cursor-pointer">
      <input type="checkbox" class="hidden" :checked="checked" @change="updateChecked" />
      <!-- checkbox -->
      <span class="w-5 h-5 border border-black rounded-sm mr-2 flex items-center justify-center" :class="{
        'border border-black': !checked,
      'border border-black bg-black': checked && color === 'black',
      'border border-primary bg-primary': checked && color === 'primary',
    }">
      <svg class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
      </svg>
    </span>
    <span class="text-gray-high text-sm">
      <slot></slot>
    </span>
    </label>
    <div v-if="error.status" v-html="error.message" class="text-red-500 text-sm mt-1"></div>
  </div>
</template>

<style scoped>

</style>