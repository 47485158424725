<script>
import {mapActions, mapState} from "vuex";
import SearchDrawer from "../sections/drawers/search-drawer.vue";
import SettingDrawer from "../sections/drawers/setting-drawer.vue";
import SignDrawer from "../sections/drawers/sign-drawer.vue";
import ForgotPasswordDrawer from "../sections/drawers/forgot-password-drawer.vue";
import MypageDrawer from "../sections/drawers/mypage-drawer.vue";
import MobileMenuDrawer from "@/components/v2/sections/drawers/mobile-menu-drawer.vue";

export default {
  name: "global-drawer",
  components: {MobileMenuDrawer, MypageDrawer, ForgotPasswordDrawer, SignDrawer, SettingDrawer, SearchDrawer},
  created() {
    this.$root.$on('global-drawer-close', this.closeDrawer);
  },
  computed: {
    ...mapState(["showGlobalDrawer", "globalDrawerSection"]),
  },
  methods: {
    ...mapActions(["closeGlobalDrawer"]),
  },
}
</script>

<template>
  <div>
    <div
        v-if="showGlobalDrawer"
        @click="closeGlobalDrawer"
        class="fixed inset-0 bg-black bg-opacity-50 z-40"
        :class="{
          'top-16': globalDrawerSection !== 'search' || $device.isDesktop,
          'top-0': globalDrawerSection === 'search' && !$device.isDesktop
        }"
    ></div>
    <div
        :class="{
          'translate-x-0': showGlobalDrawer,
          'translate-x-full': !showGlobalDrawer,
          'top-16': globalDrawerSection !== 'search' || $device.isDesktop,
          'top-0': globalDrawerSection === 'search' && !$device.isDesktop,
          'max-h-header-without': globalDrawerSection !== 'search',
          'lg:max-h-header-without': globalDrawerSection === 'search'
      }"
        class="fixed w-full lg:w-1/3 right-0 h-full bg-white border-l border-black transform transition-transform duration-300 ease-in-out z-50"
    >

            <mobile-menu-drawer v-if="globalDrawerSection === 'menu'"></mobile-menu-drawer>
            <search-drawer v-if="globalDrawerSection === 'search'"></search-drawer>
            <setting-drawer v-else-if="globalDrawerSection === 'setting'"></setting-drawer>
            <sign-drawer v-else-if="globalDrawerSection === 'sign'"></sign-drawer>
            <forgot-password-drawer v-else-if="globalDrawerSection === 'forgot-password'"></forgot-password-drawer>
            <mypage-drawer v-else-if="globalDrawerSection === 'mypage'"></mypage-drawer>
    </div>
  </div>
</template>

<style scoped>

</style>