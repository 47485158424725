<script>
import {mapActions, mapMutations, mapState} from "vuex";
import UiTabs from "../../../v2/ui/UiTabs.vue";
import {getCountry, getCountryList} from "@/lib/utils";

export default {
  name: "setting-drawer",
  components: {UiTabs},
  computed: {
    currencies() {
      return [
        {code: "USD", name: this.$t('label.currencyCode.usd'), symbol: "$"},
        {code: "EUR", name: this.$t('label.currencyCode.eur'), symbol: "€"},
        {code: "GBP", name: this.$t('label.currencyCode.gbp'), symbol: "£"},
        {code: "JPY", name: this.$t('label.currencyCode.jpy'), symbol: "¥"},
      ]
    },

    country(){
      return getCountry(this.$store.state.location.code)
    },
    countries() {
      return getCountryList(this.$store.state.countryCodes)
    },
    currentLocaleName () {
      return this.availableLocales.find((l)=>{ return l.code === this.currentLocale}).name
    },
    currentLocale () {
      return this.$i18n.locale
    },
    availableLocales () {
      return this.$i18n.locales
    },
    currentCurrency() {
      const currency = this.$store.state.currency ? this.$store.state.currency.toUpperCase() : "EUR";
      let findItem = this.currencies.find((c) => c.code === currency);
      if(!findItem) {
        findItem = this.currencies[0];
      }
      return findItem
    },
  },
  data() {
    return {
      tabIndex: 0,
      isOverItem: false,
      countrySearch: ""
    }
  },
  methods: {
    ...mapMutations(["setCountry"]),
    ...mapActions(["closeGlobalDrawer"]),
    setCurrencyItem(currency) {
      this.$store.commit("setCurrency", currency);
      this.$router.go(0);
      this.closeGlobalDrawer();
      this.closeDrawer();
    },
    setCountryItem(country) {
      this.setCountry(country);
      this.$router.go(0);
      this.closeGlobalDrawer();
      this.closeDrawer();
    },
    setOverItem(value) {
      this.isOverItem = value;
    },
    setLocaleItem(locale) {
      this.$router.push(this.switchLocalePath(locale));
      this.closeGlobalDrawer();
      this.closeDrawer();
    },

    closeDrawer() {
      this.countrySearch = "";
      this.tabIndex = 0;
    },
  }
}
</script>

<template>
  <UiTabs :tabs="[$t('label.language'), $t('label.region'),$t('label.currency')]">
    <template #tab1>
      <div>
        <div class="px-4 py-6 text-center border-b border-black">{{$t('text.languageTitle')}}</div>
        <ul class="w-full max-h-[calc(100vh-150px)] overflow-y-auto pb-32">
          <li :class="['p-4 border-b border-black cursor-pointer', !isOverItem ? 'bg-gray-low' : '']">
            {{ currentLocaleName }}
            <div class="float-right mt-2">
              <img src="~/assets/images/ui/icons/check-icon.svg" alt="check" class="w-[16px] h-[10px]" />
            </div>
          </li>
          <li class="p-4 border-b border-black cursor-pointer hover:bg-gray-low"
              v-for="locale in availableLocales" :key="locale.code" v-show="locale.code !== $i18n.locale"
              @click="setLocaleItem(locale.code)"
              @mouseover="setOverItem(true)" @mouseleave="setOverItem(false)"
          >{{ locale.name }}</li>
        </ul>
      </div>
    </template>
    <template #tab2>
      <div class="flex flex-col h-screen pb-32">
        <div class="px-4 py-6 text-center border-b border-black">{{$t('text.countryTitle')}}</div>
        <div class="p-8">
          <div class="relative w-full">
            <input type="text" class="w-full p-2 pl-10 border border-black" v-model="countrySearch" placeholder="Search country" />
            <img src="~/assets/images/ui/icons/search.png" alt="search" class="absolute left-3 top-3 w-5 h-5" />
          </div>
        </div>
        <div class="h-full overflow-y-auto pb-32">
          <ul class="w-full">
            <li :class="['flex flex-row p-4 border-b border-black cursor-pointer', !isOverItem ? 'bg-gray-low' : '']"
                v-show="countrySearch === '' || $t(country.name).toLowerCase().includes(countrySearch.toLowerCase())"
            >
              <div class="flex flex-row flex-1 justify-start items-center">
                <img v-lazy-load :data-src="country.flag" :alt="country.code" class="w-8 h-8" />
                <span class="ml-2">{{ $t(country.name) }}</span>
              </div>
              <div class="flex flex-col justify-center items-center">
                <img src="~/assets/images/ui/icons/check-icon.svg" alt="check" class="w-[16px] h-[10px]" />
              </div>
            </li>
            <li class="p-4 border-b border-black cursor-pointer hover:bg-gray-low"
                v-for="c in countries" :key="c.code"
                v-show="c.code !== country.code && (countrySearch && $t(c.name).toLowerCase().includes(countrySearch.toLowerCase()) || !countrySearch)"
                @click="setCountryItem(c.code)"
                @mouseover="setOverItem(true)" @mouseleave="setOverItem(false)"
            >
              <div class="flex flex-row justify-start items-center">
                <img v-lazy-load :data-src="c.flag" :alt="c.code" class="w-8 h-8" />
                <span class="ml-2">{{ $t(c.name) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #tab3>
      <div>
        <div class="px-4 py-6 text-center border-b border-black">{{$t('text.currencyTitle')}}</div>
        <ul class="w-full h-full overflow-y-auto pb-32">
          <li :class="['p-4 border-b border-black cursor-pointer', !isOverItem ? 'bg-gray-low' : '']">
            {{ currentCurrency.symbol + ' ' + currentCurrency.name + ' (' + currentCurrency.code + ')'}}
            <div class="float-right mt-2">
              <img src="~/assets/images/ui/icons/check-icon.svg" alt="check" class="w-[16px] h-[10px]" />
            </div>
          </li>
          <li class="p-4 border-b border-black cursor-pointer hover:bg-gray-low"
              v-for="currency in currencies" :key="currency.code"
              v-show="currency.code !== currentCurrency.code" @click="setCurrencyItem(currency.code)"
              @mouseover="setOverItem(true)" @mouseleave="setOverItem(false)"
          >{{ currency.symbol + ' ' + currency.name + ' (' + currency.code + ')' }}</li>
        </ul>
      </div>
    </template>
  </UiTabs>
</template>

<style scoped>

</style>