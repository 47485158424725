<template>
		<div @click.prevent="click" class="cursor-pointer rounded-full flex items-center justify-center">
      <img src="~/assets/images/ui/icons/bell.png" alt="bell icon" class="w-6 h-6" v-if="!alert" />
      <img src="~/assets/images/ui/icons/bell-fill.png" alt="bell icon" class="w-6 h-6" v-else />
		</div>
</template>

<style scoped>
</style>

<script>
	import {mapMutations, mapActions} from 'vuex'

	export default {
		name : 'release-alert',
		props: ['sneaker'],
		computed: {
			alert() {
				return  this._.indexOf(this.$store.state.notifications, this.sneaker.slug) !== -1
			}
		},
		methods: {
			...mapMutations(['setChosenForNotify']),
            ...mapActions(['removeNotification']),
      ...mapActions(['openPriceAlertDialog']),
			async click() {
				if (!this.alert) {
					this.setChosenForNotify(this.sneaker);
					this.openPriceAlertDialog();
				} else {
					try {
                        this.removeNotification(this.sneaker.slug);
					}
					catch (e) {
						console.log(e);
					}
				}
			}
		}
    }
</script>
