<script>
import {mapActions} from "vuex";

export default {
  name: "HomeSearchSection",
  props: {
    scrollY: {
      type: Number,
      default: 0
    }
  },
  computed: {
    backgroundPositionY() {
      return `${this.scrollY / 2}px`
    }
  },
  methods: {
    ...mapActions(["openGlobalDrawer"]),
  }
}
</script>

<template>
  <section
      class="relative flex flex-col items-center justify-center h-[468px] w-full bg-cover bg-center bg-no-repeat home-search-section-bg"
      :style="{backgroundPositionY}"
  >
    <div class="absolute inset-0 bg-black bg-opacity-40"/>
    <div class="relative z-10 flex flex-col items-center justify-center w-full px-5">
      <h1 class="text-4xl font-semibold text-white text-center uppercase leading-[1.25] tracking-[-0.8px] lg:w-[480px]" v-html="$t('home-header-title')" />
      <h2 class="text-lg text-white text-center mb-10">{{$t('Search over 100 sneaker shops at once')}}</h2>
      <div
          class="flex items-center justify-between w-full max-w-[560px] h-12 border border-[#131416] bg-white pl-2.5">
        <div
               class="w-[calc(100%-40px)] h-full border-none outline-none text-base text-gray-high cursor-text px-3 flex items-center"
            @click="() => openGlobalDrawer('search')"
        >
          {{$t('placeholders.searchbar', {total: $store.state.totalSneakersCount})}}
        </div>
        <button class="flex items-center justify-center w-10 h-full bg-white cursor-pointer">
          <img src="~/assets/images/ui/icons/search.png" alt="Search Button" class="w-5 h-5"/>
        </button>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.home-search-section-bg{
  background-image: url('~assets/images/ui/background/home-search-bg.png');
}
</style>
