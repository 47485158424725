<script>
import USelect from "~/components/ui/select.vue";
import UButton from "~/components/ui/button.vue";
import LazyImg from "~/components/LazyImg.vue";

export default {
  name: "product-thumbnail",
  components: {UButton, USelect, LazyImg},
  props: {
    product: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      isLike: false,
      currentImageIndex: 0,
    }
  },
  computed: {
    images() {
      const arr = [];

      if(this.product.coverImageUrl)
        arr.push(this.product.coverImageUrl);

      if(this.product.imageUrls) {

        for (let i = 0; i < this.product.imageUrls.length; i++) {
          arr.push(this.product.imageUrls[i]);
        }
      }

      return arr;
    },
  },
  methods: {
    thumbFromImage(url)
    {
      return url;
    },
    selectImage(index) {
      this.currentImageIndex = index;
    }
  }
}
</script>

<template>
  <div class="product-thumbnail">
    <div class="product-thumbnail-space" />
    <div class="product-thumbnail-image-wrap">
      <client-only>
      <img v-lazy-load :data-src="images[currentImageIndex]" alt="product image" />
      </client-only>
    </div>
<!--    <div class="product-thumbnail-like">-->
<!--      <img :src="isLike ? '/assets/icons/ui/heart-fill.svg' : '/assets/icons/ui/heart.svg'" alt="like" />-->
<!--    </div>-->
    <div class="product-thumbnail-image-selector">

      <client-only>

      </client-only>
      <template v-for="(img, index) in images">
        <div class="product-thumbnail-image-selector-item"
             :class="{'current-image': currentImageIndex == index}"@click="selectImage(index)">
          <img v-lazy-load :data-src="thumbFromImage(img)" alt="product image" class="product-thumbnail-image-selector-item-img"/>
<!--          <LazyImg :src="thumbFromImage(img)" alt="product image"  img-class="product-thumbnail-image-selector-item-img" />-->
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-thumbnail {
  flex: 2;
  background-color: #fff;
  padding: 20px;
  height: 100%;
  width: 100%;
  border: 1.5px solid #dfe6f0;
  border-radius: 20px;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  .product-thumbnail-like{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    border: 1.5px solid #e0ecf5;
  }


  .product-thumbnail-image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 84px;
    img {
      max-width: 600px;
      max-height: 600px;
    }
  }


  .product-thumbnail-image-selector {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .product-thumbnail-image-selector-item {
      width: 64px;
      height: 64px;
      border: 1.5px solid #dfe6f0;
      border-radius: 8px;
      margin-right: 12px;
      cursor: pointer;
      background-color: #fff;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      padding: 3px;

      &.current-image {
        border: 1.5px solid #23d1d8;
      }

      .product-thumbnail-image-selector-item-img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  /** mobile **/
  @media (max-width: 1023px) {
    flex: 1;
    margin-left: 0;
    border-radius: 0;
    padding: 0;
    border: none;
    .product-thumbnail-image-wrap {
      flex: 7;
      border-radius: 16px;
      border: solid 1.5px #dfe6f0;
      margin-right: 8px;
      margin-bottom: 0;
      overflow: hidden;
      img {
        border-radius: 16px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: auto;
        height: auto;
        aspect-ratio: 1 / 1;
      }
    }

    .product-thumbnail-image-selector {
      flex: 1;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0;
      .product-thumbnail-image-selector-item {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }

}
</style>