<script>
import {mapActions, mapGetters, mapState} from "vuex";
import UiButton from "../../../ui/UiButton.vue";
import ReleaseProductItem from "./release-product-item.vue";

export default {
  name: "release-product-list",
  components: {ReleaseProductItem},
  props: {
    products: {
      type: Array,
      required: true,
    },
    notFeaturedCount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['currency']),
    ...mapState(['showReleaseDetailMoreProducts']),

    showProducts() {
      return this.moveTopItemToTop.length > 5 ? this.moveTopItemToTop.slice(0, 5) : this.moveTopItemToTop;
    },
    moreProducts() {
      return this.moveTopItemToTop.length > 5 ? this.moveTopItemToTop.slice(5) : [];
    },
    moveTopItemToTop() {
      let topIndex = -1;
      let items = this.products;
      items.findIndex((item, index) => {
        if (item.shop.position !== 'None' && item.availability === "InStock"){
          topIndex = index;
          if (topIndex > 0) {
            let topItem = items.splice(topIndex, 1);
            items.unshift(topItem[0]);
          }
        }
      });
      return items;
    },
  },
  data() {
    return {
      hovered: -1,
    }
  },
  methods: {
    ...mapActions(['openReleaseDetailMoreProducts', 'closeReleaseDetailMoreProducts']),
  }
}
</script>

<template>
  <div :class="{
    'flex flex-col p-4 lg:p-8 pt-4': true,
    'pb-4': showProducts.length <= 5,
    'pb-8': showProducts.length > 5,
  }">
    <div class="text-base text-black font-semibold pb-4 border-b border-gray-low">{{$t('Available at', {count: notFeaturedCount})}}</div>
    <div>
      <div class="flex flex-col w-full">
        <template v-for="(product, index) in showProducts">
          <release-product-item :product="product"/>
        </template>

        <div class="flex flex-col justify-center items-center p-4 pt-8" v-if="moveTopItemToTop.length > 5" @click="openReleaseDetailMoreProducts">
          <div class="text-sm underline text-black cursor-pointer">{{
              $t('Show more')
            }}</div>
        </div>
      </div>
    </div>

    <div v-if="showReleaseDetailMoreProducts" class="fixed top-0 left-0 right-0 bottom-0 z-40">
      <div class="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-50 w-full h-full z-10" @click="closeReleaseDetailMoreProducts"></div>
      <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center z-20">
        <div class="flex flex-col w-full lg:w-1/3 bg-white shadow-lg h-full lg:h-2/3 border border-black">
          <div class="text-base text-black font-semibold p-4 border-b border-black flex flex-row justify-between items-center">
            <div class="flex-1">{{$t('Available at', {count: notFeaturedCount})}}</div>
            <div>
              <img src="~/assets/images/ui/icons/close.png" alt="Close" class="w-6 h-6 cursor-pointer" @click="closeReleaseDetailMoreProducts"/>
            </div>
          </div>
          <div class="flex flex-col w-full p-4 pt-0 flex-1 overflow-y-auto scrollbar pb-32 lg:pb-0">
            <template v-for="(product, index) in moreProducts">
              <release-product-item :product="product"/>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>