var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full h-full"},[_c('drawer-header',{attrs:{"title":_vm.headerTitle}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center min-h-36 lg:min-h-44 justify-center border-b border-black flex-none"},[_c('h1',{staticClass:"text-center text-2xl font-bold"},[_vm._v(_vm._s(_vm.user.name))]),_vm._v(" "),_c('p',{staticClass:"text-center text-base mt-1 mb-2"},[_vm._v(_vm._s(_vm.user.email))]),_vm._v(" "),_c('UiButton',{attrs:{"click":_vm.logout,"color":"black"}},[_vm._v(_vm._s(_vm.$t('Logout')))])],1),_vm._v(" "),_c('div',{staticClass:"w-full overflow-y-auto h-full pb-32 lg:pb-0"},[_c('div',{staticClass:"flex flex-col w-full h-full"},[_c('div',{staticClass:"flex border-b border-black flex-row justify-start gap-2 px-2 flex-none"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,class:{
            'py-2': true
          },on:{"click":() => {
          _vm.tabIndex = index;
        }}},[_c('div',{class:{

            'cursor-pointer rounded-[16px] min-h-8 flex flex-col justify-center items-center px-3 text-xs lg:text-sm': true,
            'bg-black text-white border border-black': _vm.tabIndex === index,
            'bg-white text-black border border-black': _vm.tabIndex !== index,
            }},[_vm._v("\n            "+_vm._s(tab.title)+"\n          ")])])}),0),_vm._v(" "),_c('div',{staticClass:"p-0 overflow-y-auto flex-auto pb-32 lg:pb-0"},[(_vm.tabIndex === 0)?_c('div',[_c('div',[_c('my-favorites')],1)]):_vm._e(),_vm._v(" "),(_vm.tabIndex === 1)?_c('div',[_c('my-notification')],1):_vm._e(),_vm._v(" "),(_vm.tabIndex === 2)?_c('div',[_c('my-account-form')],1):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }