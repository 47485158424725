<template>
  <header class="border-b border-l border-r border-black bg-white fixed top-0 w-full h-16 z-30 flex items-center" v-show="!headerHide">
    <div class="flex justify-between items-center w-full px-4 lg:px-8 relative ">
      <nav class="hidden lg:flex">
        <ul class="flex list-none m-0 p-0 gap-6">
          <li class="flex flex-col h-16" v-for="(menu, index) in menus" :key="index" @mouseover="() => {
            if(menu.subMenus && menu.subMenus.length > 0) openMegaMenu(index)
          }" @mouseleave="closeMegaMenu">
            <nuxt-link :to="localePath(menu.link)" class="flex-1 text-black text-xs font-normal no-underline lg:hover:underline text-center content-center"
           >{{ menu.title }}</nuxt-link>
            <div class="fixed left-0 top-16 w-full z-30" v-show="showMegaMenu && menu.subMenus && menu.subMenus.length > 0">
              <div class="absolute justify-between w-full bg-white px-0 z-30 top-0 flex items-center border-black border-r border-l border-b">
                <div class="flex flex-row items-center justify-between w-full">
                  <a v-for="(subMenu, sindex) in menu.subMenus" :href="subMenu.link" :key="sindex" class="flex-1 text-black content-center h-16 no-underline text-xs font-normal lg:hover:underline text-center border-r border-black lg:last:border-r-0">{{ subMenu.title }}</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <client-only>
        <div class="flex lg:hidden">
          <button class="bg-transparent border-none cursor-pointer" @click="openGlobalDrawer('menu')" v-if="!showGlobalDrawer">
            <img src="~/assets/images/ui/icons/menu.png" alt="menu" class="h-6" />
          </button>
          <button class="bg-transparent border-none cursor-pointer" @click="closeGlobalDrawer()" v-else>
            <img src="~/assets/images/ui/icons/close.png" alt="menu" class="h-6" />
          </button>
        </div>
      </client-only>

      <div class="absolute left-1/2 transform -translate-x-1/2">
        <nuxt-link :to="localePath({name: 'index'})">
          <img src="~/assets/images/ui/header-logo.png" alt="logo" class="h-6" />
        </nuxt-link>
      </div>

      <client-only>
        <div class="flex">
          <button class="bg-transparent border-none cursor-pointer ml-4 p-0" @click="() => openGlobalDrawer('search')">
            <img src="~/assets/images/ui/icons/search.png" alt="search" class="h-6" />
          </button>
          <button class="bg-transparent border-none cursor-pointer ml-4 p-0 hidden lg:flex" @click="() => openGlobalDrawer('setting')">
            <img src="~/assets/images/ui/icons/global.png" alt="setting" class="h-6" />
          </button>
          <button class="bg-transparent border-none cursor-pointer ml-4 p-0 hidden lg:flex"  v-if="!$store.state.auth.loggedIn" @click="() => openGlobalDrawer('sign')">
            <img src="~/assets/images/ui/icons/user.png" alt="signIn" class="h-6" />
          </button>
          <button class="bg-transparent border-none cursor-pointer ml-4 p-0 hidden lg:flex"  v-if="$store.state.auth.loggedIn" @click="() => openGlobalDrawer('mypage')">
            <img src="~/assets/images/ui/icons/user.png" alt="mypage" class="h-6" />
          </button>
        </div>

      </client-only>
    </div>
  </header>
</template>

<script>
import UContainer from "@/components/ui/container.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import SurfaceIcon from "@/components/icons/surface-icon.vue";
import LoginIcon from "@/components/icons/login-icon.vue";
import ReleaseItemRow from "@/components/new/release/release-item-row.vue";
import UDialog from "@/components/ui/dialog.vue";
import SearchHeaderLayout from "@/components/new/layouts/header/search-header.vue";
import MobileDrawer from "@/components/new/layouts/header/mobile-drawer.vue";
import {currencySymbol, getCountry, getCountryList} from "@/lib/utils";

export default {
  name: "header-layout",
  props: {
  },
  components: {MobileDrawer, SearchHeaderLayout, UDialog, ReleaseItemRow, LoginIcon, SurfaceIcon, UContainer},

  watch: {
    "$store.state.location.code": {
      handler: function (val) {
        this.country = getCountry(val);
      },
      immediate: true
    },
  },
  computed: {
    ...mapState(["landingsMenu", "onceLoggedIn", "location"]),
    ...mapState(["showMegaMenu", "megaMenuIndex", "showSettingDrawer", "showGlobalDrawer"]),
    menus() {

      return [
        {
          title: 'Sneaker',
          link: '/sneaker/',
          subMenus: [],
        },
        {
          title: 'Sale',
          link: '/sneaker/sale',
          subMenus: [],
        },
        {
          title: this.$t("filters.trending"),
          link: '/sneaker/top-trends',
          subMenus: [],
        },
        {
          title: this.$t("Limited Editions"),
          link: '/sneaker/limited-editions',
          subMenus: [],
        },
        {
          title: 'Reseller',
          link: '/sneaker/limited-editions',
          subMenus: [],
        },
        {
          title: this.$t("Shops"),
          link: '/shops',
          subMenus: [],
        }
      ]
    },
    headerHeight() {
      return this.searchBarVisible ? 144 : 80;
    },
    headerHide() {
      return this.$router.currentRoute.path.includes("/new-go");
    },
    headerVisible() {
      return this.scrollTop <= this.headerHeight || this.scrollUp;
    },
    currentCurrency() {
      return {
        code: (this.$store.state.currency || "EUR").toUpperCase(),
        symbol: currencySymbol(this.$store.state.currency || "EUR")
      };
    },
    currentLangName(){
      return this.availableLocales.find((l)=>{ return l.code === this.currentLang}).name
    },
    currentLang(){
      return this.$i18n.locale
    },
    currentCountry(){
      return this.$store.state.location.code
    },
    // country(){
    //   return getCountry(this.currentCountry)
    // },
    countries() {
      return getCountryList(this.$store.state.countryCodes)
    },
    availableLocales () {
      return this.$i18n.locales
    },
    searchBarVisible(){
      // console.log('searchBarVisible', this.$route.name)
      const hideSearchBarPages = ['index', 'register', 'login', 'product-checkout-id', 'product-id', 'new-home'];
      const routeName = this.$route.name;
      if (!routeName) return false;

      for (let i = 0; i < hideSearchBarPages.length; i++) {
        if (this.$route.name.startsWith(hideSearchBarPages[i]) || this.$route.name === hideSearchBarPages[i]) {
          return false;
        }
      }

      return true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  //<ul>
// <li>
//   <nuxt-link :to="sneakerRootPage()">Sneaker</nuxt-link>
// </li>
// <li>
//   <nuxt-link :to="sneakerLocalePath('sale')">Sale</nuxt-link>
// </li>
// <li>
//   <nuxt-link :to="sneakerLocalePath('top-trends')">{{ $t("filters.trending") }}</nuxt-link>
// </li>
//
// <li
//     v-for="page in landingsMenu"
//                 :key="page.url"
//     >
//     <nuxt-link :to="sneakerLocalePath(page.url)">{{ $t(page.title) }}</nuxt-link>
// </li>
// <li>
//   <nuxt-link :to="localePath({ name: 'shops' })">{{$t("Shops")}}</nuxt-link>
// </li>
// </ul>
  data() {
    return {
      localeViewVisible: false,
      scrollTop: 0,
      lastScrollTop: 0,
      scrollUp: false,
      country: getCountry(this.currentCountry),
    }
  },
  methods: {
    currencySymbol,
    ...mapMutations(["setSettingDialogVisible"]),
    ...mapActions(["openMobileDrawer"]),
    ...mapActions(["openMegaMenu", "closeMegaMenu"]),
    ...mapActions(["toggleSettingDrawer", "toggleSearchDrawer"]),
    ...mapActions(["openGlobalDrawer", 'closeGlobalDrawer']),
    handleScroll() {
      this.scrollTop = window.scrollY;
      this.scrollUp = this.scrollTop < this.lastScrollTop && this.scrollTop > 0;
      this.lastScrollTop = this.scrollTop;
    },
    setLocale(lang)
    {
      this.localeViewVisible = false;
      this.$router.push(this.switchLocalePath(lang));
    }
  }
};
</script>