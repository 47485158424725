<script>
export default {
  name: "top-button",
  data() {
    return {
      hover: false,
      show: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    onTop(){
      //스무스하게 상단으로 이동
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleScroll(){
      if(window.scrollY > 600){
        this.show = true;
      }else{
        this.show = false;
      }
    }
  }
}
</script>

<template>
  <div class="z-10 fixed bottom-5 right-5 lg:bottom-10 lg:right-10 cursor-pointer"
       @click="onTop"
       @mouseover="hover = true"
       @mouseleave="hover = false" v-show="show"
       >
    <img src="~/assets/images/ui/icons/top-button.png" alt="top button" class="w-12 h-12" v-show="!hover || !$device.isDesktop"/>
    <img src="~/assets/images/ui/icons/top-button-hover.png" alt="top button"  class="w-12 h-12" v-show="hover && $device.isDesktop"/>
  </div>
</template>

<style scoped>

</style>