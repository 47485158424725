<template>
    <div class="scroll-top-box" :class="cssClass" @click="scrollToTop">
        <span class="icon-up">
            <img src="~/assets/icons/ui/arrow-up.svg" alt="Scroll to top" class="icon-up-img"/>
        </span>
    </div>
</template>

<script>
    import {debounce} from 'lodash-core'
    export default {
        name: "toTopBtn",
        data(){
          return {
              cssClass : []
          }
        },
        methods: {
            scrollToTop(){
                document.getElementsByTagName("BODY")[0].scrollIntoView({behavior: "smooth"});
            } ,
            throttleScroll(){
                if (window.scrollY > 80) {
                    this.$store.commit('setHeaderTopClass', {remove: 'position-sticky', add: 'fixed-top'})
                } else {
                    this.$store.commit('setHeaderTopClass', {add: 'position-sticky', remove: 'fixed-top'})
                }
                let idx = this._.indexOf(this.cssClass, 'show')
                if (window.scrollY > 600)
                    idx === -1 &&  this.cssClass.push('show');
                else
                    this.cssClass.splice( idx,1);
            }
        },
        mounted(){
            this.$nextTick(()=>{
                window.addEventListener('scroll', this.throttleScroll);
            })

        },
        destroyed() {
            window.removeEventListener('scroll', this.throttleScroll);
        }
    }
</script>

<style scoped>

</style>