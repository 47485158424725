<template>
  <div>
    <b-modal id="location-modal" title="Change Your Settings" :no-close-on-esc="true" :no-close-on-backdrop="true" :hide-header-close="true" hide-footer hide-header class="modal-body fade modal show">
      <div class="w-100 modal-header d-flex flex-column align-items-center justify-content-center">
        <p class="modal-title">{{ $t("Change your settings") }}</p>
        <p>{{ $t("Choose your region & your preferred language below") }}</p>
      </div>
      <form @submit="saveLocation">
        <!-- Form Content -->
        <div class="p-4 d-flex flex-column">
          <span style="font-weight: bold;">Region</span>
          <select required v-model="selectedCountry" class="w-100 p-2 mt-2 locationModalSelect" >
            <option value="" disabled>{{ $t("Select a Country") }}</option>
            <option v-for="country in filteredCountries" :key="country.id" :value="country.code">{{ $t("countries-abbr." + country.code).includes("countries-abbr") ? country.name : $t("countries-abbr." + country.code) }}</option>
          </select>
          <span class="mt-3" style="font-weight: bold;">Language</span>
          <select class="w-100 p-2 mt-2 locationModalSelect" s name="" id="" v-model="language">
            <option value="">Select Language</option>
            <option :value="lang.code" v-for="lang in availableLocales" :key="lang.code">{{ lang.name }}</option>
          </select>
        </div>
        <!-- Form Controls -->
        <div class="modal-footer">
          <div class="w-100">
            <b-button type="submit" variant="dark" :style="{ float: 'right !important', marginLeft: '10px' }">Save Changes</b-button>
            <b-button @click="$bvModal.hide('location-modal')" variant="outline-dark">Cancel</b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  i18n: {
    inject: true,
  },
  name: "LocationModal",
  props: {
    prevLocation: Object,
  },
  data() {
    return {
      vuexCookie: "",
      filteredCountries: [],
      selectedCountry: "",
      language: '',
    }
  },
  
  computed: {
    ...mapState(["location","countries"]),
    availableLocales () {
      return this.$store.$i18n.locales;
    },
  },
  methods: {
    ...mapActions(["getCountryInfo"]),
    countryCodes() {
      this.countries.map((country, i) => {
        this.filteredCountries.push({
          id: i,
          name: Object.values(country)[0],
          code: Object.keys(country)[0],
        });
      })
    },
    saveLocation(e) {
      e.preventDefault();
      this.getCountryInfo(this.selectedCountry);
      // this.$store.commit('setVisitorLocation', this.selectedCountry);

      this.$bvModal.hide("location-modal");

      if (this.$router.currentRoute.params.hasOwnProperty('product')) {
        setTimeout(() => {
          window.location = this.switchLocalePath(this.language);
        }, 1000)
      } else {
        this.$router.push(this.switchLocalePath(this.language));
      }
    },
  },
  mounted() {
    this.language = this.$store.$i18n.locale;

    if ( this.selectedCountry === undefined || this.selectedCountry === null || this.selectedCountry === "" || this.selectedCountry === {} ){
      this.$axios.get('get-country-code').then((response) => {
        this.selectedCountry = response.data;
        this.getCountryInfo(this.selectedCountry);
      }).catch(err => {
        console.log(err);
        this.selectedCountry = "DE";
      });
    }
  },
  created() {
    this.countryCodes();
    this.selectedCountry = this.prevLocation.code;
  }
}
</script>