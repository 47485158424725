<template>
  <div class="flex flex-col w-full">
    <ui-breadcrumbs :items="breadcrumbItems"></ui-breadcrumbs>
    <div class="flex flex-col items-center justify-center gap-4 border-b border-black pb-4">
      <article class="flex flex-col items-start justify-start w-full bg-white border-b border-black p-6">
        <h1 class="text-2xl font-bold text-font-line-color mb-4">
          {{currentRouteMetas.title}}</h1>
        <div class="contents-article-description w-full lg:w-1/2" v-html="currentRouteMetas.seo_description"></div>
      </article>
    </div>
  </div>
<!--    <div>-->
<!--&lt;!&ndash;        <simple-header/>&ndash;&gt;-->
<!--        <div class="container-fluid line">-->
<!--            <breadcrumbs :items="breadcrumbItems"></breadcrumbs>-->
<!--        </div>-->
<!--        <article class="contents-article line">-->
<!--          <div class="contents-article-title">{{currentRouteMetas.title}}</div>-->
<!--          <div class="contents-article-description" v-html="currentRouteMetas.seo_description"></div>-->
<!--        </article>-->
<!--    </div>-->
</template>

<script>
    import SneakerThumbnail         from "../../../components/Sneakers/Thumbnail";
    import VoucherThumbnail         from "../../../components/Sneakers/VoucherThumbnail";
    import {mapMutations, mapState} from 'vuex'
    import SimpleHeader             from "../../../components/SimpleHeader";
    import staticMetasMixin         from "../../../plugins/staticMetasMixin"
    import ArrowRight               from "../../../components/icons/arrow-right";
    import Breadcrumbs from "../../../components/Breadcrumbs";
    import {btoaImplementation} from "@/plugins/redirectMixin";
    import UiBreadcrumbs from "@/components/v2/ui/UiBreadcrumbs.vue";

    export default {
        name      : 'Contents',
        components: {UiBreadcrumbs},
        props     : ['data'],
        mixins    : [staticMetasMixin],
        middleware: 'loadMetaNonQuery',
        data      : () => {
            return {
                rating    : 0,
                ratingInfo: ''
            };
        },
        methods   : {

        },
        computed  : {
            ...mapState({ currentRouteMetas: 'currentRouteMetas'}),
          schemaBreadcrumbs() {
            let bItems = this.breadcrumbItems.map((item, index)=>{
              return {
                "@type": "ListItem",
                "position": index + 1,
                "item": {
                  "@id":process.env.appUrl + item.lnk,
                  "name": (item.jsonText ? item.jsonText: item.text)
                }
              }
            })
            return {
              script: [
                {
                  hid: 'breadcrumbs',
                  type: 'application/ld+json',
                  innerHTML: JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": bItems
                  }, null, 2),
                },
              ]
            }
          },
          breadcrumbItems() {
            return [
              {
                text: 'Home',
                lnk: this.localePath('index')
              },
              {
                text: this.$t('text.sneakersContents'),
                lnk: this.localePath('index')
              },
              {
                text: this.currentRouteMetas.title || '',
                jsonText: this.currentRouteMetas.title || '',
                lnk: this.currentRouteMetas.href ? this.localePath(this.currentRouteMetas.href) : ''
              },
            ]
          }
        },
    }
</script>

<style lang="scss" scoped>

    /** mobile **/
    @media (max-width: 1024px) {
        .contents-article {
            padding: 0 20px;
            .contents-article-title {
                font-size: 24px;
                width: 100%;
            }
            .contents-article-description {
                width: 100%;
            }
        }
    }
</style>
