<template>
    <div class="flex flex-col relative">
      <header-layout/>
        <main class="main-layout flex flex-col items-center w-full lg:max-w-[2160px] min-h-screen border-l-1 border-r-1 pt-16 mx-auto border-gray-900">
            <nuxt/>
           <client-only>
             <global-drawer/>
<!--                <setting-dialog />-->
<!--                <to-top-btn />-->
<!--                <favorites-modal />-->
                <price-alert-setting-dialog/>
<!--                <alert-modal />-->
                <cookies-banner />
<!--    &lt;!&ndash;            <barcode-modal v-if="barcodeScanner" ref="barcodeModal"></barcode-modal>&ndash;&gt;-->
             <top-button/>
<!--                <bottom-banner />-->
           </client-only>
        </main>
      <footer-layout/>
<!--        <footer-component/>-->
    </div>
</template>

<script>
    import FooterComponent   from "../components/footerComponent";
    import BNavBar           from "../components/b-nav-bar";
    import HeaderLayout from "@/components/new/layouts/header/index.vue";
    import FooterLayout from "@/components/new/layouts/footer/index.vue";
    import SettingDialog from "@/components/new/layouts/setting-dialog.vue";
    import GlobalDrawer from "../components/v2/layouts/global-drawer.vue";
    import PriceAlertSettingDialog from "~/components/v2/sections/dialogs/price-alert-setting-dialog.vue";
    import TopButton from "~/components/v2/top-button.vue";
    export default {
        components: {
          TopButton,
          PriceAlertSettingDialog,
          GlobalDrawer,
          SettingDialog,
          FooterLayout,
          HeaderLayout,
            BarcodeModal : () => import("../components/Modals/BarcodeModal"),
            CookiesBanner: () => import("../components/cookiesBanner"),
            BottomBanner: () => import("../components/bottomBanner"),
            AlertModal: () => import("../components/Modals/alertModal"),
            NotificationModal: () => import("../components/Modals/notificationModal"),
            FavoritesModal: () => import("../components/Modals/favoritesModal"),
            ToTopBtn: () => import("../components/toTopBtn"),
            BNavBar,
            FooterComponent
        },
        head() {
            const hid = `mainSchema`;
            const lang = this.$i18n.locale;
            const i18nSeo = this.$nuxtI18nSeo();
            let rootPath = this.localePath('index');
            rootPath = rootPath === '/' ? '': rootPath;
            const mainSchema =  {
                "@context"        : "http://schema.org",
                "@type"           : "Organization",
                "name"            : "Sneakers123",
                "description"     :  this.$t('Sneaker Search Engine. Search over 100 sneaker shops at once.'),
                "url"             : process.env.appUrl+ rootPath,
                "logo"            : process.env.appUrl+ require('~/assets/img/logo/sneakers123-logo-'+this.$i18n.locale+'.svg'),
                "sameAs": [
                    "https://www.facebook.com/Sneakers123com",
                    "https://www.instagram.com/sneakers123com/",
                ]
            };

            return {
                htmlAttrs: {
                    ...i18nSeo.htmlAttrs
                },
                link: [
                        {
                            rel: 'canonical',
                            href: process.env.appUrl + this.$route.path
                        },
                    ...i18nSeo.link
                    ],
                meta : [
                    {hid: 'og:url', property: 'og:url', content: process.env.appUrl + this.switchLocalePath(this.$i18n.locale)},
                    ...i18nSeo.meta
                ],
                script: [
                    {
                        hid: 'mainSchema',
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify(mainSchema, null, 2),
                    },
                    {
                        hid: 'wecantrack',
                        type: 'text/javascript',
                        innerHTML: `

                               (function(w, c, t, u) {
                                   w._wct = w._wct || {}; w._wct = u;
                                   var s = c.createElement(t);
                                   s.type = 'text/javascript'; s.async = true; s.src = 'https://wct-2.com/wct.js';
                                   var r = c.getElementsByTagName(t)[0];
                                   r.parentNode.insertBefore(s, r);
                               }(window, document, 'script', {
                                   'uid' : 'r4qi87',
                                   'google_tracking_id' : 'UA-72543924-12',
                                   'proxy' : 'https://wct-2.com',
                               }));
                            `
                    }
                ],
                __dangerouslyDisableSanitizersByTagID: {
                    mainSchema: ['innerHTML'],
                    wecantrack: ['innerHTML']
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
.main-layout{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 2160px;
  min-height: 100vh;
  border-left: 1px solid #131416;
  border-right: 1px solid #131416;
  padding-top: 64px;
  margin: 0 auto;
}

</style>