import { render, staticRenderFns } from "./ProductThumbnail.vue?vue&type=template&id=6ae1b142&scoped=true"
import script from "./ProductThumbnail.vue?vue&type=script&lang=js"
export * from "./ProductThumbnail.vue?vue&type=script&lang=js"
import style0 from "./ProductThumbnail.vue?vue&type=style&index=0&id=6ae1b142&prod&lang=css"
import style1 from "./ProductThumbnail.vue?vue&type=style&index=1&id=6ae1b142&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae1b142",
  null
  
)

/* custom blocks */
import block0 from "./ProductThumbnail.vue?vue&type=custom&index=0&blockType=ins&class=adsbygoogle&style=display%3Ablock&data-ad-format=fluid&data-ad-layout-key=-h3-s-2x-ax%2B101&data-ad-client=ca-pub-7829825856881098&data-ad-slot=6578522698"
if (typeof block0 === 'function') block0(component)

export default component.exports