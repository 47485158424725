<template>
    <section class="tabs-section pb-0 prices-section" style="background: #F5F5F5;">
        <b-container class="line">
            <b-row>
                <b-col cols="12" class="filter-wrapper">
                  <div class="box-one">
                    <button @click="onlyInStock()" class="d-md-flex d-none">
                      <img src="@/assets/img/stock-box.png" class="d-md-block d-none" width="20" height="20" alt="...">
                      <img src="@/assets/img/stock-box.png" class="d-md-none d-block" width="16" height="16" alt="...">
                      <span> {{ $t('in-stock-text') }} </span>
                      <CloseIcon v-if="inStock" style="color: white"></CloseIcon>
                    </button>
                    <button @click="toggleSizeMenu">
                        <img src="@/assets/icons/filter-icon.svg" class="d-md-block d-none" width="16" height="16" alt="...">
                        <img src="@/assets/icons/filter-icon.svg" class="d-md-none d-block" width="12" height="12" alt="...">
                        {{ $t('filter-sizes-text') }}
                      <CloseIcon v-if="showSizeMenu" style="color: white margin-left: 8px"></CloseIcon>
                    </button>
                  </div>
                  <div>
                    <b-dropdown right button id="price-filter" :text="priceFilter" class="m-md-2 d-md-flex d-none">
                      <b-dropdown-item-button @click="sortByPrice(false)"> {{ $t('lowest-price-text') }} </b-dropdown-item-button>
                      <b-dropdown-item-button @click="sortByPrice(true)"> {{ $t('higher-price-text') }} </b-dropdown-item-button>
                    </b-dropdown>
                  </div>
                </b-col>
                <b-col cols="12" class="product-infomation" :class="{ 'with-margin-top ': hasGallery }">
                    <transition-group tag="div" name="list">
                        <template>
                        <div class="shops-list featured" v-for="f in $parent.featured" :key="f.id">
                            <ProductThumbnail
                            :product="f"
                            :featured="true"
                            ></ProductThumbnail>
                        </div>
                        </template>
                    </transition-group>
                    <!-- <div class="toggle-block">
                        <span
                        @click="toggleSizeMenu"
                        class="btn btn-sm"
                        :class="showSizeMenu ? 'btn-primary btn-filter' : 'btn-primary btn-filter'"
                        >
                        <CloseIcon v-if="showSizeMenu" style="color: white"></CloseIcon>
                        <span v-else>Filter sizes</span>
                        </span>
                    </div> -->
                    <!-- v-if="$store.state.auth.loggedIn" -->
                    <transition>
                      <div
                      class="size-menu"
                      v-show="
                            (availableSizes.eu.length ||
                            availableSizes.uk.length ||
                            availableSizes.us.length) &&
                            showSizeMenu
                        "
                        >
                        <div class="filter-size mt-3">
                          <p class="filter-size-title">{{ $t("Select size(s)") }}:</p>
                            <SizeFormatSelector></SizeFormatSelector>

                            <ul class="filter-sizes-list">
                              <li class="filter-size" v-for="size in availableSizes[sizeFormat]" :key="size">
                                <label class="container-size container2-size mb-0">
                                  <input
                                  type="checkbox"
                                  name="group3[]"
                                  :value="size"
                                  class="d-none"
                                  v-model="$parent['sizes_' + sizeFormat]"
                                  />
                                  <span class="label-text" v-html="sizeHtml(size)"></span>
                                  <span class="checkmark"></span>
                              </label>
                            </li>
                            </ul>
                        </div>
                        <!-- <div class="selected-sizes" v-if="showSizes">
                          <p class="filter-size-title">{{ $t("Selected sizes") }}:</p>
                            <ul class="selected-sizes-list">
                            <template v-for="size in $parent.sizes_eu">
                                <SelectedSize :size="size" format="eu"></SelectedSize>
                            </template>
                            <template v-for="size in $parent.sizes_us">
                                <SelectedSize :size="size" format="us"></SelectedSize>
                            </template>
                            <template v-for="size in $parent.sizes_uk">
                                <SelectedSize :size="size" format="uk"></SelectedSize>
                            </template>
                            </ul>
                        </div> -->
                        </div>
                        <!-- <div v-else class="size-menu" v-show="showSizeMenu">
                          <div class="size-not-registered">
                              {{ $t("size-register-required-text") }}
                              <nuxt-link
                              class="btn btn-primary btn-sm"
                              :to="onceLoggedIn ? localePath('login') : localePath('register')"
                              >{{ onceLoggedIn ? $t("Sign in") : $t("Register") }}</nuxt-link
                              >
                          </div>
                        </div> -->
                    </transition>

                    <ShopsList :onlyInStock="inStock" :isHigherPrice="isHigherPrice"></ShopsList>
                </b-col>
            </b-row>
        </b-container>
        <RelatedProducts :sneaker="sneakerProps" />
    </section>
</template>

<script>
import ProductPhotoRadio from "../ProductPhotoRadio";
import AlertButton from "../../Sneakers/AlertButton";
import SizeFormatSelector from "../../Sneakers/SizeFormat";
import { mapState, mapMutations } from "vuex";
import SelectedSize from "../SelectedSize";
import ShopsList from "../ShopsList";
import FavoriteButton from "../../Sneakers/FavoriteButton";
import { isEmpty, capitalize, map } from "lodash-core";
import ProductThumbnail from "../ProductThumbnail";
import CloseIcon from "~/components/icons/close-icon";
import RelatedProducts from "./Related.vue";

export default {
    props: {
        sneakerProps: {
            default: {},
            type: Object,
        }
    },
  data() {
    return {
      initRootSizeFormat: true,
      showSizeMenu: false,
      scrollBlock: "",
      scrollBlock1: "",
      scrollBlock2: "",
      scrBlk2Left: 0,
      inStock: false,
      isHigherPrice: null,
      priceFilter: this.$t('price-filter-text'),
    };
  },
  name: "mainSection",
  methods: {
    toggleSizeMenu() {
      // if(this.$store.state.auth.loggedIn)
      this.showSizeMenu = !this.showSizeMenu;
      // else {
      //     this.setClipboardText(this.$t('To view sizes, please create an account or log in.'));
      //     setTimeout(() => {
      //         this.setClipboardText('');
      //     }, 2000);
      // }
    },
    isEmpty: isEmpty,
    ...mapMutations(["setSizeFormat", "setClipboardText"]),
    // flat,unique,sort
    fus(array) {
      return this._.filter(
        this._.sortBy(this._.uniq(this._.flatten(array)), (n) => {
          return parseFloat(n);
        }),
        (s) => {
          return !this._.isEmpty(s);
        }
      );
    },
    // scrollSpy() {
    //   let prMain = document.getElementsByClassName("product-main");
    //   let prTable = document.getElementsByClassName("product-table-info");
    //   let prInfo = document.getElementsByClassName("product-infomation");
    //   let prImg = document.getElementsByClassName("product-main-img");
    //   let scrollBlock2 = document.getElementById("scrollBlock2");
    //   if (prMain.length && window.innerWidth >= 992) {
    //     prMain = prMain[0];
    //     prInfo = prInfo[0];
    //     prImg = prImg[0];
    //     prTable = prTable[0];
    //
    //     console.log('prMain3', prMain);
    //     console.log('prInfo3', prInfo);
    //     console.log('prImg3', prImg);
    //     console.log('prTable3', prTable);
    //     let scroll = window.scrollY;
    //     let start = prMain.offsetTop - 95;
    //     let end = prInfo.offsetHeight - prTable.offsetHeight - 50;
    //     let onBottomTop =
    //       prInfo.offsetHeight -
    //       prImg.offsetHeight -
    //       parseInt(getComputedStyle(prImg).marginTop) -
    //       parseInt(getComputedStyle(prImg).marginBottom) +
    //       parseInt(getComputedStyle(prInfo).marginTop);
    //     let onscrollTop = 0;
    //     if (scroll < start) {
    //       this.scrollBlock = "ontop top-0";
    //       scrollBlock2.style.left = "auto";
    //       this.scrollBlock1 = "";
    //       this.scrollBlock2 = "";
    //     } else if (scroll >= start && scroll < end) {
    //       this.scrollBlock1 = "top-65";
    //       this.scrollBlock2 = "top-65";
    //       this.scrollBlock = "onscroll";
    //       scrollBlock2.style.left = this.scrBlk2Left + "px";
    //     } else {
    //       this.scrollBlock1 = "";
    //       this.scrollBlock2 = "";
    //       this.scrollBlock = "onbottom";
    //       scrollBlock2.style.left = "auto";
    //       if (this.hasGallery)
    //         this.$refs.scrollBlock1.style.top = onBottomTop + "px";
    //       scrollBlock2.style.top = onBottomTop + "px";
    //     }
    //   }
    // },
    showSelectedSizes() {
      let sizesQuery = this.$route.query.sizes;
      if (sizesQuery) {
        let sizes = sizesQuery.split(",");
        let selectedFormats = [];
        for (let index in sizes) {
          let format = sizes[index].substr(0, 2);
          let value = sizes[index].substr(2);

          if (!selectedFormats.includes(format)) selectedFormats.push(format);

          if (!this.$parent["sizes_" + format].includes(value)) {
            let availableSizes = this.availableSizes[format];
            let allAvailableSizes = availableSizes.filter((item) => {
              return item.indexOf(value) > -1;
            });
            this.$parent["sizes_" + format] =
              this.$parent["sizes_" + format].concat(allAvailableSizes);
          }
        }
        if (this.initRootSizeFormat) this.setRootSizeFormat(selectedFormats);
      }
    },
    setRootSizeFormat(selectedFormats) {
      if (selectedFormats.includes("eu")) this.setSizeFormat("eu");
      else if (selectedFormats.includes("us")) this.setSizeFormat("us");
      else if (selectedFormats.includes("uk")) this.setSizeFormat("uk");
      this.initRootSizeFormat = false;
    },
    onlyInStock() {
      this.inStock = !this.inStock
    },
    sortByPrice (filter) {
      this.priceFilter = filter ? this.$t('higher-price-text') : this.$t('lowest-price-text');
      this.isHigherPrice = filter;
    }
  },
  mounted() {
    let scrollBlock2 = document.getElementById("scrollBlock2");
    let scrollBlock1 = document.getElementById("scrollBlock1");
    if (scrollBlock1) this.scrBlk2Left = scrollBlock1.offsetWidth;

    let container = document.getElementsByClassName("product-section")[0];
    let containerStyle =
      container.currentStyle || window.getComputedStyle(container);
    this.scrBlk2Left +=
      scrollBlock2.offsetLeft + parseInt(containerStyle.marginLeft);

    // this.$nextTick(() => {
    //   window.addEventListener("scroll", this.scrollSpy);
    // });
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.scrollSpy);
  // },
  computed: {
    ...mapState(["sizeFormat", "onceLoggedIn"]),
    sneakerColors() {
      return map(this.$parent.sneaker.colorways, (g) => {
        return {
          text: capitalize(g),
          link: this.sneakerLocalePath("", { q: g }),
        };
      });
    },
    gender() {
      let genders = isEmpty(this.$parent.sneaker.gender)
        ? ["men", "women"]
        : this.$parent.sneaker.gender;
      return map(genders, (g) => {
        return {
          text: this.$t("filters." + g),
          link: this.sneakerLocalePath(g),
        };
      });
    },
    model() {
      if (!isEmpty(this.$parent.sneaker.category)) {
        return {
          title: this.$parent.sneaker.category.title,
          url: this.sneakerLocalePath(this.$parent.sneaker.category.url),
        };
      } else {
        return {
          title: this.$parent.sneaker.name,
          url: this.sneakerRootPage({ q: this.$parent.sneaker.name }),
        };
      }
    },
    sneakerImage() {
      if (this.$parent.currentImageIndex == 0) {
        // return this.$parent.sneaker.image ? this.$parent.sneaker.image :
        //     require('~/assets/img/placeholder.png');
        return this.thumb(this.$parent.sneaker, "big");
      } else {
        return this.$parent.sneaker.gallery[this.$parent.currentImageIndex - 1];
      }
    },
    showSizes() {
      if (this.initRootSizeFormat) this.showSelectedSizes();

      return (
        !this._.isEmpty(this.$parent.sizes_eu) ||
        !this._.isEmpty(this.$parent.sizes_us) ||
        !this._.isEmpty(this.$parent.sizes_uk)
      );
    },
    availableSizes() {
      return {
        eu: this.fus(
          this._.map(this.$parent.sneaker.products, (p) => {
            return p.sizes.eu;
          })
        ),
        uk: this.fus(
          this._.map(this.$parent.sneaker.products, (p) => {
            return p.sizes.uk;
          })
        ),
        us: this.fus(
          this._.map(this.$parent.sneaker.products, (p) => {
            return p.sizes.us;
          })
        ),
      };
    },
    hasGallery() {
      return (
        this.$parent.sneaker.image &&
        !this._.isEmpty(this.$parent.sneaker.gallery)
      );
    },
    related() {
      return this.$parent.sneaker.colorway;
    },
  },
  components: {
    ProductThumbnail,
    CloseIcon,
    SizeFormatSelector,
    SelectedSize,
    ShopsList,
    RelatedProducts,
  },
};
</script>
