<script>
import {mapGetters} from "vuex";
import UiButton from "../../../ui/UiButton.vue";
import redirectMixin from "../../../../../plugins/redirectMixin";

export default {
  name: "release-product-item",
  mixins: [redirectMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hovered: false
    }
  },

  computed: {
    ...mapGetters(['currency']),

    lnkProduct() {
      return {
        url: this.product.shop_url,
        productId: this.product.id,
      }
    },
    getProductPrice() {
      let currency = this.currency;

      let convertedPrice = this.product['price_' + currency.toLowerCase()];
      if (convertedPrice != false || convertedPrice != undefined)
        return this.$root.price(convertedPrice, currency);

      return '-';
    },

    getSalePrice() {
      let currency = this.currency;
      let convertedSalePrice = this.product['sale_price_' + currency.toLowerCase()];
      if (convertedSalePrice != false || convertedSalePrice != undefined)
        return this.$root.price(convertedSalePrice, currency);

      return '-';
    },
    discount(price, salePrice) {
      return Math.round(((price - salePrice) / price) * 100) + "%";
    },
  },
}
</script>

<template>

  <nuxt-link :to="localePath({name: 'new-go',query: goProductQuery })" target="_blank">
  <div class="flex flex-row justify-between w-full py-4 border-b border-gray-low cursor-pointer" @mouseover="hovered = true" @mouseleave="hovered = false">
    <div class="flex flex-col h-16 w-16 rounded-full border border-gray-low">
        <img v-lazy-load :data-src="product.shop.logo" :alt="product.shop.name + ' logo'" class="rounded-full w-full h-16 object-contain object-center p-1"/>

    </div>
    <div class="flex flex-col flex-1 pl-4 justify-center items-start">
      <div class="text-sm text-black font-semibold flex flex-row justify-start items-center mb-1">{{product.shop.name}} <span class="ml-1"><img src="~/assets/images/ui/icons/check-product.png" class="w-4 h-4"/></span></div>
      <div class="text-xs text-gray-medium">{{ $t('last-time-check', {time: product.last_check_human}) }}</div>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row justify-end items-center mb-1">
        <div class="text-gray-high text-xs line-through mr-1" v-if="product.sale_price">
          {{getProductPrice}}
        </div>
        <div :class="{
                    'text-sm': true,
                    'text-danger': product.sale_price,
                    'text-black': !product.sale_price
                  }">{{product.sale_price ? getSalePrice : getProductPrice}}</div>
      </div>
      <div class="flex flex-row justify-end items-center">
        <div :class="{
                  'border border-black h-8 text-sm flex flex-col justify-center items-center cursor-pointer px-2': true,
                  'bg-white text-black': !hovered,
                  'bg-black text-white': hovered
                }">{{$t('shop-now-text')}}</div>
      </div>
    </div>
  </div>
  </nuxt-link>
</template>

<style scoped>

</style>