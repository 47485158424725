<template>
    <div>
<!--        <a :href="product.shop.cashback_redirect_url" class="cashback" v-if="!(_.isEmpty(product.shop.cashback_amount_percentage) && _.isEmpty(product.shop.cashback_redirect_url))">-->
<!--            {{ product.shop.cashback_amount_percentage }}% {{ $t('cashback-text') }}-->
<!--        </a>-->
        <a :href="localePath({name: 'new-go',query: goProductQuery })" target="_blank" rel="nofollow" class="item-pill">
            <div class="image">
                <client-only>
                    <img class="shop-logo img-fluid" v-lazy-load :data-src="product.shop.logo" :alt="product.shop.name + ' logo'" />
                </client-only>
            </div>
            <div class="price">
                <template v-if="product.sale_price">
                    <div class="sale">
                        <span class="old">{{ getProductPrice(product) }}</span> 
                        {{ getSalePrice(product) }}
                    </div>
                </template>
                <template v-else>
                    <div class="new">{{ getProductPrice(product) }}</div>
                </template>
            </div>
            <div class="action">
                <a :href="localePath({name: 'new-go',query: goProductQuery })" target="_blank">
                    <arrow-right/>
                </a>
            </div>
        </a>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import arrowRight from '../icons/arrow-right-white.vue';
import redirectMixin from '../../plugins/redirectMixin.js';

export default {
    name: 'BestPricesItem',
    components: { arrowRight },
    props: ['product'],
    mixins: [redirectMixin],
    computed: {
        ...mapGetters(['currency']),

      lnkProduct() {
        return {
          url: this.product.shop_url,
          productId: this.product.id,
        }
      },
        lnk() {
            return this.product.shop_url;
        },
        url() {
            return this.product.url;
        },
        partner() {
            return this.product.shop.name;
        },
    },
    methods: {
        getProductPrice(product) {
            let currency = this.currency;

            let convertedPrice = product['price_' + currency.toLowerCase()];
            if (convertedPrice != false || convertedPrice != undefined)
                return this.$root.price(convertedPrice, currency);

            return '-';
        },
        getSalePrice(product) {
            let currency = this.currency;
            let convertedSalePrice = product['sale_price_' + currency.toLowerCase()];
            if (convertedSalePrice != false || convertedSalePrice != undefined)
                return this.$root.price(convertedSalePrice, currency);

            return '-';
        },
    }
}
</script>

<style>

</style>