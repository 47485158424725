<script>
import { isEmpty, capitalize, map } from "lodash-core";

export default {
  name: "release-detail-info",
  props: {
    release: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      isShow: false,
    }
  },

  computed: {

    sneakerColors() {
      return map(this.release.colorways, (g) => {
        return {
          text: capitalize(g),
          link: this.sneakerLocalePath("", { q: g }),
        };
      });
    },
    gender() {
      let genders = isEmpty(this.release.gender)
          ? ["men", "women"]
          : this.release.gender;
      return map(genders, (g) => {
        return {
          text: this.$t("filters." + g),
          link: this.sneakerLocalePath(g),
        };
      });
    },
    model() {
      if (!isEmpty(this.release.category)) {
        return {
          title: this.release.category.title,
          url: this.sneakerLocalePath(this.release.category.url),
        };
      } else {
        return {
          title: this.release.name,
          url: this.sneakerRootPage({ q: this.release.name }),
        };
      }
    },
  }
}
</script>

<template>
  <div class="flex flex-col p-4 lg:p-8 py-4">
    <div :class="{
      'cursor-pointer flex flex-row justify-between items-center': true,
      'border-b border-gray-low pb-4 ': isShow
    }" @click="isShow = !isShow">
      <div class="text-base text-black font-semibold ">{{ $t('sneaker-detail-text') }}</div>
      <div>
        <img src="~/assets/images/ui/icons/detail-plus.png" class="w-6 h-6" v-if="!isShow"/>
        <img src="~/assets/images/ui/icons/detail-minus.png" class="w-6 h-6" v-else/>
      </div>
    </div>
    <div class="flex flex-col w-full" v-show="isShow">
      <div class="flex flex-row border-b border-gray-low">
        <div class="flex flex-col justify-center items-start min-w-24 h-12 ">
          <h3 class="text-gray-high text-xs">{{ $t('model-text') }}</h3>
        </div>
        <h4 class="flex flex-col justify-center items-start flex-1 h-12 ">
          <nuxt-link :to="model.url" class="text-xs text-black underline">{{ model.title }}</nuxt-link>
        </h4>
      </div>
      <div class="flex flex-row border-b border-gray-low">
        <div class="flex flex-col justify-center items-start min-w-24 h-12 ">
          <h3 class="text-gray-high text-xs">{{$t('style-code-text')}}</h3>
        </div>
        <div class="flex flex-col justify-center items-start flex-1 h-12 ">
          <h4 class="text-xs text-black">{{ release.sku }}</h4>
        </div>
      </div>
      <div class="flex flex-row border-b border-gray-low">
        <div class="flex flex-col justify-center items-start min-w-24 h-12 ">
          <h3 class="text-gray-high text-xs">{{ $t('brand-text') }}</h3>
        </div>
        <h4 class="flex flex-col justify-center items-start flex-1 h-12 ">
          <nuxt-link class="text-xs text-black underline" :to="sneakerLocalePath(_.kebabCase(release.brand))">{{ release.brand }}</nuxt-link>
        </h4>
      </div>
      <div class="flex flex-row border-b border-gray-low">
        <div class="flex flex-col justify-center items-start min-w-24 h-12 ">
          <h3 class="text-gray-high text-xs">{{ $t('Gender') }}</h3>
        </div>
        <h4 class="flex flex-row flex-wrap justify-start items-center flex-1 h-12 ">
          <nuxt-link v-for="g in gender" :key="g.text" class="text-xs text-black underline mr-1 mb-1" :to="g.link">{{
              g.text
            }}</nuxt-link>
        </h4>
      </div>
      <div class="flex flex-row border-b border-gray-low" v-if="sneakerColors">
        <div class="flex flex-col justify-center items-start min-w-24 h-12 ">
          <h3 class="text-gray-high text-xs">{{ $t('Color') }}</h3>
        </div>
        <div class="flex flex-row flex-wrap justify-start items-center flex-1 h-12 " v-if="sneakerColors.length > 0">
          <nuxt-link v-for="g in sneakerColors" :key="g.text" class="text-xs text-black underline mr-1 mb-1" :to="g.link">{{
              g.text
            }}</nuxt-link>
        </div>
        <div class="flex flex-row flex-wrap justify-start items-center flex-1 h-12 " v-else>
          <h4 class="text-xs text-black">{{ $t("N/A") }}</h4>
        </div>
      </div>

      <div class="flex flex-row border-b border-gray-low">
        <div class="flex flex-col justify-center items-start min-w-24 h-12 ">
          <h3 class="text-gray-high text-xs">{{$t('date-added-text')}}</h3>
        </div>
        <div class="flex flex-col justify-center items-start flex-1 h-12 ">
          <h4 class="text-xs text-black">{{ release.created }}</h4>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>