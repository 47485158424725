<script>
import UiTabs from "../../ui/UiTabs.vue";
import UiLabelInput from "../../ui/UiLabelInput.vue";
import UiButton from "../../ui/UiButton.vue";
import {mapActions, mapMutations} from "vuex";
import formMixin from "../../../../plugins/formMixin";

export default {
  name: "drawer-header",
  props: {
    title: {
      type: String,
      required: true
    },
    onBack: {
      type: Function,
      required: false
    },
    onClose: {
      type: Function,
      required: false
    }
  },
}
</script>

<template>
  <div class="flex justify-between items-center p-4 border-b border-black flex-none">
    <div v-if="onBack" class="cursor-pointer" @click="onBack">
      <img src="~/assets/images/ui/icons/back-arrow.png" alt="back" class="h-6 w-6" />
    </div>
    <div class="text-lg flex-grow text-center">{{ title }}</div>
    <div v-if="onClose" class="cursor-pointer ml-4" @click="onClose">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </div>
  </div>
</template>

<style scoped>

</style>