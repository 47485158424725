<template>
  <b-row>
    <b-col cols="12" v-if="$parent.product.sale_price_exchange > 0">
      <h1 class="font-weight-bold origin-price m-0">${{ $parent.product.price_exchange }}</h1>
      <h2 class="font-weight-bold sale-price">${{ $parent.product.sale_price_exchange }}</h2>
    </b-col>
    <b-col cols="12" v-else>
      <h2 class="font-weight-bold sale-price">${{ $parent.product.price_exchange }}</h2>
    </b-col>
      <b-col cols="12">
          <h3 class="font-weight-bold d-table-cell info-table-heading"> {{ $t('sneaker-detail-text') }}:</h3>
          <table class="product-detail-table">
          <tbody>
              <tr>
                <td> <h3>Shop</h3> </td>
                <td>
                  {{ $parent.product.shop.name }}
                </td>
              </tr>
              <tr>
              <td> <h3>{{ $t('model-text') }}</h3> </td>
              <td>{{ model.title }}
              </td>
              </tr>
              <tr>
                <td> <h3>{{ $t('style-code-text') }}</h3> </td>
                <td>{{ $parent.product.release.sku }}</td>
              </tr>
              <tr>
              <td> <h3>{{ $t('brand-text') }}</h3> </td>
              <td>{{ $parent.product.release.brand }}
              </td>
              </tr>
              <tr>
              <td><h3>{{ $t("Gender") }}</h3></td>
              <td>
                  <span v-for="g in gender" :key="g.text" class="d-inline">
                    {{
                      g.text
                    }}
                  </span>
              </td>
              </tr>
              <tr v-if="sneakerColors">
              <td><h3>{{ $t("Color") }}</h3></td>
              <td>
                  <span v-if="sneakerColors.length">
                    <span class="separated-links" :to="g.link" v-for="g in sneakerColors" :key="g.text">{{
                        g.text
                      }}</span>
                  </span>
                  <span v-else> {{ $t("N/A") }} </span>
              </td>
              </tr>
              <tr>
              <td> <h3>{{ $t('date-added-text') }}</h3> </td>
              <td> <h4 class="font-normal">{{ $parent.product.release.created }}</h4> </td>
              </tr>
          </tbody>
          </table>
      </b-col>
    <b-col cols="12">
      <b-button
          block
          class="my-2 buy-direct-btn"
          variant="outline-primary"
          target="_blank"
          :href="localePath({name: 'new-go',query: goProductQuery })">
        {{$t('Buy Direct')}}
      </b-button>
      <h2 class="line-divider "><span class="span-line-divider ">{{$t('OR')}}</span></h2>
      <b-dropdown v-if="lastSize" :text="sizeType + ' Size : ' + lastSize" block size="lg" variant="outline-primary" class="my-2" toggle-class="order-dropdown-toggle" menu-class="w-100">
        <b-dropdown-item
            target="_blank"
            :href="localePath({name: 'new-go',query: goProductQuery })">{{$t('Click to see more sizes')}}</b-dropdown-item>
        <b-dropdown-item href="#">{{sizeType}} Size : {{lastSize}}</b-dropdown-item>
      </b-dropdown>
      <b-button block class="my-2 order-btn" variant="primary" :href="localePath({path: `/product/checkout/${$parent.product.id}?size=` + encodeSize})">{{$t('Checkout Now')}}</b-button>
    </b-col>
  </b-row>
</template>

<script>
import { isEmpty, capitalize, map } from "lodash-core";
import ArrowRight from "@/components/icons/arrow-right.vue";
import redirectMixin from "@/plugins/redirectMixin";

export default {
  name: "SneakerDetail",
  components: {ArrowRight},
  mixins: [redirectMixin],
  data() {
    return {
      sizeType: 'US',
      encodeSize: '',
    }
  },
  mounted() {
    this.encodeSize = btoa(this.sizeType + ' Size : ' + this.lastSize)
  },
  computed: {
    lnk() {
      return this.$parent.product.shop_url
    },
    lnkProduct() {
      return {
        url: this.lnk,
        productId: this.$parent.product.snkProductId || this.$parent.product.id
      }
    },
    lastSize() {
      const usSize = this.$parent.product.sizes.us || [];
      const euSize = this.$parent.product.sizes.eu || [];
      const ukSize = this.$parent.product.sizes.uk || [];

      if (usSize.length > 0) {
        this.sizeType = 'US';
        return usSize[usSize.length - 1];
      } else if (euSize.length > 0) {
        this.sizeType = 'EU';
        return euSize[euSize.length - 1];
      } else if (ukSize.length > 0) {
        this.sizeType = 'UK';
        return ukSize[ukSize.length - 1];
      } else {
        return '';
      }
    },
    sneakerColors() {
      return map(this.$parent.product.release.colorways, (g) => {
        return {
          text: capitalize(g),
          link: this.$parent.sneakerLocalePath("", { q: g }),
        };
      });
    },
    gender() {
      let genders = isEmpty(this.$parent.product.release.gender)
        ? ["men", "women"]
        : this.$parent.product.release.gender;
      return map(genders, (g) => {
        return {
          text: this.$t("filters." + g),
          link: this.$parent.sneakerLocalePath(g),
        };
      });
    },
    model() {
      if (!isEmpty(this.$parent.product.release.category)) {
        return {
          title: this.$parent.product.release.category.title,
          url: this.$parent.sneakerLocalePath(this.$parent.product.release.category.url),
        };
      } else {
        return {
          title: this.$parent.product.release.name,
          url: this.$parent.sneakerRootPage({ q: this.$parent.product.release.name }),
        };
      }
    },
  },
};
</script>


<style>
.separated-links {
  margin-right: 5px;
}

.line-divider {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #aaa;
  text-align: center;
  position: relative;
}

.line-divider:before {
  content: "";
  display: block;
  border-top: solid 1px #aaa;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 19px;
  z-index: 1;
}

.line-divider .span-line-divider {
  background: #fff;
  padding: 0 20px;
  position: relative;
  font-size: 1rem;
  z-index: 5;
}
.origin-price{
  text-decoration: line-through;
  color: #999;
}
.sale-price{
  color: #000;
}

.buy-direct-btn{
  background-color: #fff !important;
  color: #00a5ca !important;
  border: 1px solid #00a5ca !important;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.order-btn{
  background-color: #00a5ca !important;
  color: #fff !important;
  border: 1px solid #00a5ca !important;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.order-dropdown-toggle, .order-dropdown-toggle:hover, .order-dropdown-toggle:focus, .order-dropdown-toggle:active{
  color: #00a5ca !important;
  border-color: #00a5ca !important;
  background-color: #fff !important;
}
</style>