<script>
export default {
  name: "u-button",
  props: {
    block: { type: Boolean, default: true },
    click: { type: Function, default: () => {} },
    variant: { type: String, default: "contained" },
    color: { type: String, default: "primary"},
    bt: { type: Boolean, default: true},
    animate: { type: Boolean, default: true},
    onDot: { type: Boolean, default: false}
  },
  computed: {

    borderColor() {
      if(this.color === "primary") {
        return "#23d1d8";
      }
      if(this.color === "black") {
        return "#191919";
      }
      if(this.color === "black-white") {
        return "#fff";
      }
      if(this.color === "secondary") {
        return "#191919";
      }
      if(this.color === "danger") {
        return "#FF0000";
      }
      if(this.color === "warning") {
        return "#FFA500";
      }
      if(this.color === "success") {
        return "#008000";
      }
    },

    bgColor() {
      if(this.color === "primary") {
        return "#23d1d8";
      }
      if(this.color === "black") {
        return "#191919";
      }
      if(this.color === "black-white") {
        return "#191919";
      }
      if(this.color === "secondary") {
        return "#fff";
      }
      if(this.color === "danger") {
        return "#FF0000";
      }
      if(this.color === "warning") {
        return "#FFA500";
      }
      if(this.color === "success") {
        return "#008000";
      }
    },
    textColor() {
      if(this.color === "primary") {
        return "#fff";
      }
      if(this.color === "black") {
        return "#fff";
      }
      if(this.color === "black-white") {
        return "#fff";
      }
      if(this.color === "secondary") {
        return "#191919";
      }
      if(this.color === "danger") {
        return "#fff";
      }
      if(this.color === "warning") {
        return "#fff";
      }
      if(this.color === "success") {
        return "#fff";
      }
    },
    classes() {
      if (this.variant === "contained") {
        return `tw-bg-[${this.bgColor}] tw-text-[${this.textColor}] tw-font-bold tw-py-3 tw-px-6 tw-rounded-md tw-text-center tw-w-full`
            + (this.bt ? "" : " tw-rounded-tl-none tw-rounded-tr-none")
            + (this.animate ? " tw-transition tw-duration-300 tw-ease-in-out tw-transform hover:tw-scale-105" : "")
      }
      if (this.variant === "outlined") {
        return `tw-border-2 tw-border-[${this.bgColor}] tw-text-[${this.textColor}] tw-font-bold tw-py-3 tw-px-6 tw-rounded-md tw-text-center tw-w-full`
            + (this.bt ? "" : " tw-rounded-tl-none tw-rounded-tr-none")
            + (this.animate ? " tw-transition tw-duration-300 tw-ease-in-out tw-transform hover:tw-scale-105" : "")
      }

      return `tw-bg-primary tw-text-white tw-font-bold tw-py-3 tw-px-6 tw-rounded-md tw-text-center tw-w-full`
          + (this.bt ? "" : " tw-rounded-tl-none tw-rounded-tr-none")
          + (this.animate ? " tw-transition tw-duration-300 tw-ease-in-out tw-transform hover:tw-scale-105" : "")
    },
  }
}
</script>

<template>
  <button class="u-btn" :class="{'bt-none': !bt, 'u-btn-block': block}" @click="click" :style="{backgroundColor: bgColor, color: textColor, borderColor: borderColor}">
    <slot></slot>
    <span class="btn-dot" v-if="onDot"></span>
  </button>
</template>

<style scoped lang="scss">
.u-btn {
  border-radius: 4px;
  background-color: #23d1d8;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: 1px solid #23d1d8;
  width: auto;
  padding: 0 24px;

  height: auto;
  min-height: 48px;
  position: relative;

  .btn-dot{
    top: 10px;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #23d1d8;
  }
  &.u-btn-block {
    line-height: 48px;
    height: 48px;
    width: 100%;
    padding: 0;
  }

  &:hover {
    background-color: #1db7bf;
    transition: background-color 0.3s ease-in-out;
  }

  &.bt-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }
}

</style>