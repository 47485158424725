<template>
  <div class="flex flex-col w-full pb-24">
    <UiBreadcrumbs :items="breadcrumbItems"/>
    <div class="flex flex-col md:flex-row relative bg-white">
      <div class="flex flex-col w-full lg:h-auto lg:w-2/3 relative" :style="{height: thumbnailHeight + 'px'}">
        <div class="flex flex-col border-b border-r border-black">
          <release-detail-thumbnail :release="sneaker" :imageHeight="thumbnailHeight"/>
        </div>
      </div>
      <div class="flex flex-col w-full h-1/2 lg:h-auto lg:w-1/3 top-0 ml-[-1px]">
        <div class="flex flex-col relative">
          <div class="flex flex-col border-l border-b  border-black">
            <release-product-info :release="sneaker" :product="product"/>
          </div>
          <div class="flex flex-col border-l border-b border-black">
            <release-product-list :products="notFeatured" :notFeaturedCount="notFeaturedCount"/>
          </div>
          <div class="flex flex-col border-l border-b border-black">
            <release-detail-info :release="sneaker" />
          </div>
        </div>
      </div>
    </div>

    <release-related :related-releases="sneaker.related" />
  </div>
</template>

<script>
import shippingMixin from '../../plugins/shippingMixin';
import productDetailsSchema from '../../plugins/product-details-schema';
import {isEmpty} from "lodash-core";
import redirectMixin from '../../plugins/redirectMixin.js';
import axios from "axios";
import UiBreadcrumbs from "../../components/v2/ui/UiBreadcrumbs.vue";
import ReleaseFavorite from "../../components/v2/thumbnails/release-favorite.vue";
import ReleaseAlert from "../../components/v2/thumbnails/release-alert.vue";
import ReleaseDetailThumbnail from "../../components/v2/thumbnails/release-detail-thumbnail.vue";
import ReleaseProductList from "../../components/v2/sections/release/detail/release-product-list.vue";
import ReleaseProductInfo from "../../components/v2/sections/release/detail/release-product-info.vue";
import ReleaseDetailInfo from "../../components/v2/sections/release/detail/release-detail-info.vue";
import ReleaseThumbnail from "../../components/v2/thumbnails/release-thumbnail.vue";
import HomeSectionTitle from "../../components/v2/sections/home/components/home-section-title.vue";
import ReleaseRelated from "../../components/v2/sections/release/detail/release-related.vue";

export default {
  name: "index",
  validate ({ params }) {
    return (/-/.test(params.product) && !/\//.test(params.product))
  },
  mixins: [shippingMixin, redirectMixin],
  components: {
    ReleaseRelated,
    HomeSectionTitle, ReleaseThumbnail,
    ReleaseProductInfo,
    ReleaseProductList,
    ReleaseDetailInfo, ReleaseDetailThumbnail, ReleaseAlert, ReleaseFavorite, UiBreadcrumbs},
  async asyncData({store, params, $axios, app, query, redirect})
  {
    try {
      let resp = await $axios.get('releases/' + params.product + `?locale=${app.i18n.locale}&location=${store.state.location.code}`);

      const data = resp.data;

      if(data.code && (data.code === 301 || data.code === 302) && data.redirect){
        redirect(data.redirect);
        return {};
      }

      if(data.code){
        redirect('/sneaker');
        return {};
      }

      return {sneaker: data, products: data.products, debug: query.debug === 'true'}
    }
    catch (e) {
      console.log('prod uri', 'releases/' + params.product + `?locale=${app.i18n.locale}&location=${store.state.location.code}`);
      console.log('prod ', e.response ? e.response.status : e);
    }
  },
  data() {
    return {
      debug: false,
      lowestPriceProduct: null,
      sneaker: {
        is_trending: false,
        related: [],
        shop: null
      },
      sizes_eu         : [],
      sizes_us         : [],
      sizes_uk         : [],
      products         : [],
      filters          : {
        featured   : [],
        notFeatured: []
      },
      shipping         : {
        free    : false,
        country : false,
        included: false
      },
      loadMore         : false,
      currentImageIndex: 0,
      storeURL: {},
      thumbnailHeight: 0,
      infoHeight: 0,
      isSticky: false,
      productInfoBottomReached: false,
    }
  },
  created(){
    this.setSneaker();
  },
  mounted() {
    this.addLastViewed();

    //현재 스크린 사이즈 - 64px이 이미지의 최대 높이
    this.thumbnailHeight = this.$device.isDesktop ? window.innerHeight - 64 : window.innerWidth;
    // const isPartnerSiteOpen = localStorage.getItem('isPartnerSiteOpen');
    // if (isPartnerSiteOpen === 'true'){
    //     if (this.notFeatured.length > 0) {
    //         localStorage.setItem('isPartnerSiteOpen', 'false');
    //         const routeData = this.$router.resolve(this.localePath({name: 'go',query: this.query }));
    //         window.open(routeData.href, '_blank');
    //     }
    // }


    this.initRelease();
    this.storeURL = this.$router.resolve(this.localePath({name: 'new-go',query: this.goProductQuery }));


    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll);
    });
  },

  beforeDestroy() {

    this.$nextTick(() => {
      window.removeEventListener('scroll', this.handleScroll);
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Scroll to the top of the page after the component has been loaded
      vm.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    });
  },

  beforeRouteUpdate(to, from, next) {
    // Scroll to the top of the page before navigating to a new route
    this.addLastViewed();
    window.scrollTo(0, 0);
    next();
  },
  methods   : {
    initRelease() {
      let lowestPriceProduct = null;
      let lowestPrice = 0;

      // throw new Error('test');
      if(this.products){

        for (let key in this.products) {
          const item = this.products[key];
          if (item.available) {
            const price = item.sale_price_eur || item.price_eur;
            if (lowestPrice === 0 || lowestPrice > price) {
              lowestPrice = price;
              lowestPriceProduct = item;
            }
          }
        }

        if(lowestPriceProduct && this.debug){
          const country = (this.$store.state.location.code || 'US').toUpperCase();
          const currency = (this.$store.state.currency || 'USD').toUpperCase();
          const language = (this.$i18n.locale || 'en').toLowerCase();

          const url = process.env.newApiUrl + '/v1/products/snk/' + lowestPriceProduct.id;
          axios.get(url, {
            headers: {
              'Content-Type': 'application/json',
              'X-County-Code': country,
              'X-Currency-Code': currency,
              'X-Language-Code': language,
              'X-Client-Uuid': this.$store.state.clientUid || null,
            }
          })
              .then((resp) => {
                if(resp.data && resp.data.data){
                  this.lowestPriceProduct = resp.data.data;
                }
              })
              .catch((e) => {
                console.log('prod ', e.response ? e.response.status : e);
              });
        }

      }



    },
    handleScroll() {
      // const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // console.log('Scroll Top:', scrollTop);
      // const thumbnail = this.$refs.thumbnail;
      // const productInfo = this.$refs.productInfo;

      // 스크롤 위치가 40 미만일 때, sticky 상태 해제
      // if (scrollTop < 40) {
      //   console.log('Scroll top is less than 40, disabling sticky.');
      //   this.isSticky = false;
      //   productInfo.style.position = '';
      //   productInfo.style.top = '';
      //   return;
      // }

      // const thumbnailBottom = thumbnail.getBoundingClientRect().bottom;
      // const productInfoBottom = productInfo.getBoundingClientRect().bottom;
      // const containerBottom = this.$refs.releaseDetail.getBoundingClientRect().bottom;

      // console.log('Thumbnail Bottom:', thumbnailBottom);
      // console.log('Product Info Bottom:', productInfoBottom);
      // console.log('Container Bottom:', containerBottom);

      // if(this.$device.isDesktop){
      //   // productInfo가 thumbnail보다 작을 때, sticky 적용
      //   if (thumbnailBottom < 0 && containerBottom > productInfoBottom) {
      //     console.log('Applying sticky.');
      //     this.isSticky = true;
      //   } else if (thumbnailBottom < 0 && containerBottom <= productInfoBottom) {
      //     console.log('Product info bottom reached, disabling sticky and fixing at bottom.');
      //     this.isSticky = false;
      //     this.productInfoBottomReached = true;
      //   } else if (thumbnailBottom >= 0) {
      //     console.log('Thumbnail is visible, disabling sticky.');
      //     this.isSticky = false;
      //     this.productInfoBottomReached = false;
      //   }
      //
      //   console.log('Is Sticky:', this.isSticky);
      //   console.log('Product Info Bottom Reached:', this.productInfoBottomReached);
      //
      //   if (this.isSticky) {
      //     productInfo.style.position = 'sticky';
      //     productInfo.style.top = '0px';
      //     console.log('Product Info position set to sticky.');
      //   } else if (this.productInfoBottomReached) {
      //     productInfo.style.position = 'absolute';
      //     productInfo.style.top = `${containerBottom - productInfoBottom}px`; // 하단에 고정
      //     console.log('Product Info position set to absolute at bottom.');
      //   } else {
      //     productInfo.style.position = '';
      //     productInfo.style.top = '';
      //     console.log('Product Info position reset.');
      //   }
      // }

    },
    filter()
    {
      this.resetFilters();
      //this.setProducts();
      this.filterSize();
      // this.filterShipping();
    },
    filterSize() {
      if (!this._.isEmpty(this.sizes_eu) || !this._.isEmpty(this.sizes_us) || !this._.isEmpty(this.sizes_uk)) {
        let filter = (p) => {
          let intersectionResultEu = false;
          let intersectionResultUs = false;
          let intersectionResultUk = false;
          for (let index in this.sizes_eu) {
            if (this.sizes_eu[index])
              intersectionResultEu = intersectionResultEu || this._.intersection(p.sizes.eu, [this.sizes_eu[index].toString()]).length;
          }
          for (let index in this.sizes_us) {
            if (this.sizes_us[index])
              intersectionResultUs = intersectionResultUs || this._.intersection(p.sizes.us, [this.sizes_us[index].toString()]).length;
          }
          for (let index in this.sizes_uk) {
            if (this.sizes_uk[index])
              intersectionResultUk = intersectionResultUk || this._.intersection(p.sizes.uk, [this.sizes_uk[index].toString()]).length;
          }
          return intersectionResultEu || intersectionResultUs || intersectionResultUk;
        };
        this.addFilter('featured', filter);
        this.addFilter('notFeatured', filter);
      }
    },
    filterShipping() {
      this._.each(this.shipping, (key, value)=>{
        if (value) {
          if (key === 'free')
            this.filterFreeShipping();
          // if (key === 'included')
          //     this.filterIncludedShipping();
          if (key === 'country')
            this.filterShippingInMyCountry();
        }
      });
    },
    filterShippingInMyCountry() {
      this.products = this._.filter(this.products, (p) => {
        let countryCode = this.$store.state.location.code;
        let rules = p.shop.shipping_rules;
        rules = this._.filter(rules, (rule) => {
          return rule.country_code === countryCode;
        });
        return rules.length > 0;
      });
    },
    filterFreeShipping() {
      this.products = this._.filter(this.products, (p) => {
        let countryCode = this.$store.state.location.code;
        let rules = p.shop.shipping_rules;
        let currentProductPrice = p.sale_price ? p.sale_price : p.price;

        this._.filter(rules, (rule) => {
          if (rule.country_code === countryCode) {
            if (rule.minimum === 0 && rule.costs > 0)
              return false;
            if (rule.minimum !== 0 && currentProductPrice >= rule.minimum)
              return true;
          }
        });
        return false;
      });
    },
    applyFilter(collection, product) {
      let result = true;
      this._.each(this.filters[collection], (filter) => {
        if (!filter(product))
          result = false
      });

      return result;
    },
    addFilter(collection, filter) {
      this.filters[collection].push(filter);
    },
    resetFilters() {
      this.filters.featured = [];
      this.filters.notFeatured = [];
    },
    setSneaker() {
      this.filter();
      this.loadMore = false;
      this.currentImageIndex = 0;
    },
    customFilteredProducts(result) {
      let availableResult = result.filter((element) => !element.sold_out && !element.coming_soon);
      let sortedResult = availableResult.sort((a, b) => {

        let currency = this.$store.state.currency;
        let salePriceProp = 'sale_price_' + currency;
        let priceProp = 'price_' + currency;
        let priceA = a[salePriceProp] != 0 ? a[salePriceProp] : a[priceProp];
        let priceB = b[salePriceProp] != 0 ? b[salePriceProp] : b[priceProp];

        return (priceB > priceA) ? -1 : (priceB < priceA ? 1 : 0);
      });
      sortedResult = sortedResult.sort((a, b) => {
        if (a.shop.countdowns.length > 0 && a.shop.countdowns[0].is_active &&
            (a.shop.countdowns[0].brand === this.sneaker.brand ||
                a.shop.countdowns[0].release_id === this.sneaker.id))
          return -1
      });
      return sortedResult.concat(result.filter((element) => element.coming_soon), result.filter((element) => element.sold_out));
    },
    countDownOffers() {
      let result = this._.filter(this.products, (p) => {
        return ((p.shop.countdowns.length > 0) &&  p.shop.countdowns[0].is_active)
      });
      return result // this.customFilteredProducts(result);
    },
    async addLastViewed() {
      await  this.$store.restored;
      this.$store.dispatch('addViewedSneaker', {
        brand         : this.sneaker.brand,
        name          : this.sneaker.name,
        image         : this.sneaker.image,
        priceRangeEur : this.priceRange(this.sneaker, 'eur'),
        priceRangeUsd : this.priceRange(this.sneaker, 'usd'),
        priceRangeJpy : this.priceRange(this.sneaker, 'jpy'),
        priceRangeGbp : this.priceRange(this.sneaker, 'gbp'),
        numberOfOffers: this._.size(this.sneaker.products),
        slug          : this.sneaker.slug
      });
    },
  },
  computed: {
    breadcrumbItems() {
      let snkr = this.sneaker || {};
      let name = snkr.name;
      let sku = snkr.sku;
      let brand = snkr.brand;
      let category = snkr.category;
      let bcrumbs =  [
        {
          text: 'Home', lnk: this.localePath({name:'index'})
        },
        {
          text: 'Sneakers', lnk: this.sneakerRootPage().path
        },
        {
          text: brand, jsonText: brand + ' Sneakers', lnk: this.brandPath(brand)
        }
      ];
      if(!isEmpty(category)) {
        bcrumbs.push({
          text: category.title.replace(brand, '').trim(),
          jsonText: brand + ' ' + category.title.replace(brand, '').trim() + ' Sneakers',
          lnk: this.sneakerLocalePath(category.url)
        })
      }

      bcrumbs.push({
        text: name + ' ' + sku ,
        jsonText: brand+ ' ' + name + ' ' + sku ,
        lnk: this.$route.path});
      return bcrumbs;
    },
    countDownOffer() {
      let result = this._.filter(this.products, (p) => {
        return (p.shop.countdowns.length > 0) // && p.available
      });
      result = this._.filter(result, p => {
        return ((p.shop.countdowns[0].brand === this.sneaker.brand ||
                p.shop.countdowns[0].release_id === this.sneaker.id)
            && p.shop.countdowns[0].is_active)
      });
      return result // this.customFilteredProducts(result);
    },


    product() {
      let products = this.notFeatured.filter((item) => item.availability === "InStock");


      const p = products.filter(product => product.shop && product.shop.position && ['Highlighted', 'Top'].includes(product.shop.position));
      if (p.length > 0) return p[0];

      let currency = this.$store.state.currency;

      products.sort((a, b) => {
        if (a.sale_price && b.sale_price) {
          a['sale_price_' + currency.toLowerCase()] - b['sale_price_' + currency.toLowerCase()]
        } else {
          a['price_' + currency.toLowerCase()] - b['price_' + currency.toLowerCase()]
        }
      });
      return products && products.length > 0 ? products[0] : {};
    },
    featured() {
      let result = this._.filter(this.products, (p) => {
        return (this.applyFilter('featured', p) && ((p.shop.position === 'Featured' && p.available)))
      });
      return this.customFilteredProducts(result);
    },
    notFeatured() {
      let result = this._.filter(this.products, (p) => {
        return  (this.applyFilter('notFeatured', p) && (p.shop.position !== 'Featured' || !p.available))
      });
      return this.customFilteredProducts(result);
    },
    fullName() {
      return this.sneaker.brand + ' ' + this.sneaker.name;
    },
    priceText() {
      return this.releasePrice(this.sneaker) ?
          this.$t('From').toLowerCase() + ' ' + this.releasePrice(this.sneaker) : ' '
    },
    pageTitle() {
      return this.sneaker.metas && this.sneaker.metas.title ? this.sneaker.metas.title :
          this.fullName + ' ' + this.sneaker.sku + ' ' + this.priceText;
    },
    pageDescription() {

      if(this.sneaker.metas && this.sneaker.metas.description)
        return this.sneaker.metas.description;

      let msg =this.$t('product-page-description') || 'sneakers123.com';
      const params = {
        fullName: this.fullName,
        sku: this.sneaker.sku,
        price: this.releasePrice(this.sneaker) || ' ',
        shopsCount: this.featured.length + this.notFeatured.length,
        brand: this.sneaker.brand
      };
      for (let key in params) {
        msg = msg.replace(`%{${key}}`, params[key]);
      }
      return msg
      // this.$t('product-page-description', )
    },
    notFeaturedCount() {
      return this.notFeatured.length;
    },
    lnkProduct() {
      if(this.notFeaturedCount > 0 && this.notFeatured[0].shop_url){
        return {
          productId: this.notFeatured[0].id,
          url: this.notFeatured[0].shop_url
        }
      }
      return {
        url: window.location.href
      }
    },
    lnk() {
      return this.notFeaturedCount > 0 && this.notFeatured[0].shop_url
          ? this.notFeatured[0].shop_url
          : '';
    },
    url() {
      return this.notFeaturedCount > 0 && this.notFeatured[0].url
          ? this.notFeatured[0].url
          : '';
    },
    partner() {

      return this.notFeaturedCount > 0 && this.notFeatured[0].shop && this.notFeatured[0].shop.name
          ? this.notFeatured[0].shop.name
          : '';
    },
  },
  watch: {
    'shipping': {
      handler()
      {
        this.filter();
      },
      deep: true
    },
    'sizes_eu'() {
      this.filter();
    },
    'sizes_uk'() {
      this.filter();
    },
    'sizes_us'() {
      this.filter();
    },
    $route:{
      handler() {
        console.log('ABC', $route.params);
      }
    }
  },
  head() {
    let product = {
      ...this.sneaker,
      url: process.env.appUrl + this.$route.path,
      description: this.pageDescription
    };
    const hid = 'productSchema';
    let bItems = this.breadcrumbItems.map((item, index)=>{
      return {
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@id": process.env.appUrl + item.lnk,
          "name": (item.jsonText ? item.jsonText: item.text)
        }
      }
    })
    return {
      title: this.pageTitle,
      meta: [
        {hid: 'description', name: 'description', content: this.pageDescription},
        {hid: 'og:title', property: 'og:title', content: this.pageTitle},
        {hid: 'og:description', property: "og:description", content: this.pageDescription},
        {hid: 'og:image', property: "og:image", content: this.sneaker.image},
        {hid: 'og:image:width', property: "og:image:width", content: 800},
        {hid: 'og:image:height', property: "og:image:height", content: 800},
        { hid: 'robots', name: 'robots', content: (this.sneaker.seo_index ? 'all':'noindex') }
      ],
      link: this.alternateLinks,
      script: [
        {
          hid,
          type: 'application/ld+json',
          innerHTML: JSON.stringify(productDetailsSchema(product,this.$store.state.currency), null, 2),
        },
        {
          hid: 'breadcrumbs',
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": bItems
          }, null, 2),
        },
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  },
}
</script>

<style lang="scss" scoped>
</style>