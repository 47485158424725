<script>
import HomeSectionTitle from "./components/home-section-title.vue";
import ReleaseThumbnail from "../../thumbnails/release-thumbnail.vue";

export default {
  name: "HomeReleasesSection",
  components: {ReleaseThumbnail, HomeSectionTitle},
  props: {
    releases: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<template>
  <section
      class="flex flex-col w-full border-b border-black"
  >
    <HomeSectionTitle
        :title="$t('text.homePage.homeReleasesSectionTitle')" :subtitle="$t('text.homePage.homeReleasesSectionSubTitle')"/>
    <div class="flex flex-row flex-wrap w-full border-t border-black">
      <div class="flex border-r border-b border-black last:border-r-0 basis-1/2 lg:basis-1/4"
           v-for="(release, i) in releases" :key="i" >
        <ReleaseThumbnail :release="release"/>
      </div>
    </div>
  </section>
</template>
