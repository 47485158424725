<template>
  <div class="mobile-drawer" :class="{ open: mobileDrawerVisible }">
    <div class="mobile-drawer-overlay" @click="closeMobileDrawer"></div>
    <div class="mobile-drawer-inner">
      <div class="mobile-drawer-header">
        <div class="mobile-drawer-header-logo">
          <img src="~/assets/images/logo.png" alt="logo" />
        </div>
        <div class="mobile-drawer-header-close" @click="closeMobileDrawer">
          <img src="~/assets/icons/ui/close-icon.svg" alt="close" />
        </div>
      </div>
      <div class="mobile-drawer-nav-wrap">
        <ul>
          <li @click="goMenuRoute(sneakerRootPage())">
            Sneaker
            <div class="mobile-drawer-nav-arrow">
              <img src="~/assets/icons/ui/arrow-right.svg" alt="arrow" />
            </div>
          </li>
          <li @click="goMenuRoute(sneakerLocalePath('sale'))">
            Sale
            <div class="mobile-drawer-nav-arrow">
              <img src="~/assets/icons/ui/arrow-right.svg" alt="arrow" />
            </div>
</li>
          <li @click="goMenuRoute(sneakerLocalePath('top-trends'))">
            {{ $t("filters.trending") }}
            <div class="mobile-drawer-nav-arrow">
              <img src="~/assets/icons/ui/arrow-right.svg" alt="arrow" />
            </div>
          </li>
          <li
              v-for="page in landingsMenu"
              :key="page.url"
              @click="goMenuRoute(sneakerLocalePath(page.url))"
          >
            {{ $t(page.title) }}
            <div class="mobile-drawer-nav-arrow">
              <img src="~/assets/icons/ui/arrow-right.svg" alt="arrow" />
            </div>
          </li>
          <li @click="goMenuRoute(localePath({ name: 'shops' }))">
            {{$t("Shops")}}
            <div class="mobile-drawer-nav-arrow">
              <img src="~/assets/icons/ui/arrow-right.svg" alt="arrow" />
            </div>
          </li>
        </ul>

        <div class="mobile-drawer-nav-buttons">
          <div
              @click="goMenuRoute(localePath({ name: 'profile-my-account' }))"
              class="sky-btn full"
              v-if="loggedIn"
          >
            {{ $t("My account") }}
          </div>
          <div
              @click="logout"
              class="sky-btn full"
              v-if="loggedIn"
          >
            {{ $t("Logout") }}
          </div>
          <div
              @click="goMenuRoute(localePath({ name: onceLoggedIn ? 'login' : 'register' }))"
              class="sky-btn full"
              v-else
          >
            {{ $t("Sign in") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "mobile-drawer",
  computed: {
    ...mapState(["mobileDrawerVisible", "landingsMenu", "onceLoggedIn"])
  },
  watch: {
    mobileDrawerVisible(val) {
      if(val) {
        window.document.body.classList.add("body-scroll-lock");
      } else {
        window.document.body.classList.remove("body-scroll-lock");
      }
    }
  },
  methods: {
    ...mapActions(["openMobileDrawer", "closeMobileDrawer"]),
    goMenuRoute(route) {
      this.closeMobileDrawer();
      this.$router.push(route);
    },
    logout(){
      this.$store.dispatch('logout');
      this.closeMobileDrawer();
      this.$router.push(this.localePath('index'))
    },
  }
};
</script>

<style lang="scss" scoped>
.mobile-drawer {
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
  display: none;
  /* mobile */
  @media (max-width: 1024px) {
    display: block;
    .mobile-drawer-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1000;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }

    .mobile-drawer-inner {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 1001;
      width: 340px;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;

      .mobile-drawer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 15px;
        border-bottom: 1px solid #dfe6f0;

        .mobile-drawer-header-logo {
          img {
            width: 200px;
          }
        }

        .mobile-drawer-header-close {
          img {
            width: 18px;
          }
        }

      }
    }

    .mobile-drawer-nav-wrap {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          padding: 0 24px;
          font-size: 16px;
          font-weight: normal;
          line-height: 48px;
          height: 48px;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
          .mobile-drawer-nav-arrow {
            float: right;
            img {
              height: 12px;
            }
          }
        }
      }
    }


    &.open {
      visibility: visible;
      .mobile-drawer-overlay {
        opacity: 1;
      }
      .mobile-drawer-inner {
        transform: translateX(0);
      }

      .mobile-drawer-nav-wrap {
        ul {
          li {
            opacity: 1;
            &:nth-child(1) {
              transition-delay: 0.1s;
            }
            &:nth-child(2) {
              transition-delay: 0.2s;
            }
            &:nth-child(3) {
              transition-delay: 0.3s;
            }
            &:nth-child(4) {
              transition-delay: 0.4s;
            }
            &:nth-child(5) {
              transition-delay: 0.5s;
            }
            &:nth-child(6) {
              transition-delay: 0.6s;
            }

          }
        }
      }
    }
  }

  .mobile-drawer-nav-buttons {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding: 0 24px;
    flex-direction: column;
    gap: 10px;
  }
}
</style>