<script>
import {capitalize, isEmpty, map} from "lodash-core";

export default {
  name: "product-detail-table",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sneakerColors() {
      return this.product.catalog.colors;
    },
    gender() {
      let genders = isEmpty(this.product.catalog.gender)
          ? ["MAN", "WOMAN"]
          : this.product.catalog.gender;
      return genders;
    },
  },
}

</script>

<template>
  <table class="product-info-detail-table">
    <tbody>
    <tr>
      <td>{{ $t('label.shop') }}</td>
      <td>
        {{ product.shop.name }}
      </td>
    </tr>
    <tr>
      <td>{{ $t('SKU') }}</td>
      <td>{{ product.sku.sku }}</td>
    </tr>
    <tr>
      <td>{{ $t('brand-text') }}</td>
      <td>{{ product.catalog.brand.name }}
      </td>
    </tr>
    <tr>
      <td>{{ $t("Gender") }}</td>
      <td>
                  <span v-for="g in gender" :key="g.text" class="d-inline">
                    {{
                      g.text
                    }}
                  </span>
      </td>
    </tr>
    <tr v-show="sneakerColors">
      <td>{{ $t("Color") }}</td>
      <td>
                  <span v-if="sneakerColors.length">
                    <span class="separated-links" :to="g.link" v-for="g in sneakerColors" :key="g.text">{{
                        g.text
                      }}</span>
                  </span>
        <span v-else> {{ $t("N/A") }} </span>
      </td>
    </tr>
    </tbody>
  </table>

</template>

<style lang="scss" scoped>
.product-info-detail-table {
  margin: 0 8px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 16px;
  td {
    padding: 8px 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #191919;
    &:first-child {
      width: 120px;
    }
    &:last-child {
      text-align: left;
      font-weight: 700;
    }
  }
}
</style>