<script>
export default {
  name: "HomeTopButtonsSection",
}
</script>

<template>
  <section
      class="flex flex-row items-center justify-center w-full border-b border-black">
    <a href="/sneakers" class="flex justify-center w-1/2 h-10 text-black items-center font-semibold text-[14px] border-r border-black">RELEASE</a>
    <a href="/sneakers" class="flex justify-center w-1/2 h-10 text-black items-center font-semibold text-[14px]">PRICE DROP</a>
  </section>
</template>
