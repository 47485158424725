<template>
  <div>
<!--    <SimpleHeader />-->
    <section class="order-complete">
      <b-container v-if="order" class="order-complete-wrap">
        <b-row class="mb-2">
          <b-col cols="12">
            <div class="order-detail-box text-center">
              <div class="order-detail-box-title">
                {{$t('text.orderComplete.orderDetails')}}
              </div>
              <div class="order-detail-box-info">
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.orderNumber')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ order.seq }}
                  </div>
                </div>
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.orderStatus')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ orderStatus }}
                  </div>
                </div>
              </div>
            </div>

          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12">
            <div class="order-detail-box text-center">
              <div class="order-detail-box-title">
                {{$t('text.orderComplete.orderItems')}}
              </div>
              <div class="order-item" v-for="(item, index) in order.items" :key="index">
                <div class="order-item-image">
                  <img :src="item.product.coverImageUrl" alt="product image" />
                </div>
                <div class="order-item-info">
                  <div class="order-item-title">
                    {{ item.product.catalog.brand.name }} {{ item.name }}
                  </div>
                  <div class="order-item-sku">
                    {{ item.product.sku.sku }}
                  </div>
                  <div class="order-item-price">
                    {{ priceFormat(item.price, order.currency.code) }}<span> x {{ item.quantity }}</span>
                  </div>

                </div>

                <div class="order-item-status">
                  {{ orderItemStatus(item.status) }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12">
            <div class="order-detail-box text-center">
              <div class="order-detail-box-title">
                {{$t('text.orderComplete.priceInformation')}}
              </div>
              <div class="order-detail-box-info">
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.priceTotal')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ priceFormat(order.price, order.currency.code) }}
                  </div>
                </div>
                <div class="order-detail-box-info-item" v-if="order.shippingFee > 0">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.shippingFee')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ priceFormat(order.shippingFee, order.currency.code) }}
                  </div>
                </div>

                <div class="order-detail-box-info-item" v-if="order.tax > 0">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.taxAndDuties')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ priceFormat(order.tax, order.currency.code) }}
                  </div>
                </div>
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.orderTotal')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ priceFormat(order.amount, order.currency.code) }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12">
            <div class="order-detail-box text-center">
              <div class="order-detail-box-title">
                {{$t('text.orderComplete.buyerInformation')}}
              </div>
              <div class="order-detail-box-info">
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.name')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ order.buyerFirstName }} {{ order.buyerLastName }}
                  </div>
                </div>
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.email')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ order.buyerEmail }}
                  </div>
                </div>
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.phone')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ order.buyerPhone }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12">
            <div class="order-detail-box text-center">
              <div class="order-detail-box-title">
                {{$t('text.orderComplete.recipientInformation')}}
              </div>
              <div class="order-detail-box-info">
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.name')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ order.recipientFirstName }} {{ order.recipientLastName }}
                  </div>
                </div>
                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.phone')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ order.recipientPhone }}
                  </div>
                </div>

                <div class="order-detail-box-info-item">
                  <div class="order-detail-box-info-item-title">
                    {{$t('text.orderComplete.address')}}
                  </div>
                  <div class="order-detail-box-info-item-value">
                    {{ order.recipientAddress }} {{ order.recipientAddressDetail }}

                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-2" v-if="order.status === 'ORDERED'">
          <b-col cols="12">
            <div class="order-detail-button-box text-center">
              <u-button :click="() => cancelOrder(false)" color="danger" :block="false">{{
                $t('label.orderCancel')
                }}</u-button>
            </div>
            <u-dialog :show="orderCancelDialogVisible"
                      :onClose="cancelOrderDialogClose" size="md" :title="$t('label.warning') + '!'">
            <u-dialog-content>
              <p>
                {{$t('text.orderCancelWarning')}}
              </p>
            </u-dialog-content>
            <u-dialog-footer>
              <u-button color="secondary" :block="false" :click="cancelOrderDialogClose" style="margin-right: 10px;">{{$t('label.cancel')}}</u-button>
              <u-button color="primary" :block="false" :click="() => cancelOrder(true)"
              >{{$t('label.orderCancel')}}</u-button>
            </u-dialog-footer>
            </u-dialog>
          </b-col>
        </b-row>
      </b-container>

    </section>
  </div>
</template>

<script>
import SimpleHeader from "../../../../components/SimpleHeader.vue";
import Breadcrumbs from "../../../../components/Breadcrumbs.vue";
import {priceFormat} from "../../../../lib/utils";
import redirectMixin from "@/plugins/redirectMixin";
import UButton from "@/components/ui/button.vue";
import UDialog from "@/components/ui/dialog.vue";
import UDialogContent from "@/components/ui/dialog-content.vue";
import UDialogFooter from "@/components/ui/dialog-footer.vue";
import axios from "axios";

export default {
  name: "order_complete",
  validate ({ params }) {
    return params.seq;
  },
  mixins: [redirectMixin],
  components: {UDialogFooter, UDialogContent, UDialog, UButton, SimpleHeader, Breadcrumbs },

  async asyncData({store, params, $axios, redirect, query, app})
  {
    let password = null;
    try {
      password = query.k;

      const country = (store.state.location.code || 'US').toUpperCase();
      const currency = (store.state.currency || 'USD').toUpperCase();
      const language = (app.i18n.locale || 'en').toLowerCase();

      const url = process.env.newApiUrlSsr + '/v1/orders/seq/' + params.seq + '?password=' + password;

      const {data} = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-County-Code': country,
          'X-Currency-Code': currency,
          'X-Language-Code': language,
          'X-Client-Uuid': store.state.clientUid || null,
        }
      });

      if(data && data.data){
        return {
          order: data.data,
          password,
          country, currency, language, clientUid: store.state.clientUid
        }
      }
    }
    catch (e) {
      console.log('prod ', e.response ? e.response.status : e);
      // redirect('/404');
      throw e;
    }
  },
  data() {
    return {
      order: null,
      orderCancelDialogVisible: false,
      password: null,
      country: null,
      currency: null,
      language: null,
      clientUid: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Scroll to the top of the page after the component has been loaded
      vm.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    });
  },

  computed: {
    orderStatus(){
      const orderStatusMap = {
        'TEMP': 'Temporary',
        'ORDERED': 'Ordered',
        'PAID': 'Paid',
        'COMPLETED': 'Completed',
        'CANCELLED': 'Cancelled',
        'FAILED': 'Failed',
      }

      if(orderStatusMap.hasOwnProperty(this.order.status)){
        return orderStatusMap[this.order.status];
      }

      return 'Unknown';
    }
  },

  methods: {
    priceFormat,
    cancelOrderDialogClose(){
      this.orderCancelDialogVisible = false;
    },
    cancelOrder(force = false){
      console.log('cancel order', force);
      if(!force){
        this.orderCancelDialogVisible = true;
        return;
      }

      this.orderCancelDialogVisible = false;

      const url = process.env.newApiUrl + '/v1/orders/cancel/' + this.order.seq;
      axios.put(url, {
        password: this.password
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-County-Code': this.country,
          'X-Currency-Code': this.currency,
          'X-Language-Code': this.language,
          'X-Client-Uuid': this.clientUid || null,
        }
      })
        .then((res) => {
          this.order = res.data.data;
          window.scrollTo(0, 0);
        })
        .catch((e) => {
          alert('Failed to cancel order')
        });

    },
    orderItemStatus(status){
      const orderItemStatusMap = {
        'TEMP': 'Temporary',
        'ORDERED': 'Ordered',
        'PAID': 'Paid',
        'SHIPPED': 'Shipped',
        'EXCHANGE': 'Exchange',
        'RETURN': 'Return',
        'COMPLETED': 'Completed',
        'CANCELLED': 'Cancelled',
        'FAILED': 'Failed',
      }

      if(orderItemStatusMap.hasOwnProperty(status)){
        return orderItemStatusMap[status];
      }

      return 'Unknown';
    }
  },
  head(){
    return {
      title: 'Order Complete | sneakers123.com',
      meta: [
        {hid: 'description', name: 'description', content: 'Order Complete | sneakers123.com'},
        {hid: 'robots', name: 'robots', content: 'noindex'}
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  }
}
</script>

<style scoped lang="scss">
.order-complete{
  padding: 20px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 0 16px;
  }
}

.order-complete-wrap{

  background: #fff;
  border: 1.5px solid #dfe6f0;
  border-radius: 12px;
  margin-bottom: 8px;
  padding: 20px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    padding: 16px;
  }
}

.order-complete-wrap > .row .order-detail-box{
  margin-bottom: 20px;
  border-bottom: 1.5px solid #dfe6f0;
}
.order-complete-wrap > .row:last-child .order-detail-box{
  margin-bottom: 0;
  border-bottom: none;
}

.order-detail-box{
  padding: 20px;

  @media (max-width: 767px) {
    padding: 16px;
  }
}

.order-item{
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;

  border: 1.5px solid #dfe6f0;
  border-radius: 12px;
  padding: 20px;

  @media (max-width: 767px) {
    padding: 16px;
  }
}

.order-item-status{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #23d1d8;
  margin-left: auto;
}
.order-item-image{
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.order-item-image img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
}
.order-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.order-item-info .order-item-title{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;

  @media (max-width: 767px) {
    max-width: 170px;
  }
}

.order-item-info .order-item-sku{
  font-size: 12px;
  font-weight: normal;
  color: #999;
  margin-bottom: 5px;
}

.order-item-info .order-item-price{
  font-size: 14px;
  font-weight: bold;
  span {
    font-size: 10px;
    font-weight: normal;
    color: #999;
  }
}

.order-detail-box-title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.order-detail-box-info-item{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  text-align: left;
}
.order-detail-box-info-item .order-detail-box-info-item-title{
  min-width: 100px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}
.order-detail-box-info-item .order-detail-box-info-item-value{
  font-size: 14px;
  font-weight: normal;
}
</style>