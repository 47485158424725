<script>
import UDialog from "@/components/ui/dialog.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import {getCountry, getCountryList} from "@/lib/utils";
import {debounce} from "lodash-core";

export default {
  name: "mobile-search-dialog",
  components: {UDialog},
  data() {
    return {
      searchInput: "",
      searchFocus: false,
      result: [],
      searchString: this.$store.state.searchString,
      initFocus: false,
      mobileSearchInputRef: "mobileSearchInputRef",
      initMounted: false,
    }
  },
  props: {
  },
  computed: {
    ...mapState(["totalSneakersCount"]),
    ...mapState(["mobileSearchDialogVisible"]),
    ...mapState(['previousSearches']),
    onSearch() {
      return this.searchFocus && ((this.searchInput && this.showResult) || (this.previousSearches && this.previousSearches.length > 0));
    },
  },
  mounted() {
    this.initMounted = true;
  },

  watch: {

    searchFocus(val)
    {
      if (val) {
        window.document.body.classList.add("body-scroll-lock");
      } else {
        window.document.body.classList.remove("body-scroll-lock");
      }
    },
    mobileSearchDialogVisible(val)
    {

      if (val && !this.initFocus && this.initMounted) {

        //search input focus in delay 500ms
        setTimeout(() => {
          this.initFocus = true;
          this.$refs.mobileSearchInputRef.focus();
        }, 500);
      }
    }
  },
  methods: {
    ...mapMutations(["setMobileSearchDialogVisible"]),
    ...mapActions(['setSearchString']),
    ...mapMutations(['deletePreviousSearch', 'clearPreviousSearches']),
    inputFocus()
    {
      this.$refs.mobileSearchInputRef.focus();
    },
    searchClear()
    {
      this.searchInput = '';
      this.showResult = false;
      this.searchFocus = false;
      //search input focus out
      this.$nextTick(() => {
        this.$refs.mobileSearchInputRef.blur();
      });
    },
    onChange: debounce(function (event) {
      this.searchInput = event.target.value;
      this.$axios.get(process.env.apiUrl + 'search-suggestion?q=' + this.searchInput).then(({data}) => {
        this.result = data;
        this.showResult = data.length > 0;
      }).catch((e) => {
        console.log('Suggestion error: ' + e)
      });
    }, 150),
    clearSearchHistory()
    {
      this.searchClear();
      this.clearPreviousSearches();
    },
    clearSearchHistoryItem(item)
    {
      this.deletePreviousSearch(item);
    },
    search(search)
    {
      this.searchClear()
      this.searchString = search;
      document.getElementsByTagName("BODY")[0].scrollIntoView({behavior: "smooth"});
      this.setSearchString(search);
      this.closeDialog();
      this.navigateSneaker();
    },

    closeDialog() {
      this.searchClear();
      this.result = [];
      this.initFocus = false;
      this.setMobileSearchDialogVisible(false);
    },

  },
}
</script>

<template>
  <u-dialog :show="mobileSearchDialogVisible" :onClose="closeDialog" size="full" noHeader noPadding>
    <div class="mobile-search-container">
      <div class="mobile-search-input-wrap">
        <div class="mobile-search-close" @click="closeDialog">
          <img src="~/assets/icons/ui/arrow-right.svg" alt="close" />
        </div>
        <div class="mobile-search-input">
          <input type="text" :placeholder="$t('placeholders.searchbar', {total: $store.state.totalSneakersCount})"
                 v-model="searchInput"
                 @keydown.enter.prevent="search(searchInput)"
                 @focus="searchFocus = true"
                 @keyup="onChange"
                 :ref="mobileSearchInputRef" />
        </div>

        <div class="mobile-search-header-clear" v-show="searchInput" @click="searchClear">
          <img src="~/assets/icons/ui/input-clear.svg" alt="close" />
        </div>
      </div>
      <div class="mobile-search-content">
        <ul class="mobile-search-result-list">
          <li class="mobile-search-result-item" v-for="item in result" :key="item" @click="search(item)">
            <div class="mobile-search-item-prefix">
              <img src="~/assets/icons/ui/search-result.svg" alt="search-result" />
            </div>
            <div class="mobile-search-item-text">{{item}}</div>
            <div class="mobile-search-item-suffix">
              <img src="~/assets/icons/ui/search-arrow.svg" alt="search-arrow" />
            </div>
          </li>
        </ul>

        <div class="mobile-search-history-wrap" v-if="previousSearches.length > 0">
          <div class="mobile-search-history-title">
            {{$t('previous-searches')}}
            <div class="mobile-search-history-clear" @click="clearSearchHistory">
              {{$t('Clear')}}
            </div>
          </div>
          <ul class="mobile-search-history-list">
            <li class="mobile-search-history-item" v-for="item in previousSearches" :key="item">
              <div class="mobile-search-item-prefix">
                <img src="~/assets/icons/ui/search-history.svg" alt="search-history" />
              </div>
              <div class="mobile-search-item-text" @click="search(item)">{{item}}</div>
              <div class="mobile-search-item-suffix pointer" @click="clearSearchHistoryItem(item)">
                <img src="~/assets/icons/ui/search-history-delete.svg" alt="search-history-delete" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </u-dialog>
</template>

<style scoped lang="scss">
.mobile-search-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0;
  border-radius: 0;
  position: relative;
  .mobile-search-input-wrap{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #dfe6f0;
    .mobile-search-close{
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 8px;
        height: 14px;
        transform: rotate(180deg);
      }
    }
    .mobile-search-header-clear{
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 16px;
        height: 16px;
      }
    }
    .mobile-search-input{
      flex: 1;
      input{
        width: 100%;
        padding: 10px 0;
        border: none;
        outline: none;
      }
    }
  }


  .mobile-search-content{
    margin-top: 56px;
    overflow-y: auto;
    height: calc(100vh - 56px);
    ul{
      padding: 0;
      margin: 0;
    }
    .mobile-search-result-list{
      list-style: none;
      .mobile-search-result-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #dfe6f0;
      }
    }

    .mobile-search-history-wrap{
      .mobile-search-history-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        font-size: 16px;
        font-weight: 800;
        color: #191919;
        .mobile-search-history-clear{
          margin-left: auto;
          cursor: pointer;
        }
      }
      .mobile-search-history-list{
        list-style: none;
        .mobile-search-history-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 16px;
          border-bottom: 1px solid #dfe6f0;
        }
      }
    }




    .mobile-search-item-prefix, .mobile-search-item-suffix{
      display: flex;
      align-items: center;
      img{
        width: 18px;
        height: 18px;
      }
    }
    .mobile-search-item-text{
      font-size: 16px;
      font-weight: normal;
      color: #191919;
      flex: 1;
      margin-left: 12px;
    }
    .mobile-search-item-suffix{
      img{
        width: 10px;
        height: 10px;
      }
    }
  }
}
</style>