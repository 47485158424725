<template>
    <div class="shop-price-section">
        <div class="d-md-flex align-items-start">
            <div class="reseller-box">
                <div class="custom_tooltip">
                    <img src="@/assets/img/reseller-2.png" width="24" height="24" alt="reseller icon" v-if="product.shop.reseller">
                    <div class="custom-tooltip-body top">
                        {{$t('reseller-text')}}
                    </div>
                </div>
                <!-- <img src="@/assets/img/reseller-2.png" width="24" height="24" alt="reseller icon" :title="$t('reseller-text')" data-placement="top" data-toggle="tooltip" v-if="product.shop.reseller"> -->
            </div>
            <div class="price-section-wrap">
                <template v-if="product.sale_price">
                    <div class="sale-price">
                        <span class="old-price">{{ getProductPrice }}</span> 
                        {{ getSalePrice }}
                    </div>
                </template>
                <template v-else>
                    <div class="new-price">{{ getProductPrice }}</div>
                </template>
            </div>
        </div>
        <a target="_blank"
            :href="localePath({name: 'new-go', query: goProductQuery})"
            class="buy-now-btn d-md-flex d-none text-uppercase align-items-center">
            {{$t('shop-now-text')}}
            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#ffffff"></path> </g></svg>
        </a>
    </div>
</template>

<script>
    import Shipping   from "./Shipping";
    import {mapState, mapGetters} from 'vuex';
    import redirectMixin from '../../plugins/redirectMixin.js';

    export default {
        props     : ['product'],
        components: {Shipping},
        mixins: [redirectMixin],
        computed  : {
           // ...mapState(['user']),
            ...mapGetters(['currency']),
            lnk() {
                return this.product.shop_url
            },
          lnkProduct() {
                return {
                    url: this.product.shop_url,
                    productId: this.product.snkProductId || this.product.id
                }
            },
            url() {
                return this.product.url
            },
            partner() {
                return this.product.shop.name
            },

            // userPrice()
            // {
            //     let cur = this.$store.getters.currency;
            //     if (this.product.shop.currency != cur.toUpperCase()) {
            //         return this.$root.price(this.product['price_' + cur], cur)
            //     }
            // },
            getProductPrice()
            {
                let currency = this.currency;

                let convertedPrice = this.product['price_' + currency.toLowerCase()];
                if (convertedPrice != false || convertedPrice != undefined)
                    return this.$root.price(convertedPrice, currency);

                return '-';
            },
            getSalePrice()
            {
                let currency = this.currency;
                let convertedSalePrice = this.product['sale_price_' + currency.toLowerCase()];
                if (convertedSalePrice != false || convertedSalePrice != undefined)
                    return this.$root.price(convertedSalePrice, currency);

                return '-';
            },
        }
    }
</script>
