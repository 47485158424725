<script>
export default {
  name: "u-breadcrumbs",
  props: {
    items: { type: Array, default: () => [] },
  },
  computed: {
  }
}
</script>

<template>
  <div class="u-breadcrumbs">
    <div class="breadcrumbs-wrap">
      <div v-for="(item, index) in items" :key="index" class="breadcrumbs-item">
        <a :href="item.href" class="breadcrumbs-link">{{ item.label }}</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.u-breadcrumbs {
  display: flex;
  flex-direction: column;
  .breadcrumbs-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 60px;
    align-items: center;
    a {
      text-decoration: none;
      color: #8999b1;
      font-size: 12px;
      font-weight: 400;

      &:hover {
        color: #191919;
      }
    }

    .breadcrumbs-item {
      font-size: 12px;
      font-weight: 400;
      color: #8999b1;
      height: 14px;

      &:after {
        content: "/";
        margin-left: 8px;
        margin-right: 8px;
        color: #8999b1;
      }

      .breadcrumbs-link {
        text-decoration: none;
      }



      &:last-child {
        margin-right: 0;
        color: #191919;
        font-weight: 700;

        &:after {
          content: "";
          margin-left: 0;
          margin-right: 0;
        }

        .breadcrumbs-link {
          color: #191919;
        }
      }

    }
  }

  /** mobile **/
  @media (max-width: 767px) {
    .breadcrumbs-wrap {
      min-height: 40px;
      margin-bottom: 16px;
      .breadcrumbs-item {
        &:last-child {
          margin-right: auto;
        }
      }
    }
  }
}

</style>