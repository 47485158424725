<script>
import HomeSquareImage from "./components/home-square-image.vue";
import HomeSectionTitle from "./components/home-section-title.vue";

export default {
  name: "HomeStepSection",
}
</script>

<template>
  <section
      class="flex flex-col w-full border-b border-black justify-center items-center"
  >
    <div class="flex flex-col w-full p-10 lg:p-20 justify-center items-center space-y-4 pb-0">
      <h2 class="text-3xl font-bold text-center max-w-96">{{$t('text.homePage.homeStepSectionTitle')}}</h2>
    </div>
    <div class="flex flex-col lg:flex-row w-full p-4 lg:p-8 justify-center items-center space-y-4 lg:px-32">
      <div class="flex flex-col w-full lg:w-1/3 p-8 justify-center items-center space-y-4 lg:px-8">
        <h3 class="text-2xl font-bold text-center max-w-96">1. {{$t('Search')}}</h3>
        <p class="text-lg text-center max-w-96">{{$t('homepage.search')}}</p>
      </div>

      <div class="flex flex-col w-full lg:w-1/3 p-4 lg:p-8 justify-center items-center space-y-4">
        <h3 class="text-2xl font-bold text-center max-w-96">2. {{$t('Find')}}</h3>
        <p class="text-lg text-center max-w-96">{{$t('homepage.find')}}</p>
      </div>

      <div class="flex flex-col w-full lg:w-1/3 p-4 lg:p-8 justify-center items-center space-y-4">
        <h3 class="text-2xl font-bold text-center max-w-96">3. {{$t('Buy')}}</h3>
        <p class="text-lg text-center max-w-96">{{$t('homepage.buy')}}</p>
      </div>
    </div>
  </section>
</template>
