var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full"},[_c('ui-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_vm._v(" "),_c('div',{staticClass:"border-b border-black py-[72px] text-center"},[(_vm.filledSearchString)?_c('h1',{staticClass:"text-2xl font-bold text-font-line-color"},[_vm._v("'"+_vm._s(_vm.filledSearchString)+"'")]):_c('h1',{staticClass:"text-2xl font-bold text-font-line-color"},[_vm._v("'Sneaker'")])]),_vm._v(" "),_c('div',{staticClass:"flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black"},[_c('UiButton',{attrs:{"click":_vm.toggleReleaseListFilter,"width":300}},[_vm._v("FILTER")]),_vm._v(" "),_c('UiSelect',{attrs:{"click":(selected) => {
      this.querySort = selected.value;
    },"width":300,"title":_vm.$t('Sort by'),"items":[
        {
          label: _vm.$t('sort.relevant'),
          value: 'relevant'
        },
        {
          label: _vm.$t('sort.latest'),
          value: 'latest'
        },
        {
          label: _vm.$t('sort.high'),
          value: 'high'
        },
        {
          label: _vm.$t('sort.low'),
          value: 'low'
        }
    ]}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black"},[_c('p',{staticClass:"text-xs font-inter text-font-line-color"},[_vm._v(_vm._s(_vm.$t('labels.showing', {total: _vm.numberFormat(_vm.loadedSneakers.total, 0)})))])]),_vm._v(" "),(_vm.loadedSneakers.total > 0)?_c('div',{staticClass:"flex flex-row flex-wrap w-full [&>*:nth-child(odd)]:border-r"},_vm._l((_vm.loadedSneakers.data),function(sneaker,i){return (_vm.placeSneaker(i))?_c('div',{key:i,staticClass:"flex lg:border-r border-b border-black lg:last:border-r-0 basis-1/2 lg:basis-1/4"},[_c('ReleaseThumbnail',{attrs:{"release":sneaker}})],1):_vm._e()}),0):_vm._e(),_vm._v(" "),(_vm.loadedSneakers.total === 0)?_c('div',{staticClass:"flex flex-row items-center px-6 py-[200px] justify-center gap-4 border-b border-black"},[_c('p',{staticClass:"text-xs font-inter text-font-line-color"},[_vm._v(_vm._s(_vm.$t('No sneakers found matching your criteria')))])]):_vm._e(),_vm._v(" "),(_vm.loadedSneakers.total > 0)?_c('div',{staticClass:"flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black"},[_c('div',{staticClass:"w-full lg:min-w-[800px]"},[_c('b-pagination-nav',{staticClass:"sneaker-pagination",attrs:{"value":_vm.queryPage,"number-of-pages":Math.ceil(_vm.loadedSneakers.total/_vm.loadedSneakers.per_page),"align":"center","link-gen":_vm.linkGen,"size":"md","use-router":""},on:{"input":_vm.goToTop}})],1)]):_vm._e(),_vm._v(" "),(_vm.seoText &&  _vm.loadedSneakers.data && _vm.loadedSneakers.data.length < 61)?_c('div',{staticClass:"flex flex-row items-center px-6 py-4 justify-center gap-4 border-b border-black bg-black text-white"},[_c('div',{staticClass:"w-full"},[_c('link-parser',{attrs:{"content":_vm.seoHTML}})],1)]):_vm._e(),_vm._v(" "),_c('release-list-filter-drawer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }