<template>
		<div class="release-alert" @click.prevent="click">
			<span>
				<div class="custom_tooltip">
					<img src="~/assets/icons/ui/bell.svg" alt="bell icon" class="bell-icon" v-if="!alert" />
          <img src="~/assets/icons/ui/bell-fill.svg" alt="bell icon" class="bell-icon" v-else />
					<div class="custom-tooltip-body left">
						{{ !alert ? $t('Notify about price') : $t('Remove price alert') }}
					</div>
				</div>
			</span>
		</div>
</template>

<style scoped lang="scss">
  .release-alert {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border: 1px solid #e0ecf5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    .bell-icon {
      width: 14px;
      height: 15px;
    }
  }
</style>

<script>
	import {mapMutations, mapActions} from 'vuex'

	export default {
		name : 'release-alert',
		props: ['sneaker'],
		computed: {
			alert() {
				return  this._.indexOf(this.$store.state.notifications, this.sneaker.slug) !== -1
			}
		},
		methods: {
			...mapMutations(['setChosenForNotify']),
            ...mapActions(['removeNotification']),
			async click() {
				if (!this.alert) {
					this.setChosenForNotify(this.sneaker);
					this.$root.$emit('bv::show::modal','notificationModal');
				} else {
					try {
                        this.removeNotification(this.sneaker.slug);
					}
					catch (e) {
						console.log(e);
					}
				}
			}
		}
    }
</script>
