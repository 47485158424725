<template>
  <div class="search-input-wrap" :style="{maxWidth: maxWidth ? maxWidth + 'px' : '100%'}">
    <div class="search-header-overlay" v-show="onSearch || searchFocus" @click="searchClear"></div>
    <div class="search-header-input-wrap" :class="{ 'on-search': onSearch, 'input-search': !!searchInput || searchFocus }">
      <div class="search-header-input-icon">
        <img src="~/assets/icons/search.svg" alt="search" class="search-header-input-icon-img" />
      </div>
      <div class="search-header-mobile-fake-input" v-if="$device.isMobile" @click="checkMobileSearch">
        {{$t('placeholders.searchbar', {total: $store.state.totalSneakersCount})}}
      </div> <!-- mobile fake input -->
      <input type="text" class="form-control" :placeholder="$t('placeholders.searchbar', {total: $store.state.totalSneakersCount})"
             v-if="!$device.isMobile"
             v-model="searchInput"
             @keydown.enter.prevent="search(searchInput)"
             @focus="searchFocus = true"
             :ref="searchInputRef"
      />

      <div class="search-header-clear" v-show="searchInput" @click="searchClear">
        <img src="~/assets/icons/ui/input-clear.svg" alt="close" />
      </div>

      <div class="search-result-list-wrap" v-show="onSearch" :style="{maxHeight: maxHeight ? maxHeight + 'px' : 'auto'}">
        <ul class="search-result-list">
          <li class="search-result-item" v-for="item in result" :key="item" @click="search(item)">
            <div class="item-prefix">
              <img src="~/assets/icons/ui/search-result.svg" alt="search-result" />
            </div>
            <div class="item-text">{{item}}</div>
            <div class="item-suffix">
              <img src="~/assets/icons/ui/search-arrow.svg" alt="search-arrow" />
            </div>
          </li>
        </ul>

        <div class="search-history-wrap" v-if="previousSearches.length > 0">
          <div class="search-history-title">
            {{$t('previous-searches')}}
            <div class="search-history-clear" @click="clearSearchHistory">
              {{$t('Clear')}}
            </div>
          </div>
          <ul class="search-history-list">
            <li class="search-history-item" v-for="item in previousSearches" :key="item">
              <div class="item-prefix">
                <img src="~/assets/icons/ui/search-history.svg" alt="search-history" />
              </div>
              <div class="item-text" @click="search(item)">{{item}}</div>
              <div class="item-suffix pointer" @click="clearSearchHistoryItem(item)">
                <img src="~/assets/icons/ui/search-history-delete.svg" alt="search-history-delete" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <mobile-search-dialog />
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {debounce} from "lodash-core";
import MobileSearchDialog from "@/components/new/layouts/mobile-search-dialog.vue";

export default {
  name: "search-input",
  components: {MobileSearchDialog},
  props: {
    maxWidth: {
      type: Number,
      default: 0
    },
    maxHeight: {
      type: Number,
      default: 550
    }
  },
  data() {
    return {
      searchInput: "",
      searchFocus: false,
      searchString: this.$store.state.searchString,
      searchInputRef: "searchInput",
      result: [],
      showResult: false,
    }
  },
  watch: {
    searchInput(val)
    {
      this.onChange();
    },

    searchFocus(val)
    {
      if (val) {
        window.document.body.classList.add("body-scroll-lock");
      } else {
        window.document.body.classList.remove("body-scroll-lock");
      }
    }
  },
  computed: {
    ...mapState(['previousSearches']),
    ...mapState(['totalSneakersCount', 'viewedSneakers']),
    ...mapState(['mobileSearchDialogVisible']),
    onSearch() {
      return !this.mobileSearchDialogVisible && this.searchFocus && ((this.searchInput && this.showResult) || (this.previousSearches && this.previousSearches.length > 0));
    },
  },
  methods: {
    ...mapActions(['setSearchString']),
    ...mapMutations(['deletePreviousSearch', 'clearPreviousSearches']),
    checkMobileSearch()
    {
      if(this.$device.isMobile && !this.$store.state.mobileSearchDialogVisible)
      {
        this.$store.commit('setMobileSearchDialogVisible', true);
        this.searchClear();
      }
    },
    searchClear()
    {
      this.searchInput = '';
      this.showResult = false;
      this.searchFocus = false;
      //search input focus out
      this.$nextTick(() => {
        if (this.$refs.searchInput){
          this.$refs.searchInput.blur();
        }
      });
    },
    onChange: debounce(function () {
      this.$axios.get(process.env.apiUrl + 'search-suggestion?q=' + this.searchInput).then(({data}) => {
        this.result = data;
        this.showResult = data.length > 0;
      }).catch((e) => {
        console.log('Suggestion error: ' + e)
      });
    }, 150),
    clearSearchHistory()
    {
      this.searchClear();
      this.clearPreviousSearches();
    },
    clearSearchHistoryItem(item)
    {
      this.deletePreviousSearch(item);
    },
    search(search)
    {
      this.searchClear()
      this.searchString = search;
      document.getElementsByTagName("BODY")[0].scrollIntoView({behavior: "smooth"});
      this.setSearchString(search);
      this.navigateSneaker();
    },
  },
}
</script>

<style lang="scss" scoped>
.search-input-wrap{
  flex:1;
  .search-header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    background: rgba(0, 0, 0, 0.4);
  }

  .search-header-input-wrap {
    z-index: 2;
    display: flex;
    flex: 1;
    align-items: center;
    background: #f4f5f9;
    border-radius: 20px;
    height: 48px;
    padding: 0 15px;
    margin-right: 20px;
    position: relative;

    &.on-search {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.input-search {
      z-index: 999;
      background: #fff;
      input {
        -webkit-box-shadow: 0 0 0 30px #fff inset;
        -webkit-text-fill-color: #191919;
        color: #191919;
      }
    }

    .search-result-list-wrap {
      position: absolute;
      top: 43px;
      left: 0;
      width: 100%;
      background: #fff;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;


      padding: 16px;
      padding-top: 0;
      max-height: 550px;
      overflow-x: hidden;
      overflow-y: auto;
      .search-result-list, .search-history-list {
        padding: 0;
        z-index: 999;
        margin-top: 5px;
        list-style: none;
        li {
          padding: 10px 15px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          &:hover {
            background: #f4f5f9;
          }

          .item-text{
            font-size: 16px;
            font-weight: normal;
            color: #191919;
            flex: 1;
            margin-left: 12px;
          }
          .item-prefix, .item-suffix {
            display: flex;
            align-items: center;
            img {
              width: 18px;
              height: 18px;
            }

            &.pointer{
              cursor: pointer;
            }
          }

          .item-suffix {
            img {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .search-history-wrap {
        .search-history-title {
          padding: 0 16px;
          font-size: 16px;
          font-weight: 800;
          color: #191919;

          .search-history-clear {
            cursor: pointer;
            float: right;
            font-size: 14px;
            font-weight: 600;
            color: #8999b1;
          }
        }
      }
    }

    .search-header-mobile-fake-input {
      font-size: 16px;
      font-weight: normal;
      color: #8999b1;
      flex: 1;
      cursor: pointer;
    }
    .search-header-input-icon {
      margin-right: 10px;
      .search-header-input-icon-img {
        width: 18px;
        height: 18px;
      }
    }
    .search-header-clear {
      position: absolute;
      right: 30px;
      top: 12px;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
    input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #fff inset ; -webkit-text-fill-color: #000; }
    input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { transition: background-color 5000s ease-in-out 0s; }
    input {
      border: none;
      padding: 0;
      width: 100%;
      background: none;
      outline: none;
      overflow: visible;
      font-size: 16px;
      font-weight: normal;
      color: #191919;
      -webkit-box-shadow: 0 0 0 30px #f4f5f9 inset;
      -webkit-text-fill-color: #8999b1;

      &:focus {
        outline: none !important;
        transition: background-color 5000s ease-in-out 0s;
      }

    }
  }

  /* mobile */
  @media (max-width: 767px) {
    .search-header-input-wrap{
      margin-right: 0 !important;
      height: 40px;

      .search-result-list-wrap{
        top: 40px !important;
        max-height: 60vh;
      }
    }

  }
}
</style>