<script>
import {priceFormat} from "../../../lib/utils";
import redirectMixin from "@/plugins/redirectMixin";

export default {
  name: "product-info-related",
  props: {
    products: { type: Array, default: [] },
  },
  mixins: [redirectMixin],
  data(){
    return {
      lnkProductId: null,
      lnkProductUrl: null,
      lnkCatalogProductId: null,
    }
  },
  computed: {

    lnkProduct() {
      return {
        url: this.lnkProductUrl,
        productId: this.lnkProductId || null,
        catalogProductId: this.lnkCatalogProductId || null
      }
    },
    currency() {
      return this.$store.state.currency;
    },
    minPrice() {
      let min = 0;
      let display = '';

      this.products.forEach((product) => {
        const price = product.exchange.salePrice || product.exchange.price;
        const displayPrice = product.exchange.salePriceDisplay || product.exchange.priceDisplay;
        if (min === 0) {
          min = price;
          display = displayPrice;
        } else if (price < min) {
          min = price;
          display = displayPrice;
        }
      });

      return display;

    },

    maxPrice() {
      let max = 0;
      let display = '';

      // console.log('products', this.products);
      this.products.forEach((product) => {
        const price = product.exchange.salePrice || product.exchange.price;
        const displayPrice = product.exchange.salePriceDisplay || product.exchange.priceDisplay;
        if (max === 0) {
          max = price;
          display = displayPrice;
        } else if (price > max) {
          max = price;
          display = displayPrice;
        }
      });

      return display;
    }
  },
  methods: {
    priceFormat,
    goToProduct(product) {
      this.lnkProductId = null;
      this.lnkCatalogProductId = null;
      this.lnkProductUrl = null;

      this.lnkProductId = product.snkProductId || null;
      this.lnkCatalogProductId = this.lnkProductId ? null : (product.id || null);
      this.lnkProductUrl = product.deeplink;

      const storeURL = this.localePath({name: 'new-go',query: this.goProductQuery });

      //go to new window
      window.open(storeURL, '_blank');
      this.$router.push(this.localePath({path: `/product/${product.catalogId}-${product.crawlerId}`}));
    }
  }
}
</script>

<template>
  <div class="product-info-related-wrap" v-if="products.length > 2">
    <div class="product-info-related-title">
      {{ $t('text.productPage.youMightAlsoLike') }}
    </div>
    <div class="product-info-subtitle">
      {{ $t('text.productPage.discoverUnbeatablePricesRangesStartingFromJustUpTo', {minPrice: minPrice, maxPrice: maxPrice}) }}
    </div>
    <div class="product-info-related">
      <div class="product-info-related-item" v-for="(product, index) in products" :key="index" v-show="index < 4" @click="goToProduct(product)">
        <div class="product-info-related-item-image">
          <img :src="product.coverImageUrl" alt="product-image" />
        </div>
      </div>

      <div class="product-info-related-item more" @click="$parent.goToRelatedProduct()">
        <div class="product-info-related-item-image">
          <img src="~/assets/icons/ui/more-dot.svg" alt="more" />
        </div>
      </div>
  </div>
  </div>
</template>

<style lang="scss" scoped>
.product-info-related-wrap{
  display: flex;
  flex-direction: column;

  .product-info-related-title{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 8px;
    color: #191919;
  }

  .product-info-subtitle{
    font-size: 14px;
    font-weight: normal;
    color: #000;
    margin-bottom: 16px;
  }

  .product-info-related{
    display: flex;
    flex-direction: row;

    .product-info-related-item{
      flex: 1;
      margin-right: 8px;
      border-radius: 12px;
      border: solid 1.5px #dfe6f0;
      display: flex;
      cursor: pointer;
      z-index: 2;

      &:last-child{
        margin-right: 0;
      }

      &:hover{
        border: solid 1.5px #23d1d8;
      }
      .product-info-related-item-image{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        img{
          border-radius: 12px;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          width: auto;
          height: auto;
          aspect-ratio: 1 / 1;
        }
      }

      &.more{
        .product-info-related-item-image{
          img{
            border-radius: 0;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  /** mobile **/
  @media (max-width: 1023px) {
    display: none;
  }
}
</style>