<script>
export default {
  name: "UiSelect",
  props: {
    width: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
    title: {
      type: String,
      required: false,
    },
    click: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    initValue: {
      type: String,
      required: false,
    },
    paperHeight: {
      type: Number,
      required: false,
      default: 380,
    }
  },
  data() {
    return {
      selected: null,
      isOpen: false,
    }
  },
  mounted() {
    //영역 외 클릭시 닫힘
    document.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    });

    if(this.initValue) {
      this.selected = this.items.find(item => item.value === this.initValue);
    }
  },
  methods: {
    handleClick(item) {
      this.selected = item;
      this.click(this.selected);
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    }
  }
}
//'black': '#131416',
// 'gray-low': '#e8ebed',
//     'gray-medium': '#c9cdd2',
//     'gray-high': '#6c7781',
//     'danger': '#db3d45',
//     'primary': '#23d1d8',
</script>

<template>
  <div class="w-full relative" :style="{width: width ? (this.$device.isDesktop ? width + 'px' : '100%') : '100%'}">
    <button @click="toggle" :class="{
      'hover:underline rounded-xs w-full border-l border-r border-t relative': true,
      'bg-black text-white border-black': color === 'black' && !isOpen,
      'bg-white text-black border-black border-b': color === 'white' && !isOpen,
      'bg-gray-low text-black border-gray-low': color === 'gray-low' && !isOpen,
      'bg-gray-medium text-black border-gray-medium': color === 'gray-medium' && !isOpen,
      'bg-gray-high text-white border-gray-high': color === 'gray-high' && !isOpen,
      'bg-danger text-white border-danger': color === 'danger' && !isOpen,
      'bg-primary text-white border-primary': color === 'primary' && !isOpen,
      'bg-black text-gray-low border-gray-low': isOpen && color === 'gray-low',
      'bg-black text-gray-medium border-gray-medium': isOpen && color === 'gray-medium',
      'bg-black text-gray-high border-gray-high': isOpen && color === 'gray-high',
      'bg-white text-danger border-danger': isOpen && color === 'danger',
      'bg-white text-primary border-primary': isOpen && color === 'primary',
      'bg-white text-black border-black': isOpen && color === 'white',
      'h-6 text-xs px-2': size === 'xs',
      'h-8 text-sm px-3': size === 'sm',
      'h-10 text-base px-4': size === 'md',
      'h-12 text-lg px-5': size === 'lg',
    }">
      {{selected ? selected.label : title}}
      <img src="~/assets/images/ui/icons/select-arrow.png" :class="{
        'absolute top-0 bottom-0 m-auto': true,
        'w-2 right-1': size === 'xs',
        'w-4 right-2': size === 'sm',
        'w-6 right-2': size === 'md',
        'w-8 right-3': size === 'lg',
      }" v-if="!isOpen && color !== 'white'">

      <img src="~/assets/images/ui/icons/select-arrow-up.png" :class="{
        'absolute top-0 bottom-0 m-auto transform rotate-180': true,
        'w-2 right-1': size === 'xs',
        'w-4 right-2': size === 'sm',
        'w-6 right-2': size === 'md',
        'w-8 right-3': size === 'lg',
      }" v-if="!isOpen && color === 'white'">

      <img src="~/assets/images/ui/icons/select-arrow-up.png" :class="{
        'absolute top-0 bottom-0 m-auto': true,
        'w-2 right-1': size === 'xs',
        'w-4 right-2': size === 'sm',
        'w-6 right-2': size === 'md',
        'w-8 right-3': size === 'lg',
      }" v-if="isOpen">

    </button>
    <div class="flex flex-col w-full relative">
      <ul :class="{
        'absolute top-0 left-0 z-20 w-full border-l border-r border-b overflow-y-auto bg-white': true,
      'hidden': !isOpen,
      'border-black bg-white text-black': color === 'black',
      'border-black bg-white text-black': color === 'white',
      'border-gray-low bg-gray-low text-black': color === 'gray-low',
      'border-gray-medium bg-gray-medium text-black': color === 'gray-medium',
      'border-gray-high bg-gray-high text-white': color === 'gray-high',
      'border-danger bg-danger text-white': color === 'danger',
      'border-primary bg-primary text-white': color === 'primary',
      }"
      :style="{maxHeight: paperHeight + 'px'}"
      >
        <li v-for="item in items" v-show="selected ? item.value !== selected.value : true" @click="handleClick(item)"
            :class="{
                'flex cursor-pointer hover:bg-gray-200 w-full items-center justify-center': true,
                'h-6 text-xs px-2': size === 'xs',
                'h-8 text-sm px-3': size === 'sm',
                'h-10 text-base px-4': size === 'md',
                'h-12 text-lg px-5': size === 'lg',
            }">
          {{item.label}}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>