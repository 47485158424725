<script>
export default {
  name: "u-accordion",
}
</script>

<template>
  <div class="u-accordion">
    <div class="accordion-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.u-accordion {
  display: flex;
  flex-direction: column;
  .accordion-wrap {
    display: flex;
    flex-direction: column;
  }
}

</style>