<script>
import UContainer from "~/components/ui/container.vue";
import {priceFormat} from "../../../lib/utils";
import redirectMixin from "@/plugins/redirectMixin";

export default {
  name: "product-related",
  props: {
    products: { type: Array, default: [] },
  },
  mixins: [redirectMixin],
  data() {
    return {
      lnkProductId: null,
      lnkProductUrl: null,
      catalogProductId: null,
    }
  },

  computed: {
    lnkProduct() {
      return {
        url: this.lnkProductUrl,
        productId: this.lnkProductId || null,
        catalogProductId: this.lnkCatalogProductId || null
      }
    },
  },
  methods: {
    goToProduct(product) {
      this.lnkProductId = null;
      this.lnkCatalogProductId = null;
      this.lnkProductUrl = null;

      this.lnkProductId = product.snkProductId || null;
      this.lnkCatalogProductId = this.lnkProductId ? null : (product.id || null);
      this.lnkProductUrl = product.deeplink;

      const storeURL = this.localePath({name: 'new-go',query: this.goProductQuery });

      //go to new window
      window.open(storeURL, '_blank');
      this.$router.push(this.localePath({path: `/product/${product.catalogId}-${product.crawlerId}`}));
    },
    discount(price, salePrice) {
      return Math.round(((price - salePrice) / price) * 100) + "%";
    },
  },
  components: {UContainer},
}

</script>

<template>
  <div class="product-related-wrap-items">
    <div class="product-related-item" v-for="(item, index) in products" :key="index" @click="goToProduct(item)">
      <div class="product-related-item-discount-rate" v-show="item.price !== item.salePrice && item.salePrice > 0">
        -{{discount(item.price, item.salePrice)}}
      </div>
<!--      <div class="product-related-item-buttons">-->
<!--        <div class="product-button-alert">-->
<!--          <img src="~/assets/icons/ui/bell.svg" alt="product-button-alert" />-->
<!--        </div>-->
<!--        <div class="product-button-like">-->
<!--          <img src="~/assets/icons/ui/heart-mini.svg" alt="product-button-like" v-if="!item.isLike" />-->
<!--          <img src="~/assets/icons/ui/heart-mini-fill.svg" alt="product-button-like" v-else />-->
<!--        </div>-->
<!--      </div>-->
      <div class="product-related-item-image">
        <client-only>
        <img v-lazy-load :data-src="item.coverImageUrl" alt="product-related-item-image" />
        </client-only>
      </div>
      <div class="product-related-item-brand">
        {{ item.catalog.brand.name }}
      </div>
      <div class="product-related-item-name">
        {{ item.name }}
      </div>
      <div class="product-related-item-sku">
        {{ item.sku.sku }}
      </div>
      <div class="product-related-item-price-sale-price" v-if="item.price !== item.salePrice && item.salePrice > 0">
        <div class="product-related-item-price">
          {{item.exchange.salePriceDisplay}}
        </div>
        <div class="product-related-item-price-discount">
          {{item.exchange.priceDisplay}}
        </div>
      </div>
      <div class="product-related-item-price-only-price" v-else>
        <div class="product-related-item-price">
          {{item.exchange.priceDisplay}}
        </div>
      </div>

      <div class="product-related-item-from-shop">From. {{item.shop.name}}</div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.product-related-wrap-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 60px;
  margin-left: -8px;
  margin-right: -8px;

  .product-related-item {
    flex: 0 0 calc(25% - 8px);
    display: flex;
    position: relative;
    flex-direction: column;
    background: #fff;
    border-radius: 12px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;

    &:nth-child(4n) {
      margin-right: 0;
    }


    .product-related-item-from-shop{
      font-size: 12px;
      font-weight: normal;
      color: #8999b1;
      margin-top: 8px;
      margin-bottom: 16px;
      padding: 0 16px;
    }
    .product-related-item-brand {
      font-size: 14px;
      font-weight: 800;
      color: #191919;
      margin-top: 4px;
      padding: 0 16px;
    }

    .product-related-item-price-sale-price {
      display: flex;
      flex-direction: row;
      margin-top: 8px;
      padding: 0 16px;
      .product-related-item-price-discount {
        font-size: 12px;
        font-weight: normal;
        line-height: 22px;
        text-decoration: line-through;
        color: #191919;
      }

      .product-related-item-price {
        font-size: 14px;
        font-weight: 800;
        margin-right: 3px;
        color: #db3d46;
      }
    }
    .product-related-item-price-only-price {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      margin-top: 8px;
      .product-related-item-price {
        font-size: 14px;
        font-weight: 800;
        color: #191919;
      }


    }

    .product-related-item-name {
      font-size: 14px;
      font-weight: 800;
      color: #191919;
      margin-top: 4px;
      padding: 0 16px;

      /** 말줄임 **/
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      min-height: 20px;
      max-width: 280px;
    }

    .product-related-item-sku {
      font-size: 12px;
      font-weight: normal;
      color: #8999b1;
      margin-top: 8px;
      padding: 0 16px;
    }
    .product-related-item-discount-rate {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 1;
      display: flex;
      padding: 4px 8px;
      background: #ffceba;
      color: #db3d46;
      border-radius: 4px;
      font-size: 14px;
    }
    .product-related-item-buttons {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      padding: 16px;
      .product-button-alert {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        border: 1.5px solid #e0ecf5;
        margin-right: 6px;
        cursor: pointer;
      }
      .product-button-like {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        border: 1.5px solid #e0ecf5;
        cursor: pointer;
      }
    }

    .product-related-item-image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      img {
        border-radius: 12px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: auto;
        height: auto;
        aspect-ratio: 1 / 1;
      }
    }
  }

  /** mobile **/
  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 24px;

    .product-related-item {
      width: 168px;
      flex: 0 0 auto;
      &:last-child {
        margin-right: 0;
      }

      &:nth-child(4n) {
        margin-right: 8px;
      }

      .product-related-item-name {
        max-width: 100%;
      }
    }
  }
}
</style>