<script>
import formMixin from "../../../../../../plugins/formMixin";
import {mapActions, mapMutations} from "vuex";
import UiLabelInput from "../../../../ui/UiLabelInput.vue";
import UiButton from "../../../../ui/UiButton.vue";

export default {
  name: "sign-in-form",
  components: {UiButton, UiLabelInput},
  mixins: [formMixin],
  created() {
    this.$root.$on('change-sign-tab', this.closeDrawer);
  },
  data() {
    return {
      model: {
        email: '',
        password: ''
      },
      errors: {
        email: { status: null , message: null},
        password: { status: null , message: null},
        recaptcha_token: { status: null , message: null},
      },
      titles: {
        login: ('<span class="atention">'+this.$t('Hey!')+'</span> '+ this.$t('Welcome Back')),
        forgot: this.$t('Reset Password'),
        afterForgot: this.$t('We have e-mailed your password reset link!'),
        reset: this.$t('Reset Password'),
        afterReset: ('<span class="atention">'+this.$t('Perfect!')+'</span> '+this.$t('Your password has changed!')),
      },
      submitTexts: {
        login : this.$t('Login to your account'),
      },
    }
  },

  methods: {
    ...mapMutations(['setTTL', 'setOnceLoggedIn']),
    ...mapMutations(['setGlobalDrawerSection']),
    ...mapActions(['closeGlobalDrawer']),
    async submitLogin() {
      if (!this.checkForm()) {
        return
      }
      const reToken = await this.submitRecaptcha();

      this.$auth.loginWith('local', {
        data: {
          recaptcha_token: reToken,
          email: this.model.email,
          password: this.model.password
        }
      }).then(()=>{
        this.setTTL(process.env.tokenTTL)
        this.setOnceLoggedIn()
        this.$store.dispatch('getCountryInfo', this.$store.state.auth.user.country)
        this.$store.commit('setCurrency', this.$store.state.auth.user.currency)
        this.closeGlobalDrawer()
        this.closeDrawer()

      }).catch(e => {
        console.log('login page', e);
        this._.each(e.response.data.errors, (message, input) => {
          this.errors[input].status = true;
          this.errors[input].message = this._.join(message, '<br>');
        })
      })

    },
    checkForm() {
      this.checkEmail(this.model.email);
      this.checkPassword(this.model.password);
      return !this.errors.email.status && !this.errors.password.status
    },
    async submitRecaptcha() {
      let token = null;
      try {
        token = await this.$recaptcha.execute('login');
        // console.log('ReCaptcha login token:', token);
      } catch (error) {
        console.log('ReCaptcha error:', error);
      }
      return token;
    },

    closeDrawer() {
      this.model.email = '';
      this.model.password = '';
      this.errors.email.status = null;
      this.errors.password.status = null;
      this.errors.recaptcha_token.status = null;
    },
  }
}
</script>

<template>
  <form @submit.prevent="submitLogin" class="flex flex-col p-4">
    <div class="flex flex-col">
      <UiLabelInput :label="$t('label.yourEmail')" v-model="model.email" :error="errors.email" :required="true" type="email"></UiLabelInput>
      <UiLabelInput :label="$t('label.yourPassword')" v-model="model.password" :error="errors.password" :required="true" type="password"></UiLabelInput>
      <div class="flex justify-center items-center">
        <div class="text-sm cursor-pointer text-gray-high" @click="setGlobalDrawerSection('forgot-password')"
        >{{$t('Forgot Password?')}}</div>
      </div>
      <UiButton type="submit" class="mt-4" size="md" color="primary"
                :disabled="!model.email || !model.password">{{submitTexts.login}}</UiButton>
    </div>
  </form>
</template>

<style scoped>

</style>