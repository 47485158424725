<script>
import USelect from "~/components/ui/select.vue";
import UButton from "~/components/ui/button.vue";
import UAccordion from "~/components/ui/accordion.vue";
import ProductInfoRelated from "~/components/new/product/product-info-related.vue";
import UAccordionItem from "~/components/ui/accordion-item.vue";
import ProductDetailTable from "~/components/new/product/product-detail-table.vue";
import ProductInfoReturnPolicy from "~/components/new/product/product-info-return-policy.vue";
import redirectMixin from "@/plugins/redirectMixin";
import UDialog from "@/components/ui/dialog.vue";
import UDialogHeader from "@/components/ui/dialog-header.vue";
import UDialogContent from "@/components/ui/dialog-content.vue";
import UDialogFooter from "@/components/ui/dialog-footer.vue";

export default {
  name: "product-checkout-button",
  props: {
    product: {
      type: Object,
      required: true,
    },
    checkCountry: {
      type: Boolean,
      default: false,
    },
    shippingCountries: {
      type: Array,
      default: () => [],
    },

  },
  mixins: [redirectMixin],
  data() {
    return {
      alertDialog: false,
      sizeType: 'US',
      encodeSize: '',
    }
  },
  mounted() {
    this.encodeSize = this.lastSize ? btoa(this.lastSize) : ''
  },
  methods: {
    countryNativeName() {
      return this.$t(`countries.common.${this.$store.state.location.code}`);
    },
    countryCode() {
      return this.$store.state.location.code ? this.$store.state.location.code.toUpperCase() : null;
    },
    showDialog() {
      this.alertDialog = true;
    },
    closeDialog() {
      this.alertDialog = false;
    },
    goToCheckout(force = false){
      if (this.checkCountry && !force){
      // if (this.countryCode() && !this.shippingCountries.includes(this.countryCode().toUpperCase()) && this.checkCountry && !force) {
        this.showDialog();
        return;
      }

      this.closeDialog();
      this.$router.push(this.localePath({
        path: `/product/checkout/${this.product.id}?size=` + this.encodeSize,
      }));
    }
  },
  computed: {
    lnk() {
      return this.product.deeplink;
    },
    lnkProduct() {
      return {
        url: this.product.deeplink,
        productId: this.product.snkProductId || null,
        catalogProductId: this.product.id || null,
      }
    },
    url() {
      return this.product.url;
    },
    lastSize() {
      // const sizes = this.product.fakeSizes && this.product.fakeSizes.length > 0
      //     ? this.product.fakeSizes
      //     : this.product.mainSizes

      const sizes = this.product.mainSizes || [];
      if(sizes){
        const size = sizes[sizes.length - 1];

        if(size === 'No Size Available'){
          return 'One Size';
        }

        return size;
      }
      return 'One Size';
    },
  },
  components: {
    UDialogFooter,
    UDialogContent,
    UDialogHeader,
    UDialog,
    ProductInfoReturnPolicy,
    ProductDetailTable, UAccordionItem, ProductInfoRelated, UButton, USelect, UAccordion}
}
</script>

<template>
  <div class="product-option-checkout">
    <u-select v-show="lastSize" :bb="false" :value="`${lastSize}`" :options="[
        { label: `${lastSize}`, value: `${lastSize}` },
        {
          label: $t('text.showMoreSizes'),
          value: 'show more sizes',
          href: localePath({name: 'new-go',query: goProductQuery }),
          target: '_blank'
        }
      ]"></u-select>
    <u-button color="black" :bt="lastSize ? false : true" :click="() => goToCheckout()">{{$t('label.checkoutNow')}}</u-button>

    <u-dialog :show="alertDialog" :onClose="closeDialog" size="md" :title="$t('label.warning') + '!'">
      <u-dialog-content>
        <p>
          {{$t('text.shippingWarning')}}<br/>
<!--          {{$t('text.dontShip')}}<br/>-->
<!--          {{$t('text.theCountryYouAreCurrentlyAccessing', {-->
<!--          country: countryNativeName(),-->
<!--        })}}-->
        </p>
      </u-dialog-content>
      <u-dialog-footer>
        <u-button color="secondary" :block="false" :click="closeDialog" style="margin-right: 10px;">{{$t('label.cancel')}}</u-button>
        <u-button color="primary" :block="false" :click="() => goToCheckout(true)"
        >{{$t('label.checkoutNow')}}</u-button>
      </u-dialog-footer>
      </u-dialog>
  </div>
</template>

<style lang="scss" scoped>
.product-option-checkout {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
</style>