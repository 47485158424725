import { render, staticRenderFns } from "./product-detail-table.vue?vue&type=template&id=6e899460&scoped=true"
import script from "./product-detail-table.vue?vue&type=script&lang=js"
export * from "./product-detail-table.vue?vue&type=script&lang=js"
import style0 from "./product-detail-table.vue?vue&type=style&index=0&id=6e899460&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e899460",
  null
  
)

export default component.exports