<script>
export default {
  name: "home-section-title",
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
}
</script>

<template>
  <div class="flex flex-col w-full p-4 pt-12 lg:p-8 lg:pt-8">
    <h3 class="text-xl font-semibold text-[#131416] uppercase m-0">{{title}}</h3>
    <h4 class="text-xs text-[#131416] font-normal" v-if="subtitle">{{subtitle}}</h4>
  </div>
</template>

<style scoped>

</style>