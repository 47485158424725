<template>
  <article class="px-5 py-5 flex flex-col w-full bg-white border-b border-black break-words">
    <div class="line">
      <link-parser  :content="seoHTML"/>
    </div>
  </article>
</template>

<script>
    import {mapState} from 'vuex';
    import staticMetasMixin from "../plugins/staticMetasMixin"
    import SimpleHeader from "./SimpleHeader";
    import LinkParser from "./linkParser";

    export default {
        name: "footerLinkPage",
        components: {LinkParser, SimpleHeader},
        mixins: [staticMetasMixin],
        computed: {
            ...mapState(['currentRouteMetas', 'totalSneakersCount', 'totalShopsCount']),
            seoHTML() {
                return this.replaceShopAndSneakerCount(this.currentRouteMetas.seo_description)
            },
            schemaBreadcrumbs() {
                return {
                    script: [
                        {
                            hid: 'breadcrumbs',
                            type: 'application/ld+json',
                            innerHTML: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": process.env.appUrl + this.localePath('index'),
                                            "name": this.$t('Sneaker Search Engine')
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": process.env.appUrl + this.$route.path,
                                            "name": this.switchLocalePath('de')
                                        }
                                    },
                                ]
                            }, null, 2),
                        },
                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>