<script>
import {btoaImplementation} from "@/plugins/redirectMixin";

export default {
  name: "shop-thumbnail",
  props: ['shop'],
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    redirectUrl()
    {
      return this.localePath({name: 'new-go', query: {
          shopId: this.shop.id,
          url: btoaImplementation(this.linkToShop),
        } });
    },
    linkToShop()
    {
      if (!this._.isEmpty(this.shop.affiliate_link)) {
        if (this._.startsWith(this.shop.affiliate_link, '?')) {
          return (this.shop.website + this.shop.affiliate_link)
        }
        return this.shop.affiliate_link + encodeURI(this.shop.website);
      }
      return this.shop.website;
    },
  }
}
</script>

<template>
  <div class="flex flex-col w-1/2 lg:w-1/4 border-r border-b border-black">
    <a :href="redirectUrl" class="items-center justify-center w-full h-full flex flex-col" @mouseover="hover = true" @mouseleave="hover = false">
      <div class="flex items-center justify-center aspect-square flex-1 border-b border-black w-full">
        <img :src="shop.logo" :alt="shop.name" class="w-40 h-40 object-contain object-center"/>
      </div>
      <div :class="{
        'flex flex-col items-center justify-center px-2 py-4 w-full': true,
        'bg-[#f7f8f9]': hover,
      }">
        <span class="text-sm text-black font-bold">{{shop.name}}</span>
        <span class="text-gray-high text-xs">{{ $t('countries-abbr.' + shop.country) }}</span>
      </div>
    </a>
  </div>
</template>

<style scoped>

</style>