<template>
    <div></div>
</template>

<script>
    import atob from 'atob';
    import fs from 'fs';
    export default {
        name    : 'Redirecting',
        async asyncData({$auth, req, query, app, store, redirect})
        {
          const whiteListDomains = [
              'prf.hn',
              'linksynergy.com',
              'sjv.io',
              'pxf.io',
              'pvxt.net',
              'awin1.com',
              'ikhnaie.link',
              'prf.hn',
              'webgains.com',
              'sneakit.com',
              'dpbolvw.net',
              'jdoqocy.com',
              'tkqlhce.com',
              'kqzyfj.com',
              'anrdoezrs.net',
              'sportspar.de',
              'sneakermall.com',
              'shopifypreview.com',
              'farfetch.com'
          ];

          const url = query.url || null;
          const to = query.to || null;

          try{
            if(!to) {
              throw new Error('Invalid URL');
            }

            if(to){
              const decodedUrl = atob(to);
              for (const domain of whiteListDomains) {
                if (decodedUrl.includes(domain)) {
                  const locale = app.i18n.locale || 'de';

                  if(locale === 'de'){
                    return redirect('/new-go?url=' + to);
                  }else{
                    return redirect('/' + locale +'/new-go?url=' + to);
                  }
                }
              }
            }

            throw new Error('Invalid URL');
          } catch (e) {
            const blockUrlLogsFile = 'block-urls.log';
            const logMessage = '[' + new Date().toISOString() + '] ' + e.message + ' - ' + url + ' - ' + to + '\n';
            fs.appendFileSync(blockUrlLogsFile, logMessage);
            redirect('/');
          }
        },
    }
</script>