<template>
  <section class="flex flex-col lg:flex-row bg-black text-white py-6" v-show="!footerHide">
    <div class="flex flex-col lg:flex-row w-full lg:w-1/2 p-8">
      <div class="flex flex-col w-full lg:w-1/4 mb-4 lg:mb-0">
        <h3 class="text-base font-semibold mb-2">{{$t('About Sneakers123')}}</h3>
        <ul class="text-sm flex flex-col list-none gap-2">
          <li>
            <router-link :to="localePath('story')">{{$t('Story')}}</router-link>
          </li>
          <li>
            <router-link :to="localePath('contact')">{{$t('Contact')}}</router-link>
          </li>
        </ul>
      </div>
      <div class="flex flex-col w-full lg:w-1/4 mb-4 lg:mb-0">
        <h3 class="text-base font-semibold mb-2">{{$t('Legal')}}</h3>
        <ul class="text-sm flex flex-col list-none gap-2">
          <li><router-link :to="localePath('data-protection')">{{$t('Data Protection')}}</router-link></li>
          <li><router-link :to="localePath('terms')">{{$t('Terms and Conditions')}}</router-link></li>
          <client-only>
            <li v-if="$i18n.locale === 'en'"><router-link :to="localePath('return-policy')">{{$t('Return Policy')}}</router-link></li>
          </client-only>
          <li><router-link :to="localePath('imprint')">{{$t('Imprint')}}</router-link></li>
        </ul>
      </div>
      <div class="flex flex-col w-full lg:w-1/4 mb-4 lg:mb-0">
        <h3 class="text-base font-semibold mb-2">{{$t('Other')}}</h3>
        <ul class="text-sm flex flex-col list-none gap-2">
          <li><a href="/blog" v-if="$i18n.locale === 'de'">{{$t('Blog')}}</a></li>
          <li><router-link :to="localePath('models')">{{$t('All Models')}}</router-link></li>
        </ul>
      </div>
      <div class="flex flex-col w-full lg:w-1/4 mb-4 lg:mb-0">
        <h3 class="text-base font-semibold mb-2">{{$t('Social Media')}}</h3>
        <ul class="text-sm flex flex-col list-none gap-2">
          <li><a href="https://www.instagram.com/sneakers123com/" target=”_blank” rel="nofollow">Instagram</a></li>
        </ul>
      </div>
    </div>
    <div class="flex flex-col w-full lg:w-1/2 p-8 gap-8">
      <div>
        <h3 class="text-base font-semibold mb-2">{{$t('Download Sneakers123 App')}}</h3>
        <div class="flex flex-row gap-4">
          <div class="flex flex-col w-full lg:w-1/2">
            <button class="bg-black text-white p-3 rounded-lg border border-dark-grey flex items-center justify-center" @click="goToAppStore">
              <img src="~/assets/images/ui/download-ios-app.png" alt="download-ios-app" class="h-6 w-auto"/>
            </button>
          </div>
          <div class="flex flex-col w-full lg:w-1/2">
            <button class="bg-black text-white p-3 rounded-lg border border-dark-grey flex items-center justify-center" @click="goToPlayStore">
              <img src="~/assets/images/ui/download-aos-app.png" alt="download-aos-app" class="h-6 w-auto"/>
            </button>
          </div>
        </div>
      </div>
      <div>
        <h3 class="text-base font-semibold mb-2">TRG Sneakers Corp (Sneakers123)</h3>
        <div class="text-sm">
          CEO: Sung Joon Ahn<br/>
          Business Registeration Number: 331-81-54818<br/>
          Address: 6-30ho, 8, Gangnam-daero 53-gil, Seocho-gu, Seoul, Republic of Korea (06621)<br/>
          Email: support@sneakers123.com<br/>
          <!--          Phone: (+82) 070-<br/>-->
        </div>
        <div class="text-sm mt-4">
          © 2015 - {{ new Date().getFullYear() }} Sneakers123 | All rights reserved. <!--{{ $t('All rights reserved') }}-->
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import UButton from "@/components/ui/button.vue";
import {getCountry, getCountryList} from "@/lib/utils";
import UiButton from "../../../v2/ui/UiButton.vue";

export default {
  name: "footer-layout",
  components: {UiButton, UButton},
  methods: {
    ...mapMutations(["setSettingDialogVisible"]),
    openSettingDialog(){
      this.setSettingDialogVisible(true);
    },
    goToAppStore(){
      window.open("https://apps.apple.com/us/app/sneakers123-find-sneakers/id1015070329", "_blank");
    },
    goToPlayStore(){
      window.open("https://play.google.com/store/apps/details?id=com.sneaker123&hl=de_AT&gl=US", "_blank");
    }
  },
  computed: {
    footerHide(){
      return this.$router.currentRoute.path.includes('new-go')
    },
    country(){
      return getCountry(this.$store.state.location.code)
    },
    countries() {
      return getCountryList(this.$store.state.countryCodes)
    },
    availableLocales () {
      return this.$i18n.locales
    },
    currentLang(){
      return this.$i18n.locale
    },
    currentLangName(){
      return this.availableLocales.find((l)=>{ return l.code === this.currentLang}).name
    },
  },
};
</script>

<style lang="scss" scoped>
</style>