<template>
    <section class="tabs-section sneaker-detail-section">
        <b-container class="line px-0">
            <b-row>
              <div class="col-12">
                <h3 class="detail-title">{{ $t('sneaker-detail-text') }}:</h3>
              </div>
                <b-col cols="6" class="mb-2">
                  <h3 class="heading">{{ $t('brand-text') }}</h3>
                  <h4 class="description">
                    <nuxt-link
                    :to="sneakerLocalePath(_.kebabCase($parent.sneaker.brand))"
                    >{{ $parent.sneaker.brand }}</nuxt-link
                    >
                </h4>
                </b-col>
                <b-col cols="6" class="mb-2">
                  <h3 class="heading">{{ $t('model-text') }}</h3>
                  <h4 class="description">
                      <nuxt-link :to="model.url">{{ model.title }}</nuxt-link>
                  </h4>
                </b-col>
                <b-col cols="6" class="mb-2">
                  <h3 class="heading">{{ $t('style-code-text') }}</h3>
                  <h4 class="description">{{ $parent.sneaker.sku }}</h4>
                </b-col>
                <b-col cols="6" class="mb-2">
                  <h3 class="heading">{{ $t("Gender") }}</h3>
                  <h4 class="description">
                      <span v-for="g in gender" :key="g.text">
                      <nuxt-link class="separated-links" :to="g.link">{{
                          g.text
                      }}</nuxt-link>
                      </span>
                  </h4>
                </b-col>
                <b-col cols="6" class="mb-2" v-if="sneakerColors">
                  <h3 class="heading">{{ $t("Color") }}</h3>
                  <h4 class="description">
                      <span v-if="sneakerColors.length">
                        <nuxt-link class="separated-links" :to="g.link" v-for="g in sneakerColors" :key="g.text">{{
                            g.text
                        }}</nuxt-link>
                      </span>
                      <span v-else> {{ $t("N/A") }} </span>
                  </h4>
                </b-col>
                <b-col cols="6" class="mb-2">
                  <h3 class="heading">{{ $t('date-added-text') }}</h3>
                  <h4 class="description">{{ $parent.sneaker.created }}</h4>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import { isEmpty, capitalize, map } from "lodash-core";

export default {
  name: "MobileProductDetail",
  computed: {
    sneakerColors() {
      return map(this.$parent.sneaker.colorways, (g) => {
        return {
          text: capitalize(g),
          link: this.sneakerLocalePath("", { q: g }),
        };
      });
    },
    gender() {
      let genders = isEmpty(this.$parent.sneaker.gender)
        ? ["men", "women"]
        : this.$parent.sneaker.gender;
      return map(genders, (g) => {
        return {
          text: this.$t("filters." + g),
          link: this.sneakerLocalePath(g),
        };
      });
    },
    model() {
      if (!isEmpty(this.$parent.sneaker.category)) {
        return {
          title: this.$parent.sneaker.category.title,
          url: this.sneakerLocalePath(this.$parent.sneaker.category.url),
        };
      } else {
        return {
          title: this.$parent.sneaker.name,
          url: this.sneakerRootPage({ q: this.$parent.sneaker.name }),
        };
      }
    },
  },
};
</script>


<style scoped>
.separated-links {
  margin-right: 5px;
}
</style>