<script>
import redirectMixin from "@/plugins/redirectMixin";

export default {
  name: "product-info-return-policy",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  mixins: [redirectMixin],

  computed: {
    lnkProduct() {
      return {
        url: this.product.deeplink,
        productId: this.product.snkProductId || null,
        catalogProductId: this.product.id || null,
      }
    },
  },
  methods: {
    goToProduct() {
      window.open(this.localePath({name: 'new-go', query: this.goProductQuery}), '_blank');
    }
  }
}
</script>

<template>
  <div class="product-info-return-policy">

    <div class="multi-spacing">
      {{$t('text.productPage.shippingFeeOfThisProduct')}} : <span class="no-pointer">{{product.exchange.shippingFee ? product.exchange.shippingFeeDisplay : 'Free'}}</span>
      <br/>
      {{$t('text.productPage.shippingAndReturnPolicyDescription1', {shopName: product.shop.name})}}
    </div>
    <div class="multi-spacing">
      <h4>
        {{$t('label.returnsPolicy')}}
      </h4>
      {{$t('text.productPage.shippingAndReturnPolicyDescription2', {email: 'support@sneakers123.com'})}}<br/>
      {{$t('text.productPage.shippingAndReturnPolicyDescription3')}}
    </div>
    <div class="multi-spacing">
      {{$t('text.productPage.shippingAndReturnPolicyDescription4', {email: 'support@sneakers123.com'})}}<br/>
      {{$t('text.productPage.shippingAndReturnPolicyDescription5')}}<br/>
      {{$t('text.productPage.shippingAndReturnPolicyDescription6')}}
    </div>
    <div class="multi-spacing">
      <div class="shop-links" @click="() => goToProduct()">{{$t('text.productPage.shippingAndReturnPolicyDescription7', {shopName: product.shop.name})}}</div>
      <div class="shop-secondary">{{$t('text.productPage.shippingAndReturnPolicyDescription8')}}</div>
    </div>

<!--    </div>-->
<!--    <div v-html="$t('text.productPage.directDeliveryByShopMessage', {shopName: product.shop.name})">-->
<!--    </div>-->

<!--    <div>{{$t('text.productPage.returnPolicyDescription1', {-->
<!--        email: 'support@sneakers123.com'-->
<!--    })}}</div>-->
<!--    <div class="multi-spacing">{{$t('text.productPage.returnPolicyDescription2')}}</div>-->

<!--    <div>{{$t('text.productPage.returnPolicyDescription3')}}</div>-->
<!--    <div class="multi-spacing">{{$t('text.productPage.returnPolicyDescription4', {-->
<!--      email: 'support@sneakers123.com'-->
<!--    })}}</div>-->

<!--    <div class="multi-spacing">{{$t('text.productPage.returnPolicyDescription5')}}</div>-->

<!--    <div class="multi-spacing">{{$t('text.productPage.returnPolicyDescription6')}}</div>-->

<!--    <div v-html="$t('text.productPage.returnPolicyDescription7').replace('SHOP_NAME', `<b>${product.shop.name}</b>`)"></div>-->
<!--    <div><b>-->
<!--      {{$t('text.productPage.returnPolicyDescription8')}}-->
<!--    </b></div>-->


  </div>
</template>

<style lang="scss" scoped>
.product-info-return-policy{
  padding: 0 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #191919;
  margin-bottom: 24px;
  > div {
    word-break: break-word;
  }

  .multi-spacing {
    margin-bottom: 8px;
  }

  .shop-links {
    color: #191919;
    cursor: pointer;
    text-decoration: underline;
  }

  .shop-secondary{
    color: #8999b1;
  }
}
</style>